import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { useParams } from 'react-router-dom';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { ServiceContractsBudgetNotesDataTableProps } from './types';
import { paginationModelDefault } from 'utils/helpers';

const ServiceContractsBudgetNotesDataTable: FC<ServiceContractsBudgetNotesDataTableProps> = ({ serviceContract }) => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [, setModalState] = useAtom(modalStateAtom);
  const [organisations] = useUserOrganisations();
  const { id } = useParams();
  useInvalidateData('service-contracts-budget-notes');

  const handleModalOpen = () => {
    setModalState((prevState) => ({
      ...prevState,
      status: 'open',
      position: 'right',
      component: null,
      props: {
        dataTableName: 'service-contracts-budget-notes',
        type: 'create',
        participantId: id,
      }
    }));
  };

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['service-contracts-budget-notes', paginationModel, selectedFilters],
    queryFn: () =>
      [],
    // listParticipantAlerts(
    //   organisations[0].organisation.globalId,
    //   id as string,
    //   paginationModel.page + 1,
    //   paginationModel.pageSize
    // ),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    enabled: organisations.length > 0,
    staleTime: cachingInvalidation.participantAlerts,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedRowIds={selectedRowIds}
      />
      <DataTable
        // data={data as Data}
        data={{ items: data || [], totalCount: 1, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        title='Service Contracts'
        dataTableName='service-contracts-budget-notes'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={handleModalOpen}
      />
    </Stack>
  );
};

export default ServiceContractsBudgetNotesDataTable;