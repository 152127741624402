import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { TabProps } from 'components/common/Tabs/types';
import UpgradePlan from 'components/common/UpgradePlan';
import AccordionBase from 'components/common/AccordionBase';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import DefaultUserRoles from 'components/features/DefaultUserRoles';
import { AccordionItem } from 'components/common/AccordionBase/types';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AccordionTitle from 'components/features/DefaultUserRoles/AccordionTitle';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AccordionContent from 'components/features/DefaultUserRoles/AccordionContent';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import useInvalidateData from 'hooks/useInvalidateData';
import { getRoleTemplateUserCount } from 'api/organisations/users';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

const ListUserRolesPage = () => {
  const [organisations] = useUserOrganisations();
  useInvalidateData('role-templates');
  const [counts, setCounts] = useState({
    owner: 0,
    admin: 0,
    standard: 0,
    scheduler: 0,
    finance: 0,
    intake: 0,
    service: 0,
  });

  const { data } = useQuery({
    queryKey: ['role-templates'],
    queryFn: () => getRoleTemplateUserCount(organisations[0].organisation.globalId),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.roleTemplates,
  });

  useEffect(() => {
    if (data?.items) {
      setCounts({
        owner: data?.items.find((item) => item.name === 'Owner')?.count || 0,
        admin: data?.items.find((item) => item.name === 'Administrator')?.count || 0,
        standard: data?.items.find((item) => item.name === 'Standard User')?.count || 0,
        scheduler: data?.items.find((item) => item.name === 'Scheduler')?.count || 0,
        finance: data?.items.find((item) => item.name === 'Finance')?.count || 0,
        intake: data?.items.find((item) => item.name === 'Intake')?.count || 0,
        service: data?.items.find((item) => item.name === 'Service Team')?.count || 0,
      });
    }
  }, [data]);

  const DefaultRolesAccordionArray = [
    {
      summaryContent: () => <AccordionTitle text='Owner' description='Unrestricted access to all features and settings within the system, including organizational settings. This role has the authority to complete any action necessary for managing the platform effectively.' statusArray={[{ text: `${counts.owner}` }, { text: 'web app role' }]} />,
      detailsContent: () => <AccordionContent text='<p>Permission to <strong>CREATE, EDIT and DELETE</strong>. Access to the modules below:</p>' permissionArray={[
        { text: '<p>Home Page</p>', icon: HomeOutlinedIcon },
        { text: '<p>Participant Centre</>', icon: PersonSearchOutlinedIcon },
        { text: '<p>Progress Notes</>', icon: StickyNote2OutlinedIcon },
        { text: '<p>Scheduling</>', icon: CalendarTodayOutlinedIcon },
        { text: '<p>Incidents</>', icon: WarningAmberOutlinedIcon },
        { text: '<p>Billing & Finance</>', icon: MonetizationOnOutlinedIcon },
        { text: '<p>Reporting</>', icon: InsertChartOutlinedIcon },
        { text: '<p>Resources</>', icon: FolderOutlinedIcon },
        { text: '<p>Downloads</>', icon: FileDownloadOutlinedIcon },
        { text: '<p>Settings</>', icon: SettingsOutlinedIcon },
      ]} />,
    },
    {
      summaryContent: () => <AccordionTitle text='Admin' description='Comprehensive access is granted to all features and settings, with the exception of organizational settings. This role empowers users to execute all actions required for efficient management of the platform.' statusArray={[{ text: `${counts.admin}` }, { text: 'web app role' }]} />,
      detailsContent: () => <AccordionContent text='<p>Permission to <strong>CREATE, EDIT and DELETE</strong>. Access to the modules below:</p>' permissionArray={[
        { text: '<p>Home Page</p>', icon: HomeOutlinedIcon },
        { text: '<p>Participant Centre</p>', icon: PersonSearchOutlinedIcon },
        { text: '<p>Progress Notes</p>', icon: StickyNote2OutlinedIcon },
        { text: '<p>Scheduling</p>', icon: CalendarTodayOutlinedIcon },
        { text: '<p>Incidents</p>', icon: WarningAmberOutlinedIcon },
        { text: '<p>Billing & Finance</p>', icon: MonetizationOnOutlinedIcon },
        { text: '<p>Reporting</p>', icon: InsertChartOutlinedIcon },
        { text: '<p>Resources</p>', icon: FolderOutlinedIcon },
        { text: '<p>Downloads</p>', icon: FileDownloadOutlinedIcon },
        { text: '<p>Settings (except Organisation Settings)</p>', icon: SettingsOutlinedIcon },
      ]} />,
    },
    {
      summaryContent: () => <AccordionTitle text='Standard User' description='Access to all areas of the system, excluding Incidents and Settings. While the capability to create records exists, editing or deleting functionalities are restricted. This role is ideal for individuals needing to input data without altering existing records.' statusArray={[{ text: `${counts.standard}` }, { text: 'web app role' }]} />,
      detailsContent: () => <AccordionContent text='<p>Permission to <strong>CREATE</strong>. Access to the modules below:</p>' permissionArray={[
        { text: '<p>Home Page</p>', icon: HomeOutlinedIcon },
        { text: '<p>Participant Centre</p>', icon: PersonSearchOutlinedIcon },
        { text: '<p>Scheduling</p>', icon: CalendarTodayOutlinedIcon },
        { text: '<p>Billing & Finance</p>', icon: MonetizationOnOutlinedIcon },
        { text: '<p>Resources</p>', icon: FolderOutlinedIcon },
      ]} />,
    },
    {
      summaryContent: () => <AccordionTitle text='Scheduler' description='Access to the Participant Centre and Scheduling features. This User Role provides access to create, modify, and delete schedules, as well as view incidents and private incidents. It is suited for individuals responsible for managing and organizing schedules within the system.' statusArray={[{ text: `${counts.scheduler}` }, { text: 'web app role' }]} />,
      detailsContent: () => <AccordionContent text='<p>Permission to <strong>CREATE, EDIT and DELETE</strong>. Access to the modules below:</p>' permissionArray={[
        { text: '<p>Home Page</p>', icon: HomeOutlinedIcon },
        { text: '<p>Participant Centre</p>', icon: PersonSearchOutlinedIcon },
        { text: '<p>Scheduling</p>', icon: CalendarTodayOutlinedIcon },
        { text: '<p>Incidents (<strong>VIEW</strong> only</p>', icon: WarningAmberOutlinedIcon },
        { text: '<p>Reporting</p>', icon: InsertChartOutlinedIcon },
        { text: '<p>Resources</p>', icon: FolderOutlinedIcon },
        { text: '<p>Downloads</p>', icon: FileDownloadOutlinedIcon },
      ]} />,
    },
    {
      summaryContent: () => <AccordionTitle text='Finance' description='Access exclusively to the Billing tab where users can create, modify (including approval), and delete records related to billing. This role is designed for those tasked with overseeing financial transactions and records within the platform.' statusArray={[{ text: `${counts.finance}` }, { text: 'web app role' }]} />,
      detailsContent: () => <AccordionContent text='<p>Permission to <strong>CREATE, EDIT and DELETE</strong>. Access to the modules below:</p>' permissionArray={[
        { text: '<p>Home Page</p>', icon: HomeOutlinedIcon },
        { text: '<p>Billing & Finance</p>', icon: MonetizationOnOutlinedIcon },
        { text: '<p>Reporting</p>', icon: InsertChartOutlinedIcon },
        { text: '<p>Downloads</p>', icon: FileDownloadOutlinedIcon },
      ]} />,
    },
    {
      summaryContent: () => <AccordionTitle text='Intake' description='Access exclusively to the Participant Centre and the ability to create and modify records. This role is tailored for individuals involved in data entry and management of participant-related information within the system.' statusArray={[{ text: `${counts.intake}` }, { text: 'web app role' }]} />,
      detailsContent: () => <AccordionContent text='<p>Permission to <strong>CREATE and EDIT</strong>. Access to the modules below:</p>' permissionArray={[
        { text: '<p>Home Page</p>', icon: HomeOutlinedIcon },
        { text: '<p>Participant Centre</p>', icon: PersonSearchOutlinedIcon },
        { text: '<p>Resources</p>', icon: FolderOutlinedIcon },
      ]} />,
    },
    {
      summaryContent: () => <AccordionTitle text='Service Team' description='The Service Team role is designated solely for access through the mobile app. This role encompasses all functionalities available on the app, allowing individuals to perform tasks and actions necessary for their responsibilities.' statusArray={[{ text: `${counts.service}` }, { text: 'mobile app role' }]} />,
      detailsContent: null,
    },
  ] as AccordionItem[];

  const DefaultRolesTabs = [
    {
      label: 'Default Roles',
      requiredPermissions: [],
      content: (
        <Box padding='32px'>
          <AccordionBase accordionArray={DefaultRolesAccordionArray} />
        </Box>
      )
    },
    {
      label: 'Custom Roles',
      content: <UpgradePlan />,
      requiredPermissions: [],
    },
  ] as TabProps[];

  return <DefaultUserRoles tabs={DefaultRolesTabs} />;

};

export default ListUserRolesPage;