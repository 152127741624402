import React from 'react';
import { useAtom } from 'jotai';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { LeaveType } from 'types/dbSchema/availabilitySettings';
import useInvalidateData from 'hooks/useInvalidateData';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import { updateLeaveType } from 'api/organisations/settings/availability-settings/leave-types';

const EditStatusDropdown = ({ leaveType }: { leaveType: LeaveType }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('leave-type');
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateLeaveType(organisations[0].organisation.globalId, { ...leaveType, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'leave-type': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={leaveType.isActive}
      setIsActive={handleEdit}
      disabled={!leaveType.isEditable}
    />
  );
};

export default EditStatusDropdown;