import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';

// This hook is used to invalidate the data of a specific data table.
// Invalidates the query for all queries with the key 'dataTableToInvalidate' regardless of the pagination model and filters
const useInvalidateData = (dataTableToInvalidate: string) => {
  const [invalidateData, setInvalidateData] = useAtom(invalidateDataAtom);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (invalidateData[dataTableToInvalidate]) {
      queryClient.invalidateQueries({ queryKey: [dataTableToInvalidate] });
      queryClient.invalidateQueries({ queryKey: [`${dataTableToInvalidate}-infinite`] });

      // Remove the data table from the list of data tables that need to be invalidated
      setInvalidateData((prev) => {
        const updated = { ...prev };
        delete updated[dataTableToInvalidate];
        return updated;
      });
    }
  }, [invalidateData, dataTableToInvalidate, queryClient]);
};

export default useInvalidateData;