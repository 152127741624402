import React from 'react';
import { Typography } from '@mui/material';
import variables from 'styles/variables';
import { GridColDef } from '@mui/x-data-grid';
import EditIcon from './EditIcon';
import EditStatusDropdown from './EditStatusDropdown';

export const Columns: GridColDef[] = [
  {
    field: 'edit',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <EditIcon tag={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Tag Name',
    flex: 1,
    renderCell: (params) => (
      <Typography variant='subtitle2'>
        {params.row.name}
      </Typography>
    ),
  },
  {
    field: 'tagCategory',
    headerName: 'Tag Category',
    flex: 3,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {params.row.tagCategory}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <EditStatusDropdown tag={params.row} />
    ),
  },
];

export default Columns;