import React, { FC, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import SearchInput from 'components/common/InputFields/SearchInput';
import Actions from './Actions';
import { systemUserStatuses } from 'types/dbSchema/userProfiles';
import FilterWithDropdown from 'components/common/FilterWithDropdown';
import { IFilter } from 'components/common/FilterWithDropdown/types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from 'components/common/Tooltip';
import { goalStatuses, goalTypes } from 'types/dbSchema/participantGoals';
import { ControlsProps } from '../../types';
import { goalCommentTypeFilter, goalStatusFilter, goalTypeFilter } from 'utils/filters';

const Controls: FC<ControlsProps> = ({
  selectedFilters,
  setSelectedFilters,
  searchText,
  setSearchText,
  selectedRowIds,
}) => {
  const [filterOpen, setFilterOpen] = useState('');

  return (
    <StyledStack>
      <Stack justifyContent='space-between' height='100%' gap='16px'>
        <ActionStack>
          <Typography variant='subtitle2' minWidth='fit-content' fontSize='18px'>
            Goals
          </Typography>
          <Stack flexDirection='row' gap='16px' height='36px' width='fit-content'>
            {filters && (
              <Filters>
                {[goalTypeFilter, goalStatusFilter].map((filter: IFilter, index: number) => (
                  <FilterWithDropdown
                    key={index}
                    filter={filter}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    filterOpen={filterOpen}
                    setFilterOpen={setFilterOpen}
                  />
                ))}
                {selectedFilters.length > 0 &&
                  <ResetFiltersBox onClick={() => setSelectedFilters([])}>
                    <Typography variant='body2' fontWeight='600' color='secondary' >
                      Reset filters
                    </Typography>
                  </ResetFiltersBox>
                }
              </Filters>
            )}
            {/* <SearchInput searchValue={searchText} setSearchValue={setSearchText} placeholderText='Search User Profiles' height='100%' /> */}
            <Actions />
          </Stack>
        </ActionStack>
      </Stack>
    </StyledStack>
  );
};

// This object is used to define the filters that will be displayed in the filter dropdowns
export const filters = [
  {
    field: 'goalType',
    name: 'Goal Type',
    options: goalTypes.map((type) => type.label)
  },
  {
    field: 'status',
    name: 'Status',
    options: goalStatuses
  },
];

const Filters = styled(Stack)`
  width: fit-content;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
`;

const ActionStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const ResetFiltersBox = styled(Stack)`
  cursor: pointer;
  padding: 8px;
  border-left: 1px solid ${variables.colors.secondary.outlineBorder};
  min-width: fit-content;
`;

export default Controls;