import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsertChartOutlinedSharpIcon from '@mui/icons-material/InsertChartOutlinedSharp';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
// import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
// import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'; // Not in the scope of MVP1
// import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'; // Not in the scope of MVP1
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import JoinInnerOutlinedIcon from '@mui/icons-material/JoinInnerOutlined';
import GroupOutlinedIcon from '@mui/icons-material/Group';
import { permissions } from 'types/dbSchema/permissions';

export interface MenuItem {
  label: string;
  icon: any | null;
  to: string;
  requiredPermissions: string[];
  children: ChildMenuItem[];
}

export interface ChildMenuItem {
  label: string;
  to: string;
  requiredPermissions: string[];
  icon: any | null;
}

// Main menu items, iterated over to create the main menu
export const mainMenuItems: MenuItem[] = [
  {
    label: 'My Home',
    requiredPermissions: [permissions.VIEW_HOME],
    icon: HomeOutlinedIcon,
    to: '/',
    children: [],
  },
  {
    label: 'Powerview',
    requiredPermissions: [permissions.VIEW_POWERVIEW],
    icon: InsertChartOutlinedSharpIcon,
    to: '/powerview',
    children: [],
  },
  {
    label: 'Participant Centre',
    requiredPermissions: [permissions.VIEW_PARTICIPANT_CENTRE],
    icon: PersonSearchOutlinedIcon,
    to: '',
    children: [
      {
        label: 'Participants',
        to: '/participant-centre/participants',
        requiredPermissions: [permissions.VIEW_PARTICIPANTS],
        icon: null
      },
      {
        label: 'Contacts Console',
        to: '/participant-centre/contacts-console',
        requiredPermissions: [permissions.VIEW_CONTACTS],
        icon: null
      },
      // Commented out Incidents and Tasks, they will be released after MVP1
      // {
      //   label: 'Tasks',
      //   to: '/participant-centre/tasks'
      // },
      // {
      //   label: 'Incidents',
      //   to: '/participant-centre/incidents'
      // }
    ]
  },
  {
    label: 'Scheduling',
    icon: CalendarTodayOutlinedIcon,
    requiredPermissions: [],
    to: '',
    children: [
      {
        label: 'Participant Schedule',
        to: '/scheduling/participation-schedules',
        requiredPermissions: [],
        icon: null
      },
      {
        label: 'Shift Releases',
        to: '/scheduling/shift-releases',
        requiredPermissions: [],
        icon: null
      },
      {
        label: 'Shift Approval',
        to: '/scheduling/shift-approval',
        requiredPermissions: [],
        icon: null
      },
      {
        label: 'Service Team',
        to: '/scheduling/service-team',
        requiredPermissions: [],
        icon: null
      },
      {
        label: 'Unallocated Services',
        to: '/scheduling/unallocated-services',
        requiredPermissions: [],
        icon: null
      },
      {
        label: 'Scheduling Supports',
        to: '/scheduling/scheduling-supports',
        requiredPermissions: [],
        icon: null
      },
    ]
  },
  {
    label: 'Billing',
    icon: MonetizationOnOutlinedIcon,
    requiredPermissions: [],
    to: '',
    children: [
      {
        label: 'NDIS Claims',
        to: '/billing/ndis-claims',
        requiredPermissions: [],
        icon: null
      },
      {
        label: 'Invoice Batches',
        to: '/billing/invoice-batches',
        requiredPermissions: [],
        icon: null
      },
      {
        label: 'Payfiles',
        to: '/billing/payfiles',
        requiredPermissions: [],
        icon: null
      },
    ]
  },
  {
    label: 'Resources',
    icon: FolderOutlinedIcon,
    requiredPermissions: [],
    to: '',
    children: [
      {
        label: 'Service Providers',
        to: '/resources/service-providers',
        requiredPermissions: [],
        icon: null
      },
      {
        label: 'Service Regions',
        to: '/resources/service-regions',
        requiredPermissions: [],
        icon: null
      },
      {
        label: 'Products & Services',
        to: '/resources/products-services',
        requiredPermissions: [],
        icon: null
      },
    ]
  },
  {
    label: 'Downloads',
    icon: FileDownloadOutlinedIcon,
    requiredPermissions: [],
    to: '/downloads',
    children: []
  },
];

// Settings menu items, iterated over to create the settings menu
export const settingsMenuItems: MenuItem[] = [
  {
    label: 'Organisation Settings',
    requiredPermissions: [],
    icon: CorporateFareIcon,
    to: '/settings/organisation/organisation-settings',
    children: []
  },
  {
    label: 'User Management',
    requiredPermissions: [],
    icon: BadgeOutlinedIcon,
    to: '',
    children: [
      {
        label: 'User Profiles',
        to: '/settings/user-management/user-profiles',
        requiredPermissions: [],
        icon: null
      },
      {
        label: 'User Positions',
        to: '/settings/user-management/user-positions',
        requiredPermissions: [],
        icon: null
      },
      {
        label: 'User Roles',
        to: '/settings/user-management/user-roles',
        requiredPermissions: [],
        icon: null
      },
    ]
  },
  {
    label: 'Participant Settings',
    requiredPermissions: [],
    icon: ManageAccountsOutlinedIcon,
    to: '/settings/participant',
    children: []
  },
  // This page is partially completed (except for the APIs), it is not in the scope of MVP1
  // {
  //   label: 'Task Settings',
  //   icon: CheckCircleOutlinedIcon,
  //   to: '/settings/task-settings',
  //   children: []
  // },
  // This page is partially completed (except for the APIs), it is not in the scope of MVP1
  // {
  //   label: 'Incident Settings',
  //   icon: WarningAmberOutlinedIcon,
  //   to: '/settings/incident-settings',
  //   children: []
  // },
  {
    label: 'Scheduling Settings',
    requiredPermissions: [],
    icon: CalendarTodayOutlinedIcon,
    to: '/settings/scheduling-settings',
    children: []
  },
  {
    label: 'Service Team Settings',
    requiredPermissions: [],
    icon: GroupOutlinedIcon,
    to: '/settings/service-team-settings',
    children: []
  },
  {
    label: 'Provider Settings',
    requiredPermissions: [],
    icon: AccountBoxOutlinedIcon,
    to: '/settings/provider-settings',
    children: []
  },
  {
    label: 'Availability Settings',
    requiredPermissions: [],
    icon: WatchLaterOutlinedIcon,
    to: '/settings/availability-settings',
    children: []
  },
  {
    label: 'Notification Settings',
    requiredPermissions: [],
    icon: NotificationsOutlinedIcon,
    to: '/settings/notification-settings',
    children: []
  },
  {
    label: 'Integration',
    requiredPermissions: [],
    icon: JoinInnerOutlinedIcon,
    to: '/settings/integration',
    children: []
  },
];