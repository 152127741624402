import React, { FC } from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Action } from 'components/common/ActionsDropdown/types';
import ActionsDropdown from 'components/common/ActionsDropdown';
import { ActionsProps } from 'types';
import { handleExport } from 'utils/helpers';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import ButtonWithDropdown from 'components/common/ButtonWithDropdown';
import Tooltip from 'components/common/Tooltip';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms/modalAtom';
import LinkToGoalForm from 'components/features/Forms/LinkToGoalForm';
import TestProgressNoteForm from 'components/features/Forms/TestProgressNoteForm';
import { useParams } from 'react-router-dom';

const Actions: FC<ActionsProps> = ({ selectedRowIds, dataTableName }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const { id } = useParams();

  const handleLinkToGoal = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: LinkToGoalForm,
      props: {
        selectedRowIds: selectedRowIds,
        participantId: id as string,
        dataTableName: 'participant-goals'
      }
    });
  };

  // Disable this for production
  const handleCreateDummyNote = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: TestProgressNoteForm,
      props: {
        participantId: id
      }
    });
  };

  const actions: Action[] = [
    {
      icon: FileUploadOutlinedIcon,
      label: `Export ${selectedRowIds.length > 0 ? 'Selected' : 'All'}`,
      function: () => {
        handleExport(selectedRowIds, dataTableName);
      },
    },
    {
      icon: FileUploadOutlinedIcon,
      label: 'Create Dummy Note',
      function: () => {
        handleCreateDummyNote();
      },
    },
    {
      icon: InsertLinkOutlinedIcon,
      info: (
        <Tooltip
          toolTipTriggerComponent={<InfoOutlinedIcon sx={{ height: '100%', width: '100%' }} />}
          toolTipTitle=''
          toolTipText='To link a progress note to a goal, start by checking the box next to each relevant progress note.'
          position='right'
        />
      ),
      label: 'Link to Goal',
      function: () => {
        handleLinkToGoal();
      },
      isDisabled: selectedRowIds.length === 0,
    }
  ];

  return (
    <ButtonWithDropdown
      color='primary'
      label='Actions'
      isDisabled={false}
      dropdownContents={
        <ActionsDropdown selectedRowIds={selectedRowIds} dataTableName={dataTableName} actions={actions} />
      }
    />
  );
};

export default Actions;