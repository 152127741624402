import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { ProgressNoteType } from 'types/dbSchema/participantSettings';
import ProgressNoteTypeForm from 'components/features/Forms/ProgressNoteTypeForm';

const EditIcon = ({ progressNoteType }: { progressNoteType: ProgressNoteType }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  if (!progressNoteType || !progressNoteType.isEditable) return null;

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ProgressNoteTypeForm,
      props: {
        dataTableName: 'progress-note-types',
        type: 'edit',
        progressNoteType,
      }
    }));
  }, [setModalState, progressNoteType]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default EditIcon;