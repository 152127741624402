import React from 'react';
import { useAtom } from 'jotai';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { GeneralNoteType } from 'types/dbSchema/participantSettings';
import { updateGeneralNoteType } from 'api/organisations/settings/participants/general-note-types';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useInvalidateData from 'hooks/useInvalidateData';
import useErrorMessage from 'hooks/useErrorMessage';

const EditStatusDropdown = ({ generalNoteType }: { generalNoteType: GeneralNoteType }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('general-note-types');
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateGeneralNoteType(organisations[0].organisation.globalId, { ...generalNoteType, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'general-note-types': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={generalNoteType.isActive}
      setIsActive={handleEdit}
      disabled={!generalNoteType.isEditable}
    />
  );
};

export default EditStatusDropdown;