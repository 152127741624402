import React, { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { modalStateAtom } from 'atoms';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { ServiceRegion } from 'types/dbSchema/serviceRegions';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import styled from 'styled-components';
import variables from 'styles/variables';
import { closeModalAtom } from 'atoms/modalAtom';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useErrorMessage from 'hooks/useErrorMessage';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { deleteServiceRegionById } from 'api/organisations/serviceregions';

// TODO: Delete button will be disabled if record cannot be deleted because it’s assigned to a participant and/or a Service provider.
// Renders Delete Icon for Grid column definitions
const Delete = ({ serviceRegion }: { serviceRegion: ServiceRegion }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: DeleteModal,
      props: { serviceRegion }
    }));
  }, [setModalState]);

  if (serviceRegion.linkedParticipantCount > 0 || serviceRegion.linkedServiceProviderCount > 0 || serviceRegion.linkedUserCount > 0) return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'not-allowed' }}>
      <DeleteOutlinedIcon sx={{ color: variables.colors.icon.standard, opacity: 0.2 }} />
    </Stack>
  );

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <DeleteOutlinedIcon sx={{ color: '#C62828' }} />
    </Stack>
  );
};

export interface DeleteModalProps {
  serviceRegion: ServiceRegion;
}

const DeleteModal: FC<DeleteModalProps> = ({ serviceRegion }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const showError = useErrorMessage();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);

  const handleDelete = async () => {
    try {
      await deleteServiceRegionById(organisations[0].organisation.globalId, serviceRegion.id);
      setInvalidateData((prev) => ({ ...prev, 'service-regions': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };


  return (
    <Stack alignItems="center" width='500px'>
      <Stack flexDirection="row" alignItems="center" gap='24px' sx={{ padding: '32px' }}>
        <Box sx={{
          borderRadius: '100%',
          background: '#FECDD2',
          padding: '8px',
          height: '40px',
          width: '40px',
          display: 'flex',
          alignSelf: 'flex-start',
          boxSizing: 'border-box'
        }}>
          <DeleteOutlinedIcon color='error' />
        </Box>
        <Stack gap='16px'>
          <Typography variant='h6'>
            Are you sure you want to delete this Service Region?
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            This action cannot be reversed.
          </Typography>
        </Stack>
      </Stack>
      <ButtonStack>
        <Button variant='outlined' onClick={closeModal}>Cancel</Button>
        <Button variant='contained' color='error' onClick={handleDelete}>Delete</Button>
      </ButtonStack>
    </Stack>
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;

export default Delete;