import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Columns } from './Controls/Columns';
import { listPositions } from 'api/organisations/positions';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import UserPositionForm from 'components/features/Forms/UserPositionForm';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';

const UserPositionsDataTable: FC = () => {
  const [searchText, setSearchText] = useState('');
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  const [modalState, setModalState] = useAtom(modalStateAtom);
  useInvalidateData('user-position');

  const { isPending, isError, data, isFetching, isLoading } = useQuery({
    queryKey: ['user-position', paginationModel, searchText],
    queryFn: () => listPositions(
      organisations[0].organisation.globalId,
      paginationModel.page + 1,
      paginationModel.pageSize
    ),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.userPositions,
  });

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: UserPositionForm,
      props: {
        dataTableName: 'user-positions',
        type: 'create',
      }
    });
  };

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <DataTable
        data={data as Data}
        columns={Columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching || isLoading}
        isError={isError}
        title='User Positions'
        dataTableName='user-position'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={handleModalOpen}
      />
    </Stack>
  );
};

export default UserPositionsDataTable;