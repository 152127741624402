import React, { FC, useCallback } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { CreateParticipantAndContactLink } from 'types/dbSchema/participantContact';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import TextInput from 'components/common/InputFields/TextInput';
import RelationshipTypeLookupField from 'components/common/LookupInputField/RelationshipTypeLookupField';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import { ExternalContact } from 'types/dbSchema/externalContacts';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { createExternalContact } from 'api/organisations/externalcontacts';
import { linkParticipantToExternalContact } from 'api/organisations/participants/externalcontacts';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import useCurrentUser from 'hooks/useCurrentUser';
import { emailValidationRegex, mobileValidationRegex, phoneValidationRegex } from 'utils/helpers';
import AlreadyExistsModal from '../AlreadyExistsModal';
import { ContactAndParticipantLinkFormProps } from '../types';

// This component creates a new contact and links it to the participant
export const CreateContact: FC = () => {
  const navigate = useNavigate();
  const handleCancel = () => navigate(-1);
  const [, closeModal] = useAtom(closeModalAtom);
  const [, setModalState] = useAtom(modalStateAtom);
  const [organisations] = useUserOrganisations();
  const [currentUser] = useCurrentUser();
  const { id } = useParams();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      isActive: true,
      createdBy: currentUser?.id || ''
    } as ContactAndParticipantLinkFormProps
  });

  // Function to open/close the modal.
  const handleModalOpen = useCallback((selectedContactId: string, relationshipData: CreateParticipantAndContactLink) => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: AlreadyExistsModal,
      props: {
        participantId: id,
        selectedContactId,
        relationshipData
      }
    }));
  }, [setModalState]);

  const handleCreateExternalContact = async (data: ExternalContact) => {
    try {
      const resp = await createExternalContact(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'external-contacts': true }));
      return resp;
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleLinkContactAndParticipant = async (data: CreateParticipantAndContactLink, contactId: string) => {
    try {
      await linkParticipantToExternalContact(
        organisations[0].organisation.globalId,
        contactId,
        id as string,
        data
      );
      setInvalidateData((prev) => ({ ...prev, 'external-contacts': true, 'participant-contacts': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleCreateNewContact = async (data: ContactAndParticipantLinkFormProps) => {

    const contactData = {
      createdBy: currentUser?.id,
      createdDateTime: new Date().toISOString(),
      fullName: {
        firstName: data.fullName.firstName,
        lastName: data.fullName.lastName,
      },
      email: data.email,
      mobile: data.mobile,
      phone: data.phone,
      relationship: data.relationship,
      isPrimaryContact: data.isPrimaryContact,
      isEmergencyContact: data.isEmergencyContact,
      isActive: data.isActive,
      linkedParticipants: [],
      linkedParticipantsCount: 0,
    } as ExternalContact;

    const linkData = {
      isEmergencyContact: data.isEmergencyContact,
      isPrimaryContact: data.isPrimaryContact,
      relationshipType: data.relationship,
      createdBy: currentUser?.id,
    } as CreateParticipantAndContactLink;

    try {
      const resp = await handleCreateExternalContact(contactData);
      if (resp.success) {
        await handleLinkContactAndParticipant(
          { ...linkData, contactId: resp?.externalContactId },
          resp?.externalContactId as string
        );
        navigate(`/participant-centre/participants/view/${id}#contacts`);
        closeModal();
      } else {
        handleModalOpen(resp.externalContactId, { ...linkData, contactId: resp.externalContactId });
      }
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleCreateNewContact)}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px'
      }}
    >
      <DetailsStack>
        <Stack gap='8px'>
          <Typography variant='subtitle1'>
            Add New Contact
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Contacts will be created in the Contacts Console list and establish a link to Participants Contacts for seamless integration.
          </Typography>
        </Stack>
        <Stack gap='20px' flexDirection='row'>
          <Controller
            name="fullName.firstName"
            control={control}
            rules={{ required: 'First Name is required' }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='firstName'
                label='First Name'
                error={errors.fullName?.firstName ? true : false}
                errorText={errors.fullName?.firstName?.message}
                isMandatory
              />
            }
          />
          <Controller
            name="fullName.lastName"
            control={control}
            rules={{ required: 'Last Name is required' }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='lastName'
                label='Last Name'
                error={errors.fullName?.lastName ? true : false}
                errorText={errors?.fullName?.lastName?.message}
                isMandatory
              />
            }
          />
        </Stack>
        <Stack gap='20px' flexDirection='row'>
          <Controller
            name="relationship"
            control={control}
            rules={{
              required: 'Relationship is required',
            }}
            render={({ field }) =>
              <RelationshipTypeLookupField
                {...field}
                id='relationship'
                label='Relationship'
                placeholder='Select'
                validationError={errors.relationship ? true : false}
                errorText={errors.relationship?.message}
                isMandatory
              />
            }
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: emailValidationRegex,
                message: 'Invalid Email Address',
              },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='email'
                label='Email'
                error={errors.email ? true : false}
                errorText={errors?.email?.message}
                isMandatory
              />
            }
          />
        </Stack>
        <Stack gap='20px' flexDirection='row'>
          <Controller
            name="mobile"
            control={control}
            rules={{
              required: 'Mobile is required',
              minLength: { value: 10, message: 'Mobile number must be 10 digits' },
              maxLength: { value: 10, message: 'Mobile number must be 10 digits' },
              pattern: {
                value: mobileValidationRegex,
                message: 'Invalid mobile number',
              },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='mobile'
                label='Mobile'
                error={errors.mobile ? true : false}
                errorText={errors?.mobile?.message}
                isMandatory
              />
            }
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              required: 'Phone is required',
              pattern: {
                value: phoneValidationRegex,
                message: 'Please enter a valid Australian phone number',
              },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='phone'
                label='Phone'
                error={errors.phone ? true : false}
                errorText={errors?.phone?.message}
                isMandatory
              />
            }
          />
        </Stack>
        <Stack>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            Contact Tags
          </Typography>
          <Stack gap='20px' flexDirection='row'>
            <Controller
              name="isPrimaryContact"
              control={control}
              render={({ field }) =>
                <CheckboxInput
                  {...field}
                  id='isPrimaryContact'
                  label="Primary Contact"
                  error={errors.isPrimaryContact ? true : false}
                  checked={field.value ? true : false}
                />}
            />
            <Controller
              name="isEmergencyContact"
              control={control}
              render={({ field }) =>
                <CheckboxInput
                  {...field}
                  id='isEmergencyContact'
                  label="Emergency Contact"
                  error={errors.isEmergencyContact ? true : false}
                  checked={field.value ? true : false}
                />}
            />
          </Stack>
        </Stack>
        <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
          <Typography variant='subtitle2' fontWeight='500'>
            Status
          </Typography>
          <Controller
            name="isActive"
            control={control}
            rules={{
              validate: value => value !== undefined && value !== null || 'Status is required'
            }}
            render={({ field }) =>
              <IsActiveDropdown
                isActive={(field.value === undefined || field.value === null) ? true : field.value}
                setIsActive={field.onChange}
              />
            }
          />
        </Stack>
      </DetailsStack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' boxSizing='border-box'>
        <Button variant='text' onClick={handleCancel}>Cancel</Button>
        <Button variant='contained' type='submit'>Create and Link Contact</Button>
      </Stack>
    </form >
  );
};

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export default CreateContact;