import React, { FC, useState } from 'react';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { CreateParticipantAndContactLink } from 'types/dbSchema/participantContact';
import RelationshipTypeLookupField from 'components/common/LookupInputField/RelationshipTypeLookupField';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import { useAtom } from 'jotai';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { linkParticipantToExternalContact } from 'api/organisations/participants/externalcontacts';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import useCurrentUser from 'hooks/useCurrentUser';
import SearchAllContactsField from 'components/features/SearchAllContactsField';
import { AllContact, PartialServiceProvider } from 'components/features/SearchAllContactsField/types';
import { linkParticipantToServiceProviderContact } from 'api/organisations/participants/serviceprovidercontacts';
import { linkParticipantToParticipant } from 'api/organisations/participants/participantcontacts';
import { LinkContactDetailsFormProps } from '../types';

// This component links an existing contact to the participant
export const LinkContactDetails: FC = () => {
  const navigate = useNavigate();
  const handleCancel = () => navigate(-1);
  const [selectedContact, setSelectedContact] = useState<AllContact | null>(null);
  const [organisations] = useUserOrganisations();
  const [currentUser] = useCurrentUser();
  const { id } = useParams();
  const showError = useErrorMessage();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const { control, handleSubmit, formState: { errors } } = useForm<LinkContactDetailsFormProps>({
    mode: 'onChange',
  });

  const onSubmit = async (data: LinkContactDetailsFormProps) => {
    try {
      const newRelationship = {
        isEmergencyContact: data.isEmergencyContact,
        isPrimaryContact: data.isPrimaryContact,
        relationshipType: data.relationshipType,
        createdBy: currentUser?.id,
        contactId: selectedContact?.id,
      } as CreateParticipantAndContactLink;

      switch (data.selectedContact.contactType) {
        case 'Contact':
          await submitExternalContact(newRelationship);
          break;
        case 'Participant':
          await submitParticipantContact(newRelationship);
          break;
        case 'ServiceProvider':
          await submitServiceProviderContact(newRelationship);
          break;
        default:
          throw new Error('Invalid contact type');
      }
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const submitExternalContact = async (data: CreateParticipantAndContactLink) => {
    await linkParticipantToExternalContact(
      organisations[0].organisation.globalId,
      selectedContact?.id as string,
      id as string,
      data
    );
    setInvalidateData((prev) => ({ ...prev, 'participant-contacts': true }));
    navigate(`/participant-centre/participants/view/${id}#contacts`);
  };

  const submitParticipantContact = async (data: CreateParticipantAndContactLink) => {
    await linkParticipantToParticipant(
      organisations[0].organisation.globalId,
      selectedContact?.id as string,
      id as string,
      data
    );
    setInvalidateData((prev) => ({ ...prev, 'participant-contacts': true }));
    navigate(`/participant-centre/participants/view/${id}#contacts`);
  };

  const submitServiceProviderContact = async (data: CreateParticipantAndContactLink) => {
    await linkParticipantToServiceProviderContact(
      organisations[0].organisation.globalId,
      selectedContact?.id as string,
      id as string,
      data
    );
    setInvalidateData((prev) => ({ ...prev, 'participant-contacts': true }));
    navigate(`/participant-centre/participants/view/${id}#contacts`);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px'
      }}
    >
      <DetailsStack>
        <Stack padding='24px' sx={{ backgroundColor: '#F9FAFB' }}>
          <Stack gap='8px'>
            <Typography variant='h6' fontWeight='600'>
              Link a contact
            </Typography>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Find contacts across Contacts Console, Participants and Service Provider lists. Search by adding name and/or email.
            </Typography>
            <Controller
              name="selectedContact"
              control={control}
              rules={{ required: 'Contact is required' }}
              render={({ field }) =>
                <SearchAllContactsField
                  {...field}
                  placeholder='Select'
                  validationError={errors.selectedContact ? true : false}
                  errorText={errors.selectedContact?.message}
                  onChange={(value) => {
                    setSelectedContact(value);
                    field.onChange(value);
                  }}
                />
              }
            />
          </Stack>
        </Stack>
        <Stack gap='32px'>
          <Typography variant='h6' fontWeight='600'>
            Contact Details
          </Typography>
          <Stack flexDirection='row' gap='32px' width='100%'>
            {selectedContact ? (
              <Stack justifyContent='center' border={`1px dashed ${variables.colors.primary.lighter}`} borderRadius='8px' padding='16px' width='100%' sx={{ boxSizing: 'border-box' }}>
                <Stack height='fit-content' flexDirection='row' gap='16px'>
                  <Box
                    sx={{
                      borderRadius: '100%', background: 'rgba(75, 85, 99, 0.12)', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box'
                    }}
                  >
                    <AccountBoxOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
                  </Box>
                  <Stack>
                    <Typography fontSize='18px' fontWeight='600'>
                      {selectedContact.fullName.firstName} {selectedContact.fullName.lastName}
                    </Typography>
                    <Stack flexDirection='row' gap='8px'>
                      <Typography variant='body2' color={variables.colors.text.secondary}>
                        Email
                      </Typography>
                      <Typography variant='body2'>
                        {selectedContact.email}
                      </Typography>
                    </Stack>
                    <Stack flexDirection='row' gap='8px'>
                      <Typography variant='body2' color={variables.colors.text.secondary}>
                        Mobile
                      </Typography>
                      <Typography variant='body2'>
                        {selectedContact.mobile}
                      </Typography>
                    </Stack>
                    <Stack flexDirection='row' gap='8px'>
                      <Typography variant='body2' color={variables.colors.text.secondary}>
                        Phone
                      </Typography>
                      <Typography variant='body2'>
                        {selectedContact.phone}
                      </Typography>
                    </Stack>
                    {selectedContact && selectedContact.contactType === 'ServiceProvider' && (
                      <Stack flexDirection='row' gap='8px'>
                        <Typography variant='body2' color={variables.colors.text.secondary}>
                          Service Provider
                        </Typography>
                        <Typography variant='body2'>
                          {(selectedContact.serviceProvider as PartialServiceProvider)?.companyName}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            ) : (
              <Stack justifyContent='flex-start' border={`1px dashed ${variables.colors.primary.lighter}`} height='200px' borderRadius='8px' padding='16px' gap='30px' width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Your selected Contact will be displayed here.
                </Typography>
                <Stack height='fit-content' flexDirection='row' gap='16px'>
                  <Skeleton animation={false} variant="circular" width={48} height={48} />
                  <Stack>
                    <Skeleton animation={false} width={126} height={20} />
                    <Skeleton animation={false} width={214} height={16} />
                    <Skeleton animation={false} width={202} height={16} />
                    <Skeleton animation={false} width={137} height={16} />
                  </Stack>
                </Stack>
              </Stack>
            )}
            <Stack width='100%' gap='24px'>
              <Controller
                name="relationshipType"
                control={control}
                rules={{
                  required: 'Relationship is required',
                }}
                render={({ field }) =>
                  <RelationshipTypeLookupField
                    {...field}
                    id='relationshipType'
                    label='Relationship'
                    placeholder='Select'
                    validationError={errors.relationshipType ? true : false}
                    errorText={errors.relationshipType?.message}
                    isMandatory
                  />
                }
              />
              <Stack>
                <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
                  Contact Tags
                </Typography>
                <Controller
                  name="isPrimaryContact"
                  control={control}
                  render={({ field }) =>
                    <CheckboxInput
                      {...field}
                      id='isPrimaryContact'
                      label="Primary Contact"
                      error={errors.isPrimaryContact ? true : false}
                      checked={field.value ? true : false}
                    />}
                />
                <Controller
                  name="isEmergencyContact"
                  control={control}
                  render={({ field }) =>
                    <CheckboxInput
                      {...field}
                      id='isEmergencyContact'
                      label="Emergency Contact"
                      error={errors.isEmergencyContact ? true : false}
                      checked={field.value ? true : false}
                    />}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </DetailsStack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' boxSizing='border-box'>
        <Button variant='text' onClick={handleCancel}>Cancel</Button>
        <Button variant='contained' type='submit'>Link Contact</Button>
      </Stack>
    </form>
  );
};

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export default LinkContactDetails;