import React, { FC } from 'react';
import { ViewServiceProviderProps } from '../types';
import { Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import variables from 'styles/variables';
import { useNavigate } from 'react-router-dom';
import ServiceType from 'components/common/ServiceType';
import { ServiceType as TServiceType } from 'types/dbSchema/schedulingSettings';
import Line from 'components/common/Line';
import LineHeader from 'components/common/LineHeader';
import { formatABNForUI } from 'utils/helpers';
import { ServiceRegion } from 'types/dbSchema/serviceRegions';

const DetailsTab: FC<ViewServiceProviderProps> = ({ serviceProvider }) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/resources/service-providers/edit/${serviceProvider.id}`);
  };

  return (
    <StyledStack>
      <Stack flexDirection='row' justifyContent='space-between'>
        <Typography variant='h6'>Company Details</Typography>
        <Button variant='contained' startIcon={<EditOutlinedIcon />} onClick={handleEdit}>
          Edit
        </Button>
      </Stack>
      <DetailsStack>
        <Line>
          <Stack width='50%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Company Name
            </Typography>
            <Typography variant='body2'>
              {serviceProvider.companyName}
            </Typography>
          </Stack>
          <Stack width='50%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              ABN
            </Typography>
            <Typography variant='body2'>
              {serviceProvider.abn && formatABNForUI(serviceProvider.abn)}
            </Typography>
          </Stack>
        </Line>
        <Line noBottomBorder flexDirection='column' alignItems='flex-start'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Service Types
          </Typography>
          <Stack flexDirection='row' gap='8px' flexWrap='wrap'>
            {serviceProvider?.serviceTypes && (serviceProvider.serviceTypes as TServiceType[]).map((serviceType: TServiceType) => (
              <ServiceType serviceType={serviceType} key={serviceType.name} />
            ))}
          </Stack>
        </Line>
      </DetailsStack>
      <DetailsStack>
        <LineHeader>
          <Typography variant='subtitle1'>Contact Details</Typography>
        </LineHeader>
        <Line>
          <Stack width='50%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Phone
            </Typography>
            <Typography variant='body2'>
              {serviceProvider.phone}
            </Typography>
          </Stack>
        </Line>
        <Line noBottomBorder>
          <Stack width='50%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Email
            </Typography>
            <Typography variant='body2' sx={{ overflowWrap: 'anywhere' }}>
              {serviceProvider.email}
            </Typography>
          </Stack>
          <Stack width='50%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Website
            </Typography>
            <Typography variant='body2' sx={{ overflowWrap: 'anywhere' }}>
              {serviceProvider?.website}
            </Typography>
          </Stack>
        </Line>
      </DetailsStack>
      <DetailsStack>
        <LineHeader>
          <Typography variant='subtitle1'>Location Details</Typography>
        </LineHeader>
        <Line noBottomBorder>
          <Stack width='50%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Service Region
            </Typography>
            <Typography variant='body2'>
              {(serviceProvider.serviceRegion as ServiceRegion).name}
            </Typography>
          </Stack>
          <Stack width='50%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Address
            </Typography>
            <Typography variant='body2'>
              {serviceProvider.businessAddress &&
                `${serviceProvider.businessAddress?.streetAddress}, ${serviceProvider.businessAddress.suburb}, 
                ${serviceProvider.businessAddress.state}, ${serviceProvider.businessAddress.postCode}`}
            </Typography>
          </Stack>
        </Line>
      </DetailsStack>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  padding: 24px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  gap: 24px;
`;

const DetailsStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export default DetailsTab;