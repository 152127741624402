import React, { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { Participant } from 'types/dbSchema/participants';
import { useNavigate } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import variables from 'styles/variables';
import Line from 'components/common/Line';
import LineHeader from 'components/common/LineHeader';
import { useHasPermission } from 'hooks/useHasPermission';
import { PrimaryDisability } from 'types/dbSchema/participantSettings';
import { Tag } from 'types/dbSchema/serviceTeamSettings';
import { permissions } from 'types/dbSchema/permissions';

const CarePreferences: FC<{ participant: Participant }> = ({ participant }) => {
  const navigate = useNavigate();

  return (
    <CarePreferencesStack>
      <Stack flexDirection={'row'} justifyContent='space-between' width='100%'>
        <Typography variant='h6'>
          Care Preferences
        </Typography>
        <Button
          variant='contained'
          color='primary'
          disabled={!useHasPermission([permissions.MODIFY_PARTICIPANT_DETAILS])}
          startIcon={<EditOutlinedIcon />}
          onClick={() => navigate(`/participant-centre/participants/edit/${participant.id}#care-preferences`)}
        >
          Edit
        </Button>
      </Stack>
      <ServicePreferences participant={participant} />
      <Disabilities participant={participant} />
      <Languages participant={participant} />
      <Misc participant={participant} />
    </CarePreferencesStack>
  );
};

const ServicePreferences: FC<{ participant: Participant }> = ({ participant }) => {
  const navigate = useNavigate();

  return (
    <ServicePreferenceStack>
      <Typography variant='subtitle1' paddingBottom='16px'>
        Service Preferences
      </Typography>
      {participant?.servicePreferences && participant.servicePreferences.length > 0 ? (
        <PreferencesWrapper>
          {participant.servicePreferences.length > 0 &&
            (participant.servicePreferences as Tag[]).map((servicePreference: Tag, index) => (
              <ServicePreferenceItem key={index}>
                <Stack key={servicePreference.id}
                  sx={{
                    borderRadius: '4px',
                    width: 'fit-content',
                    padding: '0 6px',
                  }}>
                  <Typography variant='caption' fontWeight='600'>
                    {servicePreference.name}
                  </Typography>
                </Stack>
              </ServicePreferenceItem>
            ))}
        </PreferencesWrapper>
      ) : (
        <Stack gap='16px' width='100%' justifyContent='space-between'>
          <Typography variant='body2'>
            Remember to add the participant’s service preferences. These will match service team skills, helping our Suggestions Engine select the best-suited worker.
          </Typography>
          <Button
            variant='contained'
            color='secondary'
            sx={{ width: 'fit-content' }}
            disabled={!useHasPermission([permissions.MODIFY_PARTICIPANT_DETAILS])}
            onClick={() => navigate(`/participant-centre/participants/edit/${participant.id}#care-preferences`)}
          >
            Add Service Preferences
          </Button>
        </Stack>
      )}
    </ServicePreferenceStack>
  );
};

const Disabilities: FC<{ participant: Participant }> = ({ participant }) => {
  const navigate = useNavigate();
  return (
    <StyledStack>
      <LineHeader>
        <Typography variant='subtitle2'>
          Disabilities
        </Typography>
      </LineHeader>
      {participant.primaryDisability || participant.otherDisability ? (
        <>
          <Line>
            <Stack width='100%'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Primary Disability
              </Typography>
              <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
                {participant?.primaryDisability && (participant.primaryDisability as PrimaryDisability)?.name}
              </Typography>
            </Stack>
          </Line>
          <Line noBottomBorder>
            <Stack width='100%'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Primary Language
              </Typography>
              <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
                {participant.otherDisability}
              </Typography>
            </Stack>
          </Line>
        </>
      ) : (
        <Box padding='16px 24px'>
          <Button
            variant='outlined'
            color='secondary'
            sx={{ width: 'fit-content', color: '#0277BD', padding: '4px 8px' }}
            onClick={() => navigate(`/participant-centre/participants/edit/${participant.id}#care-preferences`)}
            disabled={!useHasPermission([permissions.MODIFY_PARTICIPANT_DETAILS])}
          >
            Add disability
          </Button>
        </Box>
      )}
    </StyledStack>
  );
};

const Languages: FC<{ participant: Participant }> = ({ participant }) => {
  const navigate = useNavigate();
  return (
    <StyledStack>
      <LineHeader>
        <Typography variant='subtitle2'>
          Languages
        </Typography>
      </LineHeader>
      {participant.primaryLanguage || participant.otherLanguage ? (
        <>
          <Line>
            <Stack width='100%'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Primary Language
              </Typography>
              <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
                {participant.primaryLanguage}
              </Typography>
            </Stack>
          </Line>
          <Line noBottomBorder>
            <Stack width='100%'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Other Language
              </Typography>
              <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
                {participant.otherLanguage}
              </Typography>
            </Stack>
          </Line>
        </>
      ) : (
        <Box padding='16px 24px'>
          <Button
            variant='outlined'
            color='secondary'
            sx={{ width: 'fit-content', color: '#0277BD', padding: '4px 8px' }}
            disabled={!useHasPermission([permissions.MODIFY_PARTICIPANT_DETAILS])}
            onClick={() => navigate(`/participant-centre/participants/edit/${participant.id}#care-preferences`)}
          >
            Add language
          </Button>
        </Box>
      )}
    </StyledStack>
  );
};

const Misc: FC<{ participant: Participant }> = ({ participant }) => {
  const navigate = useNavigate();
  return (
    <StyledStack>
      <Line flexDirection='column' alignItems='flex-start'>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Cultural Requirements
        </Typography>
        {participant.culturalRequirements ? (
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {participant.culturalRequirements}
          </Typography>
        ) : (
          <Button
            variant='outlined'
            color='secondary'
            sx={{ width: 'fit-content', color: '#0277BD', padding: '4px 8px' }}
            onClick={() => navigate(`/participant-centre/participants/edit/${participant.id}#care-preferences`)}
            disabled={!useHasPermission([permissions.MODIFY_PARTICIPANT_DETAILS])}
          >
            Add cultural requirements
          </Button>
        )}
      </Line>
      <Line flexDirection='column' alignItems='flex-start'>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Allergies
        </Typography>
        {participant.allergies ? (
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {participant.allergies}
          </Typography>
        ) : (
          <Button
            variant='outlined'
            color='secondary'
            sx={{ width: 'fit-content', color: '#0277BD', padding: '4px 8px' }}
            onClick={() => navigate(`/participant-centre/participants/edit/${participant.id}#care-preferences`)}
            disabled={!useHasPermission([permissions.MODIFY_PARTICIPANT_DETAILS])}
          >
            Add allergies
          </Button>
        )}
      </Line>
      <Line flexDirection='column' alignItems='flex-start'>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Living Arrangements
        </Typography>
        {participant.livingArrangements ? (
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {participant.livingArrangements}
          </Typography>
        ) : (
          <Button
            variant='outlined'
            color='secondary'
            sx={{ width: 'fit-content', color: '#0277BD', padding: '4px 8px' }}
            onClick={() => navigate(`/participant-centre/participants/edit/${participant.id}#care-preferences`)}
            disabled={!useHasPermission([permissions.MODIFY_PARTICIPANT_DETAILS])}
          >
            Add living arrangements
          </Button>
        )}
      </Line>
      <Line noBottomBorder flexDirection='column' alignItems='flex-start'>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Interests
        </Typography>
        {participant.interests ? (
          <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
            {participant.interests}
          </Typography>
        ) : (
          <Button
            variant='outlined'
            color='secondary'
            sx={{ width: 'fit-content', color: '#0277BD', padding: '4px 8px' }}
            onClick={() => navigate(`/participant-centre/participants/edit/${participant.id}#care-preferences`)}
            disabled={!useHasPermission([permissions.MODIFY_PARTICIPANT_DETAILS])}
          >
            Add interests
          </Button>
        )}
      </Line>
    </StyledStack >
  );
};

const CarePreferencesStack = styled(Stack)`
  display: flex;
  padding: 24px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 40%;
  min-width: 400px;
  box-sizing: border-box;
  height: fit-content;
  @media (max-width: 1050px) {
    width: 50%;
    min-width: unset;
  }
`;

const StyledStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

const ServicePreferenceStack = styled(Stack)`
  border-radius: 4px;
  border: 1px solid #81D4FA;
  background:rgba(3, 169, 244, 0.04);
  padding: 16px;
  width: 100%;
`;

const PreferencesWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const ServicePreferenceItem = styled(Stack)`
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--components-chips-inviteSent-background, rgba(3, 169, 244, 0.20));
`;

export default CarePreferences;