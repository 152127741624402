import React, { FC, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import Tooltip from 'components/common/Tooltip';

const Controls: FC = () => {

  return (
    <StyledStack>
      <Stack justifyContent='space-between' height='100%' gap='16px'>
        <ActionStack>
          <Typography variant='subtitle2' minWidth='fit-content' fontSize='18px'>
            Linked Progress Notes
          </Typography>
        </ActionStack>
      </Stack>
    </StyledStack>
  );
};

const Filters = styled(Stack)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const StyledStack = styled(Stack)`
  height: fit-content;
  padding: 24px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
`;

const ActionStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  @media (max-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ResetFiltersBox = styled(Stack)`
  cursor: pointer;
  padding: 8px;
  border-left: 1px solid ${variables.colors.secondary.outlineBorder};
  min-width: fit-content;
`;

const StyledTooltip = styled(Tooltip)`
  & .MuiTooltip-popper {
    background-color: white;
  }
`;

export default Controls;