import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import { listLinkedProgressNotes } from 'api/organisations/participants/goals';
import useInvalidateData from 'hooks/useInvalidateData';
import { paginationModelDefault } from 'utils/helpers';

const LinkedProgressNotesDataTable: FC = () => {
  const [, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  const { id, goalId } = useParams();
  useInvalidateData('linked-participant-progress-notes');

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['linked-participant-progress-notes', paginationModel],
    queryFn: () => listLinkedProgressNotes(
      organisations[0].organisation.globalId,
      id as string,
      goalId as string,
      null,
      paginationModel.page + 1,
      paginationModel.pageSize
    ),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.progressNotes,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls />
      <DataTable
        data={data as Data}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        title='Linked Progress Notes'
        dataTableName='linked-participant-progress-notes'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={null}
        allowNoDataImport={false}
      />
    </Stack>
  );
};

export default LinkedProgressNotesDataTable;