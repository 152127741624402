import React from 'react';
import SettingsTabs from 'components/common/SettingsTabs';
import ServiceTeamNoteTypesDataTable from '../DataTables/ServiceTeamNoteTypesDataTable';
import PayPeriodsDataTable from '../DataTables/PayPeriodsDataTable';
import ShiftCancellationReasonsDataTable from '../DataTables/ShiftCancellationReasonsDataTable';
import TimeSheetDeclinedReasonsDataTable from '../DataTables/TimeSheetDeclinedReasonsDataTable';
import TagsDataTable from '../DataTables/TagsDataTable';

const ServiceTeamSettings = () => {
  return (
    <SettingsTabs
      title='Service Team Settings'
      tabs={[
        {
          label: 'Pay Periods',
          content: <PayPeriodsDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Tags',
          content: <TagsDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Note Types',
          content: <ServiceTeamNoteTypesDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Time Sheet Declined Reasons',
          content: <TimeSheetDeclinedReasonsDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Shift Cancellation Reasons',
          content: <ShiftCancellationReasonsDataTable />,
          requiredPermissions: [],
        },
      ]}
    />
  );
};

export default ServiceTeamSettings;