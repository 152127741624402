import React from 'react';
import { useAtom } from 'jotai';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { RelationshipType } from 'types/dbSchema/participantSettings';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { updateRelationshipType } from 'api/organisations/settings/participants/relationship-types';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';

// For MVP 1, we are not implementing the edit functionality, so the dropdown is disabled
// I have created the component for future use - just remove this comment and the disabled prop
const EditStatusDropdown = ({ relationshipType }: { relationshipType: RelationshipType }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('relationship-types');
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateRelationshipType(organisations[0].organisation.globalId, { ...relationshipType, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'relationship-types': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={relationshipType.isActive}
      setIsActive={handleEdit}
      // disabled={!relationshipType.isEditable} // For MVP 1, the dropdown is disabled
      disabled
    />
  );
};

export default EditStatusDropdown;