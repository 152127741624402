import React from "react";
import { Box, Typography } from "@mui/material";
import { priceGuideRegionOptions } from "types";
import { userProfileStatuses } from "types/dbSchema/userProfiles";
import { isActiveStatusObjects } from "components/common/IsActiveDropdown/types";
import { IFilter } from "components/common/FilterWithDropdown/types";
import { statuses } from "types/dbSchema/participants";
import { serviceTimeslots } from "types/dbSchema/schedulingSupports";
import { ndisClaimsStatuses } from "types/dbSchema/ndisClaims";
import { invoiceBatchStatuses } from "types/dbSchema/invoiceBatches";
import { payfileStatuses } from "types/dbSchema/payfiles";
import { deliveredServiceRecordStatuses } from "types/dbSchema/deliveredServiceRecord";

export const goalCommentTypeFilter = {
  field: 'goalCommentType',
  name: 'Comment Type',
  options: [{
    value: 'generalComment',
    label: <Typography variant='body2'>General Comment</Typography>
  },
  {
    value: 'goalReview',
    label: <Typography variant='body2'>Goal Review</Typography>
  }]
};

export const goalTypeFilter = {
  field: 'goalType',
  name: 'Goal Type',
  options: [
    {
      value: 'shortTerm',
      label: <Typography variant='body2'>Short Term</Typography>
    },
    {
      value: 'longTerm',
      label: <Typography variant='body2'>Long Term</Typography>
    }
  ]
};

export const goalStatusFilter = {
  field: 'status',
  name: 'Status',
  options: [
    {
      value: 'notStarted',
      label: <Typography variant='body2'>Not Started</Typography>
    },
    {
      value: 'inProgress',
      label: <Typography variant='body2'>In Progress</Typography>
    },
    {
      value: 'completed',
      label: <Typography variant='body2'>Completed</Typography>
    }
  ]
};

export const userTypeFilters = {
  field: 'userType',
  name: 'User Type',
  options: [
    {
      value: 'webAppUser',
      label: <Typography variant='body2'>Web App User</Typography>
    },
    {
      value: 'mobileAppUser',
      label: <Typography variant='body2'>Mobile App User</Typography>
    }
  ]
};

export const userProfileStatusFilter = {
  field: 'status',
  name: 'Status',
  options: userProfileStatuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Box padding='8px 16px'>
          <Box sx={{ backgroundColor: status.backgroundColor, padding: '2px 4px', borderRadius: '4px', width: 'fit-content', cursor: 'pointer', position: 'relative' }}>
            <Typography variant='body2' fontWeight='600' color={status.fontColor}>
              {status.label}
            </Typography>
          </Box>
        </Box>
      )
    };
  })
};

export const participantProfileStatusFilter = {
  field: 'status',
  name: 'Status',
  options: statuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Box padding='8px 16px'>
          <Box sx={{ backgroundColor: status.backgroundColor, padding: '2px 4px', borderRadius: '4px', width: 'fit-content', cursor: 'pointer', position: 'relative' }}>
            <Typography variant='body2' fontWeight='600' color={status.fontColor}>
              {status.label}
            </Typography>
          </Box>
        </Box>
      )
    };
  })
};

export const serviceTimeslotsFilter = {
  field: 'deliveredDuring',
  name: 'Delivered During',
  options: serviceTimeslots.map((status) => {
    return {
      value: status,
      label: (
        <Typography variant='body2'>
          {status}
        </Typography>
      )
    };
  })
};

export const pricingRegionFilter = {
  field: 'pricingRegion',
  name: 'Pricing Region',
  options: priceGuideRegionOptions.map((region) => ({
    label: (
      <Typography variant='body2'>
        {region.label}
      </Typography>
    ),
    value: region.value,
  })),
};

export const isActiveFilter: IFilter = {
  field: 'status',
  name: 'Status',
  options: isActiveStatusObjects.map((isActive) => ({
    label: (
      <Box padding='8px 16px'>
        <Box sx={{ backgroundColor: isActive.backgroundColor, padding: '2px 4px', borderRadius: '4px', width: 'fit-content', cursor: 'pointer', position: 'relative' }}>
          <Typography variant='body2' fontWeight='600' color={isActive.fontColor}>
            {isActive.label}
          </Typography>
        </Box>
      </Box>
    ),
    value: isActive.isActive.toString()
  })),
};

export const ndisClaimsStatusFilter = {
  field: 'status',
  name: 'Status',
  options: ndisClaimsStatuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Box padding='8px 16px'>
          <Box sx={{ backgroundColor: status.backgroundColor, padding: '2px 4px', borderRadius: '4px', width: 'fit-content', cursor: 'pointer', position: 'relative' }}>
            <Typography variant='body2' fontWeight='600' color={status.fontColor}>
              {status.label}
            </Typography>
          </Box>
        </Box>
      )
    };
  })
};

export const payfileStatusFilter = {
  field: 'status',
  name: 'Status',
  options: payfileStatuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Box padding='8px 16px'>
          <Box sx={{ backgroundColor: status.backgroundColor, padding: '2px 4px', borderRadius: '4px', width: 'fit-content', cursor: 'pointer', position: 'relative' }}>
            <Typography variant='body2' fontWeight='600' color={status.fontColor}>
              {status.label}
            </Typography>
          </Box>
        </Box>
      )
    };
  })
};

export const invoiceBatchStatusFilter = {
  field: 'status',
  name: 'Status',
  options: invoiceBatchStatuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Box padding='8px 16px'>
          <Box sx={{ backgroundColor: status.backgroundColor, padding: '2px 4px', borderRadius: '4px', width: 'fit-content', cursor: 'pointer', position: 'relative' }}>
            <Typography variant='body2' fontWeight='600' color={status.fontColor}>
              {status.label}
            </Typography>
          </Box>
        </Box>
      )
    };
  })
};

export const deliveredServiceStatusFilter = {
  field: 'status',
  name: 'Status',
  options: deliveredServiceRecordStatuses.map((status) => {
    return {
      value: status.value,
      label: (
        <Box padding='8px 16px'>
          <Box sx={{ backgroundColor: status.backgroundColor, padding: '2px 4px', borderRadius: '4px', width: 'fit-content', cursor: 'pointer', position: 'relative' }}>
            <Typography variant='body2' fontWeight='600' color={status.fontColor}>
              {status.label}
            </Typography>
          </Box>
        </Box>
      )
    };
  })
};

export const quoteFilter = {
  field: 'quote',
  name: 'Quote',
  options: [
    {
      value: 'yes',
      label: <Typography variant='body2'>Yes</Typography>
    },
    {
      value: 'no',
      label: <Typography variant='body2'>No</Typography>
    }
  ]
};

export const nonFaceToFaceSupportProvisionFilter = {
  field: 'nonFaceToFaceSupportProvision',
  name: 'Non-F2F',
  options: [
    {
      value: 'yes',
      label: <Typography variant='body2'>Yes</Typography>
    },
    {
      value: 'no',
      label: <Typography variant='body2'>No</Typography>
    }
  ]
};

export const providerTravelFilter = {
  field: 'providerTravel',
  name: 'Provider Travel',
  options: [
    {
      value: 'yes',
      label: <Typography variant='body2'>Yes</Typography>
    },
    {
      value: 'no',
      label: <Typography variant='body2'>No</Typography>
    }
  ]
};

export const shortNoticeCancellationsFilter = {
  field: 'shortNoticeCancellations',
  name: 'Short Notice Cancellations',
  options: [
    {
      value: 'yes',
      label: <Typography variant='body2'>Yes</Typography>
    },
    {
      value: 'no',
      label: <Typography variant='body2'>No</Typography>
    }
  ]
};

export const ndiaRequestedReportsFilter = {
  field: 'ndiaRequestedReports',
  name: 'NDIA Requested Reports',
  options: [
    {
      value: 'yes',
      label: <Typography variant='body2'>Yes</Typography>
    },
    {
      value: 'no',
      label: <Typography variant='body2'>No</Typography>
    }
  ]
};

export const irregularSILSupportsFilter = {
  field: 'irregularSILSupports',
  name: 'Irregular SIL Supports',
  options: [
    {
      value: 'yes',
      label: <Typography variant='body2'>Yes</Typography>
    },
    {
      value: 'no',
      label: <Typography variant='body2'>No</Typography>
    }
  ]
};

export const serviceProviderFilters = [
  quoteFilter,
  nonFaceToFaceSupportProvisionFilter,
  providerTravelFilter,
  shortNoticeCancellationsFilter,
  ndiaRequestedReportsFilter,
  irregularSILSupportsFilter,
  isActiveFilter
];