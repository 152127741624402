import React, { FC, useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import ServiceTeamEmployeeDetails from 'components/features/Scheduling/ServiceTeamSchedule/ServiceTeamEmployeeDetails';
import { useAtom } from 'jotai';
import { sidebarAtom } from 'atoms';
import ServiceTeamMemberDetails from 'components/features/ServiceTeamMemberDetails';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getUserById } from 'api/organisations/users';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';

const ViewServiceTeamDetailsPage: FC = () => {
  const [sidebarState, setSidebarState] = useAtom(sidebarAtom);
  const [organisations] = useUserOrganisations();
  const { id } = useParams();

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getUserById(organisations[0].organisation.globalId, id as string)
  });

  useEffect(() => {
    setSidebarState({
      ...sidebarState,
      content: <ServiceTeamMemberDetails />,
    });
  }, [setSidebarState, sidebarState]);

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ServiceTeamEmployeeDetails />;
};

export default ViewServiceTeamDetailsPage;