import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import styled from 'styled-components';
import DataTable from 'components/common/DataTable';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PaginationModel } from 'components/common/DataTable/types';
import useInvalidateData from 'hooks/useInvalidateData';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { dummyAvailabilitiesTemplates } from 'utils/helpers/getDBData';
import { useNavigate } from 'react-router-dom';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';
import { Columns } from './Columns';

const AvailabilityTemplatesDataTable = () => {
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  useInvalidateData('availability-template');
  const navigate = useNavigate();

  const { isError, data, isPending, isFetching, isLoading } = useQuery({
    queryKey: ['availability-template', paginationModel],
    // queryFn: () => listAvailabilityTemplates(organisations[0].organisation.globalId, paginationModel.page + 1, paginationModel.pageSize),
    queryFn: () => dummyAvailabilitiesTemplates,
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.availabilityTemplates,
  });

  return (
    <StyledBox>
      <SearchBox>
        <Button variant='contained' sx={{ padding: '8px 16px' }} onClick={() => navigate('/settings/availability-settings/create')}>
          <AddOutlinedIcon sx={{ marginRight: '8px' }} />
          Add
        </Button>
      </SearchBox>
      <Box height='calc(100vh - 295px)'>
        <DataTable
          // data={data as Data}
          data={{ items: data || [], totalCount: 1, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
          columns={Columns}
          checkboxSelection={false}
          dataTableName='availability-template'
          isPending={isFetching || isPending || isLoading}
          isError={isError}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          title='Leave Types'
          paginationMode='server'
          onNoData={() => navigate('/settings/availability-settings/create')}
        />
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: white;
`;

const SearchBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
`;

export default AvailabilityTemplatesDataTable;