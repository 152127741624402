import { Button, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import variables from 'styles/variables';
import { useAtom } from 'jotai';
import { closeModalAtom, modalStateAtom } from 'atoms/modalAtom';
import { IconOnlyState } from 'components/common/InputFields/DragAndDropFileUpload/FileStates';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ParticipantGoalPartial, ProgressNote, ProgressNoteTypePartial } from 'types/dbSchema/progressNotes';
import Score from 'components/common/Score';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import { format, parseISO } from 'date-fns';
import { downloadMultipleAttachments } from 'utils/helpers';
import { NameAndID } from 'types/dbSchema/userProfiles';
import ParticipantGoalLookupField from 'components/common/LookupInputField/ParticipantGoalLookupField';
import { Controller, useForm } from 'react-hook-form';
import { updateParticipantProgressNoteById } from 'api/organisations/participants/progressNotes';
import useUserOrganisations from 'hooks/useUserOrganisations';
import LinkIcon from '@mui/icons-material/Link';
import { convertProgressNote } from 'utils/helpers/postDataConvert';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';

export interface ViewProgressNotesProps {
  progressNote: ProgressNote;
  participantID: string;
}

const ViewProgressNotes: FC<ViewProgressNotesProps> = ({ progressNote, participantID }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const totalAttachmentSize = progressNote.attachments ? (progressNote.attachments?.reduce((acc, file) => acc + file.fileSize, 0) / 1048576).toFixed(2) : 0;

  const downloadAllFiles = () => {
    if (!progressNote.attachments) return;
    downloadMultipleAttachments(progressNote.attachments);
  };

  const handleFullScreen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: modalState.position === 'center' ? 'right' : 'center',
      component: ViewProgressNotes,
      props: {
        progressNote,
        participantID,
      }
    });
  };

  return (
    <Stack padding='32px' width={modalState.position === 'center' ? '80vw' : 'unset'} height={modalState.position === 'center' ? '80vh' : 'unset'} overflow='auto'>
      <Stack gap='16px'>
        <Stack gap='16px' flexDirection='row' justifyContent='space-between'>
          <Stack gap='16px' flexDirection='row' alignItems='center'>
            <Typography variant='body2' color={variables.colors.text.secondary} width='120px' minWidth='120px' >Created by</Typography>
            <Typography variant='subtitle2'>{(progressNote.createdBy as NameAndID).fullName}</Typography>
          </Stack>
          <Stack gap='16px' flexDirection='row' alignItems='center'>
            {modalState.position === 'center' ?
              <CloseFullscreenOutlinedIcon onClick={handleFullScreen} sx={{ cursor: 'pointer' }} /> :
              <OpenInFullOutlinedIcon onClick={handleFullScreen} sx={{ cursor: 'pointer' }} />}
            <CloseOutlinedIcon onClick={closeModal} sx={{ cursor: 'pointer' }} />
          </Stack>
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='center'>
          <Typography variant='body2' color={variables.colors.text.secondary} width='120px' minWidth='120px' >Shift Details</Typography>
          <Typography variant='body2'>
            {progressNote.shiftDetails ? format(parseISO(progressNote.shiftDetails), 'dd/MM/yyyy, hh:mm a') : ''}
          </Typography>
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='center'>
          <Typography variant='body2' color={variables.colors.text.secondary} width='120px' minWidth='120px' >Note Type</Typography>
          <Typography variant='body2'>{(progressNote.progressNoteType as ProgressNoteTypePartial).name}</Typography>
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='center'>
          <Typography variant='body2' color={variables.colors.text.secondary} width='120px' minWidth='120px' >Score</Typography>
          <Score score={progressNote.score} />
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='center'>
          <Typography variant='body2' color={variables.colors.text.secondary} width='120px' minWidth='120px' >Linked Goal</Typography>
          <LinkToGoalDropdown participantID={participantID} progressNote={progressNote} />
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='flex-start'>
          <Stack flexDirection='row' justifyContent='space-between'>
            <Typography variant='body2' color={variables.colors.text.secondary} width='120px' minWidth='120px' >Attachments</Typography>
          </Stack>
          <Stack gap='8px'>
            <Stack gap='8px' width='100%' alignContent='flex-start' flexDirection='row' flexWrap='wrap'>
              {progressNote.attachments?.map((file, index) => (
                <IconOnlyState key={index} uploadedFile={file} />
              ))}
            </Stack>
            {progressNote.attachments && progressNote.attachments.length > 0 && (
              <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
                <Typography variant='subtitle2' color={variables.colors.text.secondary} fontSize='12px' fontWeight='500'>
                  `${progressNote.attachments.length} attachment${progressNote.attachments && progressNote.attachments.length > 1 ? 's' : ''} (${totalAttachmentSize} MB)`
                </Typography>
                <Button variant='text' size='small' onClick={downloadAllFiles} startIcon={<FileDownloadOutlinedIcon />} sx={{ width: 'fit-content', color: '#1F293799' }}>
                  Download all
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='center'>
          <Typography variant='body2'>{progressNote.note}</Typography>
        </Stack>
      </Stack>
    </Stack >
  );
};

export interface LinkToGoalDropdownProps {
  participantID: string;
  progressNote: ProgressNote;
}

const LinkToGoalDropdown: FC<LinkToGoalDropdownProps> = ({ participantID, progressNote }) => {
  const [organisations] = useUserOrganisations();
  const [, closeModal] = useAtom(closeModalAtom);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();

  const { control, handleSubmit, formState: { errors } } = useForm<ProgressNote>({
    defaultValues: {
      participantGoal: progressNote.participantGoal ? (progressNote.participantGoal as ParticipantGoalPartial).id : ''
    },
    mode: 'onChange'
  });

  const handleLinkToGoal = async (data: any) => {
    try {
      if (!progressNote.id) return;

      await updateParticipantProgressNoteById(
        organisations[0]?.organisation.globalId,
        participantID,
        progressNote.id,
        convertProgressNote(progressNote, data.participantGoal),
      );
      setInvalidateData((prev) => ({ ...prev, 'participant-progress-notes': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleUnlinkFromGoal = async () => {
    try {
      if (!progressNote.id) return;

      await updateParticipantProgressNoteById(
        organisations[0]?.organisation.globalId,
        participantID,
        progressNote.id,
        convertProgressNote(progressNote, null),
      );
      setInvalidateData((prev) => ({ ...prev, 'participant-progress-notes': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };


  if (progressNote.participantGoal && (progressNote.participantGoal as ParticipantGoalPartial).id) return (
    <Stack flexDirection={'row'} gap='16px' alignItems='center' justifyContent='space-between' width='100%' maxWidth='278px'>
      <Typography variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}>
        {(progressNote.participantGoal as ParticipantGoalPartial).goal}
      </Typography>
      <LinkOffIcon
        sx={{ cursor: 'pointer' }}
        onClick={handleUnlinkFromGoal}
      />
    </Stack>
  );

  return (
    <form onSubmit={handleSubmit(handleLinkToGoal)} style={{ width: '100%' }}>
      <Stack minWidth='100%' gap='16px' flexDirection='row' maxWidth='180px'>
        <Controller
          name="participantGoal"
          control={control}
          rules={{ required: 'Note Type is required' }}
          render={({ field }) =>
            <ParticipantGoalLookupField
              {...field}
              id='participantGoal'
              label=''
              validationError={errors.participantGoal ? true : false}
              errorText={errors.participantGoal?.message}
              participantId={participantID}
              placeholder='Select goal'
            />
          }
        />
        <Button
          type='submit'
          variant='outlined'
          color='secondary'
          startIcon={<LinkIcon />}
          sx={{ minWidth: 'fit-content', height: '100%', alignSelf: 'center' }}
        >
          Link
        </Button>
      </Stack>
    </form>
  );
};

export default ViewProgressNotes;