import React from 'react';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import variables from 'styles/variables';
import { Address } from 'types';
import EditIcon from './EditIcon';
import EditStatusDropdown from './EditStatusDropdown';

export const Columns: GridColDef[] = [
  {
    field: 'edit',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <EditIcon shiftLocation={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Location Name',
    flex: 1,
    renderCell: (params) => (
      <Typography variant='subtitle2'>
        {params.row.name}
      </Typography>
    ),
  },
  {
    field: 'address',
    headerName: 'Location Address',
    flex: 3,
    renderCell: (params) => {
      const address = params.row.address as Address;
      return (
        <Typography variant='body2' color={variables.colors.text.secondary}>
          {address.streetAddress}, {address.suburb}, {address.state}, {address.postCode}
        </Typography>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <EditStatusDropdown shiftLocation={params.row} />
    ),
  },
];