import React, { forwardRef, useEffect, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { Typography } from '@mui/material';
import { LookupSelectFieldOptions, WrapperLookupFieldProps } from '../types';
import LookupInputField from '..';
import { ExitReason } from 'types/dbSchema/participantSettings';
import { listExitReasons } from 'api/organisations/settings/participants/exit-reasons';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

const ExitReasonLookupField = forwardRef<HTMLDivElement, WrapperLookupFieldProps>(({
  id,
  label,
  onChange,
  errorText,
  helperText,
  isMandatory,
  placeholder,
  validationError,
  value
}, ref) => {
  const [organisations] = useUserOrganisations();
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState<LookupSelectFieldOptions[]>([]);

  // useInfiniteQuery is a hook that allows you to fetch data on scroll
  // This function handles the fetching of exit reasons in the organisation
  const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery({
    queryKey: ['exit-reasons-infinite', organisations[0]?.organisation.globalId, 100],
    queryFn: ({ pageParam = 0 }) => listExitReasons(organisations[0].organisation.globalId, pageParam + 1, 100),
    initialPageParam: 0,
    staleTime: cachingInvalidation.exitReasons,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      const totalLoadedItems = allPages.flatMap(page => page.items).length;
      if (totalLoadedItems >= lastPage.totalCount) {
        return undefined; // No more pages to load
      }
      return allPages.length;
    },
  });

  // When the data is fetched, map the exitReasons to the options
  // The label is JSX so that we can customise the look of the options
  useEffect(() => {
    if (data) {
      const newOptions = data.pages.flatMap(page =>
        page.items.map((exitReason: ExitReason) => ({
          value: exitReason.id,
          label: (
            <Typography key={exitReason.id} variant='body2'>
              {exitReason.name}
            </Typography>
          ),
        }))
      );
      setOptions(newOptions);
    }
  }, [data]);

  return (
    <LookupInputField
      id={id}
      ref={ref}
      label={label}
      options={options}
      errorText={errorText}
      helperText={helperText}
      searchValue={searchText}
      placeholder={placeholder}
      isMandatory={isMandatory}
      hasNextPage={hasNextPage}
      dataFetchingError={error}
      fetchNextPage={fetchNextPage}
      setSearchValue={setSearchText}
      validationError={validationError}
      isFetchingNextPage={isFetchingNextPage}
      isFetching={isFetching || status === 'pending'}
      onChange={(value: string) => onChange(value)}
      value={value}
    />
  );
});


ExitReasonLookupField.displayName = 'ExitReasonLookupField';

export default ExitReasonLookupField;