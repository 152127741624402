import React from 'react';
import SettingsTabs from 'components/common/SettingsTabs';
import ParticipantStatusesDataTable from 'components/features/DataTables/ParticipantStatusesDataTable';
import RelationshipTypesDataTable from 'components/features/DataTables/RelationshipTypesDataTable';
import PrimaryDisabilitiesDataTable from 'components/features/DataTables/PrimaryDisabilitiesDataTable';
import ProgressNoteTypesDataTable from 'components/features/DataTables/ProgressNoteTypesDataTable';
import ExitReasonsDataTable from 'components/features/DataTables/ExitReasonsDataTable';
import GeneralNoteTypesDataTable from 'components/features/DataTables/GeneralNotesTypesDataTable';

const ParticipantSettings = () => {
  return (
    <SettingsTabs
      title='Participant Settings'
      tabs={[
        {
          label: 'Statuses',
          content: <ParticipantStatusesDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'General Notes Types',
          content: <GeneralNoteTypesDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Relationship Types',
          content: <RelationshipTypesDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Primary Disabilities',
          content: <PrimaryDisabilitiesDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Progress Notes Types',
          content: <ProgressNoteTypesDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Exit Reasons',
          content: <ExitReasonsDataTable />,
          requiredPermissions: [],
        },
      ]}
    />
  );
};

export default ParticipantSettings;