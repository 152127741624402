import React, { FC } from 'react';
import { updateParticipantById } from 'api/organisations/participants';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { closeModalAtom } from 'atoms/modalAtom';
import variables from 'styles/variables';
import useErrorMessage from 'hooks/useErrorMessage';
import { Controller, useForm } from 'react-hook-form';
import DatePickerInput from 'components/common/InputFields/DatePickerInput';
import ExitReasonLookupField from 'components/common/LookupInputField/ExitReasonLookupField';
import { convertParticipant } from 'utils/helpers/postDataConvert';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { ModalContentProps } from '../types';

// This is the modal to handle the status being set to inactive
const InactiveModalContent: FC<ModalContentProps> = ({ participant, status }) => {
  const [organisations] = useUserOrganisations();
  const [, closeModal] = useAtom(closeModalAtom);
  const showError = useErrorMessage();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const { control, handleSubmit, formState: { errors }, getValues } = useForm({
    defaultValues: {
      exitDate: new Date().toISOString(),
      exitReason: null,
    },
    mode: 'onChange',
  });

  const handleFormSubmit = async () => {
    try {
      // Create a new object with the updated status and exit date
      const data = {
        ...participant,
        status,
        exitDate: getValues('exitDate'),
        exitReason: getValues('exitReason'),
        deceasedDate: null
      } as any;
      // Update the participant with the new object in the DB
      await updateParticipantById(organisations[0].organisation.globalId, participant.id as string, convertParticipant(data));
      setInvalidateData((prev) => ({ ...prev, 'participants': true }));
      // Close the modal
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <Stack width='500px' boxSizing='border-box' height='fit-content'>
      <form style={{ position: 'relative', width: '100%', height: '100%' }} onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack gap='24px' width='100%' padding='32px' boxSizing='border-box'>
          <Stack gap='12px'>
            <Typography variant='h6'>
              Participant status update
            </Typography>
            <Typography variant='body2' color={variables.colors.text.secondary} component='div'>
              You are about to update the participant status to <Box display='inline' color={variables.colors.text.primary} fontWeight='700'>inactive</Box>, please complete information below and update.
            </Typography>
          </Stack>
          <Controller
            name="exitDate"
            control={control}
            rules={{ required: 'Exit date is required' }}
            render={({ field }) =>
              <DatePickerInput
                {...field}
                id='exitDate'
                label='Exit date'
                error={errors.exitDate ? true : false}
                errorText={errors.exitDate?.message}
                isMandatory
              />}
          />
          <Controller
            name="exitReason"
            control={control}
            rules={{ required: 'Exit reason is required' }}
            render={({ field }) =>
              <ExitReasonLookupField
                {...field}
                id='exitReason'
                label='Exit reason'
                validationError={errors.exitReason ? true : false}
                errorText={errors.exitReason?.message}
                isMandatory
                placeholder='Select'
              />
            }
          />
        </Stack>
        <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
          <Button variant='text' onClick={closeModal}>Cancel</Button>
          <Button variant='contained' type='submit'>Update status</Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default InactiveModalContent;