import React from 'react';
import { useAtom } from 'jotai';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { updatePrimaryDisabilities } from 'api/organisations/settings/participants/primary-disabilities';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import { PrimaryDisability } from 'types/dbSchema/participantSettings';

const EditStatusDropdown = ({ primaryDisability }: { primaryDisability: PrimaryDisability }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('primary-disabilities');
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updatePrimaryDisabilities(organisations[0].organisation.globalId, { ...primaryDisability, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'primary-disabilities': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={primaryDisability.isActive}
      setIsActive={handleEdit}
      disabled={!primaryDisability.isEditable}
    />
  );
};

export default EditStatusDropdown;