import React from 'react';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { LinearProgress } from '@mui/material';
import { getOrganisationDetails } from 'api';
import OrganisationSettings from 'components/features/OrganisationSettings';

const OrganisationSettingsPage = () => {
  const [organisations] = useUserOrganisations();

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
    getByIDFunc: () => getOrganisationDetails(organisations[0].organisation.globalId)
  });

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <OrganisationSettings organisationDetails={currentDataPoint} />;
};

export default OrganisationSettingsPage;