import React from 'react';
import { useAtom } from 'jotai';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { ServiceProviderPosition } from 'types/dbSchema/serviceProviderSettings';
import { updateServiceProviderPosition } from 'api/organisations/settings/service-providers/positions';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';

const EditStatusDropdown = ({ serviceProviderPosition }: { serviceProviderPosition: ServiceProviderPosition }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('service-provider-positions');
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateServiceProviderPosition(organisations[0].organisation.globalId, { ...serviceProviderPosition, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'service-provider-positions': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={serviceProviderPosition.isActive}
      setIsActive={handleEdit}
      disabled={!serviceProviderPosition.isEditable}
    />
  );
};

export default EditStatusDropdown;