import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import styled from 'styled-components';
import variables from 'styles/variables';
import { EditOutlined } from '@mui/icons-material';
import { useAtom } from 'jotai';
import { modalStateAtom, triggerRefreshCurrentDataPointAtom } from 'atoms';
import { UnlinkModal } from 'components/features/DataTables/ParticipantContactsDataTable/Controls/Columns/UnlinkIcon';
import LineHeader from 'components/common/LineHeader';
import Line from 'components/common/Line';
import { RelationshipType } from 'types/dbSchema/participantSettings';
import Label from 'components/common/Label';
import { NameAndID } from 'types/dbSchema/userProfiles';
import { ParticipantAndContactLink, UpdateParticipantAndContactLink } from 'types/dbSchema/participantContact';
import { Participant } from 'types/dbSchema/participants';
import { closeModalAtom } from 'atoms/modalAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { updateParticipantExternalContactLink } from 'api/organisations/participants/externalcontacts';
import TitleWithClose from 'components/common/TitleWithClose';
import { Controller, useForm } from 'react-hook-form';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import RelationshipTypeLookupField from 'components/common/LookupInputField/RelationshipTypeLookupField';
import UnlinkButton from './UnlinkButton';

export interface ViewParticipantContactProps {
  participantAndContactLink: ParticipantAndContactLink;
  participantId: string;
  contactId: string;
}

const ViewParticipantContact: FC<ViewParticipantContactProps> = ({ participantAndContactLink, participantId, contactId }) => {

  return (
    <Stack width='100%' justifyContent='flex-start' alignItems='center' height='100%' sx={{
      backgroundColor: 'white',
      minHeight: `calc(100vh - ${variables.heights.topBar})`,
      overflowY: 'auto',
    }}>
      <PageHeader participantAndContactLink={participantAndContactLink} participantId={participantId} contactId={contactId} />
      <Stack gap='24px' width='100%' height='fit-content' padding='0 24px 24px' >
        <Stack gap='5px'>
          <ContactDetailsStack>
            <LineHeader>
              <Typography variant='subtitle2'>
                Contact Details
              </Typography>
            </LineHeader>
            <Line>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Email
                </Typography>
                <Typography variant='body2'>
                  {participantAndContactLink.email}
                </Typography>
              </Stack>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Mobile Number
                </Typography>
                <Typography variant='body2'>
                  {participantAndContactLink.mobile}
                </Typography>
              </Stack>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Phone Number
                </Typography>
                <Typography variant='body2'>
                  {participantAndContactLink.phone}
                </Typography>
              </Stack>
            </Line>
            <Line noBottomBorder>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Relationship to Participant
                </Typography>
                <Typography variant='body2'>
                  {(participantAndContactLink.relationship as RelationshipType).name}
                </Typography>
              </Stack>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Service Provider
                </Typography>
                {/* <Typography variant='body2'>
                  {(participantAndContactLink.serviceProvider as ServiceProvider)?.companyName}
                </Typography> */}
              </Stack>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Contact Tags
                </Typography>
                <Stack direction='row' gap='8px'>
                  {participantAndContactLink.isPrimaryContact && <Label text='Primary' />}
                  {participantAndContactLink.isEmergencyContact && <Label text='Emergency' />}
                </Stack>
              </Stack>
            </Line>
          </ContactDetailsStack>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {`Created by ${(participantAndContactLink.createdBy as NameAndID).fullName}
             on ${new Date(participantAndContactLink.createdDateTime).toLocaleDateString()}`}
          </Typography>
        </Stack>
        <Stack gap='24px'>
          <Typography variant='subtitle1'>
            Linked Participants
          </Typography>
          <Box display='grid' gridTemplateColumns='repeat(auto-fill, minmax(368px, 1fr))' gap='24px'>
            {participantAndContactLink.linkedParticipants.map((participant: Participant) => (
              <LinkedParticipant key={participant.id} participant={participant} />
            ))}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export interface LinkedParticipantProps {
  participant: Participant;
}

const LinkedParticipant: FC<LinkedParticipantProps> = ({ participant }) => {
  return (
    <Stack
      flexDirection='row'
      gap='16px'
      alignItems='center'
      justifyContent='space-between'
      minWidth='368px'
      padding='16px 24px'
      sx={{
        backgroundColor: '#F9FAFB',
        border: '1px solid #E5E7EB',
        borderRadius: '4px',
      }}
    >
      <Stack flexDirection='row' gap='16px' alignItems='center'>
        <Box height='100%' width='fit-content' display='flex' alignItems='center'>
          <img src={participant.profilePicture} alt="profile" style={{ width: '42px', height: '42px', borderRadius: '32px', objectFit: 'cover' }} />
        </Box>
        <Stack>
          <Typography variant='subtitle2'>
            {participant.fullName.firstName} {participant.fullName.lastName}
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {participant.email}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const PageHeader: FC<ViewParticipantContactProps> = ({ participantAndContactLink, participantId, contactId }) => {
  const navigate = useNavigate();
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: UnlinkModal,
      props: {
        dataTableName: 'participant-contacts',
        externalContact: participantAndContactLink,
        contactId,
        participantId,
        backAfterUnlink: true,
      }
    }));
  }, [setModalState]);

  // Function to open/close the modal.
  const handleEditModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: UpdateContactLinkForm,
      props: {
        dataTableName: 'participant-contacts',
        participantAndContactLink,
        participantId,
        contactId,
      }
    }));
  }, [setModalState, participantAndContactLink]);

  return (
    <PageHeaderStack>
      <Button variant='text'
        sx={{
          color: variables.colors.text.secondary,
          textTransform: 'none',
          fontWeight: 400,
          padding: 0
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackOutlinedIcon sx={{
          padding: '4px',
          boxSizing: 'border-box',
          height: '24px',
          width: '24px',
        }}
        />
        Contacts
      </Button>
      <PageHeaderDetails>
        <Typography variant='h5' sx={{ textWrap: 'wrap' }}>
          {participantAndContactLink.fullName.firstName} {participantAndContactLink.fullName.lastName}
        </Typography>
        <Stack flexDirection='row' gap='16px' width='fit-content' alignItems='center'>
          <UnlinkButton />
          <Button startIcon={<EditOutlined />} variant='contained' color='primary' onClick={handleEditModalOpen}>
            Edit
          </Button>
        </Stack>
      </PageHeaderDetails>
    </PageHeaderStack >
  );
};

const UpdateContactLinkForm: FC<ViewParticipantContactProps> = ({ participantAndContactLink, participantId, contactId }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [, setTriggerRefresh] = useAtom(triggerRefreshCurrentDataPointAtom);
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: {
      isPrimaryContact: participantAndContactLink.isPrimaryContact,
      isEmergencyContact: participantAndContactLink.isEmergencyContact,
      relationshipType: (participantAndContactLink.relationship as RelationshipType).id,
    } as UpdateParticipantAndContactLink
  });

  const submitForm = async (data: any) => {
    if (!participantId || !contactId) {
      showError('Participant, Contact or Contact Link not found');
      return;
    }
    try {
      await updateParticipantExternalContactLink(
        organisations[0].organisation.globalId,
        contactId,
        participantId,
        data
      );
      setInvalidateData((prev) => ({ ...prev, 'participant-contacts': true }));
      setTriggerRefresh((prev) => prev + 1);
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit((data) => submitForm(data))}>
      <Stack alignItems="center" width='500px'>
        <Stack alignItems="center" gap='24px' width='100%' sx={{ padding: '32px' }}>
          <TitleWithClose title='Edit Contact' handleClose={closeModal} />
          <Stack width='100%' gap='24px'>
            <Controller
              name="relationshipType"
              control={control}
              rules={{
                required: 'Relationship is required',
              }}
              render={({ field }) =>
                <RelationshipTypeLookupField
                  {...field}
                  id='relationshipType'
                  label='Relationship'
                  placeholder='Select'
                  validationError={errors.relationshipType ? true : false}
                  errorText={errors.relationshipType?.message}
                  isMandatory
                />
              }
            />
            <Stack>
              <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
                Contact Tags
              </Typography>
              <Stack flexDirection='row' gap='48px'>
                <Controller
                  name="isPrimaryContact"
                  control={control}
                  render={({ field }) =>
                    <CheckboxInput
                      {...field}
                      id='isPrimaryContact'
                      label="Primary Contact"
                      error={errors.isPrimaryContact ? true : false}
                      checked={field.value ? true : false}
                    />}
                />
                <Controller
                  name="isEmergencyContact"
                  control={control}
                  render={({ field }) =>
                    <CheckboxInput
                      {...field}
                      id='isEmergencyContact'
                      label="Emergency Contact"
                      error={errors.isEmergencyContact ? true : false}
                      checked={field.value ? true : false}
                    />}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack >
        <ButtonStack>
          <Button variant='outlined' onClick={closeModal}>Cancel</Button>
          <Button variant='contained' color='secondary' type='submit'>Update</Button>
        </ButtonStack>
      </Stack >
    </form>
  );
};

const PageHeaderStack = styled(Stack)`
  display: flex;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 8px;
`;

const PageHeaderDetails = styled(Stack)`
 flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
`;

const ContactDetailsStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;

export default ViewParticipantContact;