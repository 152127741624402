import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { GeneralNoteType } from 'types/dbSchema/participantSettings';
import GeneralNoteTypeForm from 'components/features/Forms/GeneralNoteTypeForm';

const EditIcon = ({ generalNoteType }: { generalNoteType: GeneralNoteType }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  if (!generalNoteType || !generalNoteType.isEditable) return null;

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: GeneralNoteTypeForm,
      props: {
        dataTableName: 'general-note-types',
        type: 'edit',
        generalNoteType: generalNoteType,
      }
    }));
  }, [setModalState, generalNoteType]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default EditIcon;