import { Box, Stack, Typography } from '@mui/material';
import FilterWithDropdown from 'components/common/FilterWithDropdown';
import { IFilter, SelectedFilter } from 'components/common/FilterWithDropdown/types';
import SearchInput from 'components/common/InputFields/SearchInput';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import variables from 'styles/variables';
import { ViewParticipantsGoalProps } from '../..';
import RichTextInput from 'components/common/InputFields/RichTextInput';
import UserProfileCell from 'components/features/UserProfileCell';
import useCurrentUser from 'hooks/useCurrentUser';
import { User } from 'types/dbSchema/userProfiles';
import CommentCard from '../CommentCard';
import { Comment } from 'types/dbSchema/participantGoals';
import { addCommentToGoal } from 'api/organisations/participants/goals';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useParams } from 'react-router-dom';
import useErrorMessage from 'hooks/useErrorMessage';
import { useAtom } from 'jotai';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { triggerRefreshCurrentDataPointAtom } from 'atoms';
import { goalCommentTypeFilter, goalStatusFilter } from 'utils/filters';

const GoalComments: FC<ViewParticipantsGoalProps> = ({ participantGoal }) => {
  const [currentUser] = useCurrentUser();
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [filteredComments, setFilteredComments] = useState<Comment[]>(participantGoal.comments);
  // const [searchText, setSearchText] = useState('');
  const [filterOpen, setFilterOpen] = useState('');
  const [organisations] = useUserOrganisations();
  const [, setTriggerRefresh] = useAtom(triggerRefreshCurrentDataPointAtom);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const { id } = useParams();

  const handleNewComment = async (text: string) => {
    try {
      const newComment = {
        comment: text,
        commentType: 'generalComment',
      };
      await addCommentToGoal(organisations[0].organisation.globalId, id as string, participantGoal.id, newComment);
      setInvalidateData((prev) => ({ ...prev, 'participant-goals': true }));
      setTriggerRefresh((prev) => prev + 1);
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // We are filtering the comments on the frontend
  // Reason: The comments are returned in an array on the goal, so we just filter that array
  useEffect(() => {
    let comments = participantGoal.comments;
    if (selectedFilters.length > 0) {
      selectedFilters.forEach((filter: SelectedFilter) => {
        console.log('first');
        comments = comments.filter((comment: Comment) => filter.valuesSelected.includes(comment.commentType));
      });
    }
    setFilteredComments(comments);
  }, [selectedFilters, participantGoal.comments]);

  return (
    <Stack width='100%'>
      <GoalCommentStackHeader>
        <Stack flexDirection='row' gap='24px' width='100%' justifyContent='space-between'>
          <Typography variant='h6' fontWeight='600' color='text.primary'>
            Goal History
          </Typography>
        </Stack>
        <Stack flexDirection='row' gap='16px' height='36px' minWidth='fit-content'>
          <Filters>
            {[goalCommentTypeFilter].map((filter: IFilter, index: number) => (
              <FilterWithDropdown
                key={index}
                filter={filter}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
              />
            ))}
            {selectedFilters.length > 0 &&
              <ResetFiltersBox onClick={() => setSelectedFilters([])}>
                <Typography variant='body2' fontWeight='600' color='secondary' >
                  Reset filters
                </Typography>
              </ResetFiltersBox>
            }
          </Filters>
          {/* <SearchInput searchValue={searchText} setSearchValue={setSearchText} placeholderText='Search' height='100%' /> */}
        </Stack>
      </GoalCommentStackHeader>
      <RichTextBox>
        <Stack gap='16px' flexDirection='row' alignItems='center' marginBottom='16px'>
          <UserProfileCell displayOnly userProfile={currentUser as User} nameOnly />
        </Stack>
        <RichTextInput
          characterLimit={500}
          buttonText='Add Comment'
          onSubmit={handleNewComment}
          minHeight='108px'
        />
      </RichTextBox >
      <Stack gap='16px' padding='32px 0'>
        {filteredComments.map((comment: Comment, index: number) => (
          <CommentCard key={index} comment={comment} canDelete />
        ))}
      </Stack>
    </Stack >
  );
};

const GoalCommentStackHeader = styled(Stack)`
  width: 100%;
  flex-direction: row;
`;

const RichTextBox = styled(Box)`
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 16px;
  /* min-height: 190px; */
  height: fit-content;
  width: 100%;
  background: white;
   margin-top: 32px;
`;

const Filters = styled(Stack)`
  width: fit-content;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const ResetFiltersBox = styled(Stack)`
  cursor: pointer;
  padding: 8px;
  border-left: 1px solid ${variables.colors.secondary.outlineBorder};
  min-width: fit-content;
`;


export default GoalComments;