import React from 'react';
import SettingsTabs from 'components/common/SettingsTabs';
import ServiceProviderNoteTypesDataTable from 'components/features/DataTables/ServiceProviderNoteTypesDataTable';
import ServiceProviderPositionsDataTable from 'components/features/DataTables/ServiceProviderPositionsDataTable';

const ServiceProviderSettings = () => {
  return (
    <SettingsTabs
      title='Service Provider Settings'
      tabs={[
        {
          label: 'Positions',
          content: <ServiceProviderPositionsDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Note Types',
          content: <ServiceProviderNoteTypesDataTable />,
          requiredPermissions: [],
        }
      ]}
    />
  );
};

export default ServiceProviderSettings;