import { Button, Stack, Typography } from '@mui/material';
import React, { FC, createElement } from 'react';
import styled from 'styled-components';
import variables from 'styles/variables';
import { ActionDropdownProps, StyledDropDownButtonProps } from './types';

const ActionsDropdown: FC<ActionDropdownProps> = ({ selectedRowIds, dataTableName, actions }) => {
  return (
    <>
      {actions.map((action, index) => (
        <Stack key={index} flexDirection='row' alignItems='center'>
          <StyledDropDownButton
            disabled={action.isDisabled}
            hasBorder={action.borderTop}
            onClick={() => action.function({ selectedRowIds, dataTableName })}
            startIcon={action.icon && (
              createElement(action.icon, {
                sx: { color: action?.isDisabled ? '#1F29374D' : variables.colors.darkNeutral.lighter }
              })
            )}
          >
            <Typography variant='body2'>
              {action.label}
            </Typography>
          </StyledDropDownButton>
          {action?.info && (
            <Stack marginRight='16px'>
              {action.info}
            </Stack>
          )}
        </Stack>
      ))}
    </>
  );
};

const StyledDropDownButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => prop !== 'hasBorder',
}) <StyledDropDownButtonProps>`
  border-radius: 0;
  color: black;
  text-transform: unset;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  min-width: max-content;
  width: 100%;
  gap: 8px;
  border-top: ${({ hasBorder }) => hasBorder && '1px solid #0000001F'};
  padding: ${({ hasBorder }) => hasBorder ? '16px 16px 8px 16px' : '8px 16px'};
`;

export default ActionsDropdown;