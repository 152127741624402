import { GridRowId } from "@mui/x-data-grid";
import axiosInstance from "api/config";

// Get a list of progress notes for a participant
export const listParticipantProgressNotes = async (organisationId: string, participantId: string, page: number, pageSize: number) => {
  try {
    if (!organisationId || !participantId) throw new Error('Organisation ID and Participant Id are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/progressnotes`, {
      params: {
        page,
        pageSize
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an error listing progress notes for the participant', error);
    throw error;
  }
};

export const getParticipantProgressNoteById = async (organisationId: string, participantId: string, progressNoteId: string) => {
  try {
    if (!organisationId || !participantId || !progressNoteId) throw new Error('Organisation ID, Participant ID, and Progress Note ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/progressnotes/${progressNoteId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the progress note', error);
    throw error;
  }
};

export const updateParticipantProgressNoteById = async (organisationId: string, participantId: string, progressNoteId: string, data: any) => {
  try {
    if (!organisationId || !participantId || !progressNoteId || !data) throw new Error('Organisation ID, Participant ID, Data, and Progress Note ID are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/participants/${participantId}/progressnotes/${progressNoteId}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the progress note', error);
    throw error;
  }
};

// Create a dummy progress note - for testing purposes
export const createDummyProgressNote = async (organisationId: string, participantId: string, data: FormData) => {
  try {
    if (!organisationId || !participantId) throw new Error('Organisation ID and Participant ID are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/progressnotes`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('There was an error creating the dummy progress note', error);
    throw error;
  }
};

export const bulkLinkProgressNotesToGoalID = async (organisationId: string, participantId: string, participantGoalId: string, idsToAttach: GridRowId[]) => {
  try {
    if (!organisationId || !participantId || !participantGoalId || !idsToAttach)
      throw new Error('Organisation ID, Participant ID, Data, and Progress Note ID are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/participants/${participantId}/progressnotes/bulk-link/${participantGoalId}`,
      idsToAttach,
    );
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the progress note', error);
    throw error;
  }
};