import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { ScoreProps, TScores } from './types';

const scores: TScores = {
  1: {
    fontColor: '#B71C1C',
    color: '#FECDD2',
  },
  2: {
    fontColor: '#E64A19',
    color: '#FFE0B2',
  },
  3: {
    fontColor: '#FFF176',
    color: '#AE6B0B',
  },
  4: {
    fontColor: '#01579B',
    color: '#B3E5FC',
  },
  5: {
    fontColor: '#1B5E20',
    color: '#C8E6C9',
  },
};

const getScoreKey = (score: number): keyof TScores => {
  if (score >= 1 && score < 2) return 1;
  if (score >= 2 && score < 3) return 2;
  if (score >= 3 && score < 4) return 3;
  if (score >= 4 && score < 5) return 4;
  if (score >= 5) return 5;
  return 1; // Default case, should not occur due to validation
};

const Score: FC<ScoreProps> = ({ score }) => {
  if (!score || score < 1 || score > 5) {
    return (
      <StyledBox>
        <Typography variant='body2' fontWeight='700'>
          N/A
        </Typography>
      </StyledBox >
    );
  }
  const scoreKey = getScoreKey(score);

  return (
    <StyledBox sx={{ backgroundColor: scores[scoreKey].color }}>
      <Typography variant='body2' sx={{ color: scores[scoreKey].fontColor }} fontWeight='700' fontSize='12px'>
        {score.toFixed(2)}
      </Typography>
    </StyledBox >
  );
};

const StyledBox = styled(Box)`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 100%;
`;

export default Score;