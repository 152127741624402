import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Columns } from './Controls/Columns';
import { PaginationModel } from 'components/common/DataTable/types';
import { ParticipantNotificationsData } from 'utils/helpers/getDBData';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';

const ParticipantNotificationsDataTable: FC = () => {
  const [searchText, setSearchText] = useState('');
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);

  // TODO: Replace dummyProductAndServices with getNotificationTemplates when API is ready
  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['participant-notification-settings', paginationModel],
    queryFn: () => ParticipantNotificationsData,
    placeholderData: keepPreviousData,
    // enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.participantNotifications,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <DataTable
        data={{ items: data || [], totalCount: 1, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
        columns={Columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        title='Participant Notification Settings'
        dataTableName='participant-notification-settings'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={null}
      />
    </Stack>
  );
};

export default ParticipantNotificationsDataTable;