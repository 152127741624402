import React from 'react';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import variables from 'styles/variables';
import EditIcon from './EditIcon';
import EditStatusDropdown from './EditStatusDropdown';

export const Columns: GridColDef[] = [
  {
    field: 'edit',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <EditIcon serviceProviderPosition={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Position',
    flex: 1.5,
    renderCell: (params) => (
      <Typography variant='subtitle2'>
        {params.row.name}
      </Typography>
    ),
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 3,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {params.row.description}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <EditStatusDropdown serviceProviderPosition={params.row} />
    ),
  },
];