import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { closeModalAtom } from 'atoms/modalAtom';
import { Controller, useForm } from 'react-hook-form';
import TextInput from 'components/common/InputFields/TextInput';
import variables from 'styles/variables';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { LeaveType, leaveTypesColorList } from 'types/dbSchema/availabilitySettings';
import ColorSelectInput from 'components/common/InputFields/ColorSelectInput';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import { createLeaveType, updateLeaveType } from 'api/organisations/settings/availability-settings/leave-types';

const LeaveTypeForm = ({ type, leaveType, filterOut }: { type: 'create' | 'edit'; leaveType: LeaveType, filterOut: string[] }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: leaveType || {
      isActive: true
    }
  });

  // Function to create a new Leave Type
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleCreate = async (data: LeaveType) => {
    try {
      await createLeaveType(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'leave-type': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to update a Leave Type.
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleEdit = async (data: LeaveType) => {
    try {
      await updateLeaveType(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'leave-type': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to handle form submission
  const handleSubmitForm = async (data: LeaveType) => {
    if (type === 'create') {
      await handleCreate(data);
    } else {
      await handleEdit(data);
    }
  };

  return (
    <form style={{ width: '500px' }} onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack height='fit-content' padding='32px' gap='24px'>
        <Typography variant='h6' fontWeight='600'>
          {type === 'create' ? 'Add Leave Type' : 'Edit Leave Type'}
        </Typography>
        <Controller
          name="name"
          control={control}
          rules={{
            required: 'Leave Type is required',
            maxLength: { value: 40, message: 'Leave Type should not exceed 40 characters' },
            pattern: {
              value: /^[a-zA-Z0-9!@#$%^&*()_+-=[\]{};':"|,.<>/? ]*$/,
              message: 'Leave Type must be alphanumeric'
            },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='leaveType'
              label='Name'
              placeholder='Enter Leave Type'
              error={errors.name ? true : false}
              errorText={errors?.name?.message}
              isMandatory
            />
          }
        />
        <Controller
          name="color"
          control={control}
          rules={{ required: 'color is required' }}
          render={({ field }) => (
            <ColorSelectInput
              {...field}
              id="color"
              label="Color"
              error={errors.color ? true : false}
              errorText={errors.color?.message}
              placeholder="Select"
              filterOut={filterOut}
              colorList={leaveTypesColorList}
              isMandatory
            />
          )}
        />
        <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
          <Typography variant='subtitle2' fontWeight='500'>
            Status
          </Typography>
          <Controller
            name="isActive"
            control={control}
            rules={{
              validate: value => value !== undefined && value !== null || 'Status is required'
            }}
            render={({ field }) =>
              <IsActiveDropdown
                isActive={(field.value === undefined || field.value === null) ? true : field.value}
                setIsActive={field.onChange}
              />
            }
          />
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        <Button variant='outlined' color='primary' onClick={closeModal}>
          Cancel
        </Button>
        <Button type='submit' variant='contained' color='primary' disabled={!isDirty}>
          {type === 'create' ? 'Create' : 'Update'}
        </Button>
      </Stack>
    </form>
  );
};

export default LeaveTypeForm;