import React, { useCallback, useState } from 'react';
import { Box, Button } from '@mui/material';
import styled from 'styled-components';
import DataTable from 'components/common/DataTable';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { listPayPeriods } from 'api/organisations/settings/service-team/pay-periods';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';
import { Columns } from './Columns';
import PayPeriodForm from '../../Forms/PayPeriodForm';

const PayPeriodsDataTable = () => {
  const [, setModalState] = useAtom(modalStateAtom);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  useInvalidateData('pay-periods');

  const { isError, data, isPending, isFetching, isLoading } = useQuery({
    queryKey: ['pay-periods', paginationModel],
    queryFn: () => listPayPeriods(
      organisations[0].organisation.globalId,
      paginationModel.page + 1,
      paginationModel.pageSize
    ),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.payPeriods,
  });

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: PayPeriodForm,
      props: {
        dataTableName: 'pay-periods',
        type: 'create',
      }
    }));
  }, [setModalState]);

  const isAddButtonDisabled = !data || data.totalCount === undefined || data.totalCount > 0;

  return (
    <StyledBox>
      <SearchBox>
        <Button variant='contained' sx={{ padding: '8px 16px' }} onClick={handleModalOpen} disabled={isAddButtonDisabled}>
          <AddOutlinedIcon sx={{ marginRight: '8px' }} />
          Add
        </Button>
      </SearchBox>
      <Box height='calc(100vh - 295px)'>
        <DataTable
          data={data as Data}
          columns={Columns}
          checkboxSelection={false}
          dataTableName='pay-periods'
          isPending={isPending || isFetching || isLoading}
          isError={isError}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          title='Pay Periods'
          paginationMode='server'
          onNoData={handleModalOpen}
        />
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: white;
`;

const SearchBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
`;

export default PayPeriodsDataTable;