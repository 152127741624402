import React from "react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AvailabilityTemplate } from "types/dbSchema/availabilitySettings";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

// Renders Edit Icon for Grid column definitions - opens add/edit form in modal
const EditIcon = ({ availabilityTemplate }: { availabilityTemplate: AvailabilityTemplate }) => {
  const navigate = useNavigate();

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(`/settings/availability-settings/edit/${availabilityTemplate.id}`)}
    >
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default EditIcon;