import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { activateOrganisation } from 'api';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import useUserOrganisations from 'hooks/useUserOrganisations';

const ReturnPage = () => {
  const [organisations, , fetchOrganisations] = useUserOrganisations();
  const [urlRedirect, setUrlRedirect] = useState<string | null>(null);
  const { accounts } = useMsal();
  // Because the useEffect runs whenever the organisations atom changes, we need to prevent multiple calls to the activate function
  const [isActivating, setIsActivating] = useState<boolean>(false);

  useEffect(() => {
    // If the user is logged in, has an active org, redirect them to the home page
    if (accounts.length > 0 && organisations.length > 0 && !organisations[0].organisation.isPending) setUrlRedirect('/');

    const getOrganisations = async () => {
      // If the user is authenticated/logged in && has no organisations, retrieve the user's organisations
      if (accounts.length > 0 && organisations.length === 0) {
        try {
          // Get the user's organisations
          await fetchOrganisations();
          // If the user has an organisation that is active (not pending), redirect them to the home page
          if (organisations.length > 0 && !organisations[0]?.organisation.isPending) {
            setUrlRedirect('/');
          }
        } catch (error) {
          console.error('There was an error retrieving the user\'s organisations:', error);
          setUrlRedirect(null);
        }
      }
    };

    getOrganisations();

    const activate = async () => {
      try {
        setIsActivating(true); // Set activating state to prevent multiple calls
        const response = await activateOrganisation(organisations[0].organisation.globalId);
        if (response.success) {
          // Refetch the organisations after activation
          // The protected route does not grab the user's organisations again after activation (if organisations are already retrieved), so we need to update the atom here
          await fetchOrganisations();
          setUrlRedirect('/');
        } else {
          setUrlRedirect('/organisation-registration');
        }
      } catch (error) {
        console.error('There was an error activating the organisation', error);
      } finally {
        setIsActivating(false);
      }
    };

    // If the user has an organisation that is pending, activate it
    if (organisations && organisations.length > 0 && organisations[0]?.organisation.isPending && !isActivating) activate();
  }, [accounts, organisations]);

  // Redirect the user to the appropriate page
  if (urlRedirect && location.pathname !== urlRedirect) {
    return (
      <AuthenticatedTemplate>
        <Navigate to={urlRedirect} />
      </AuthenticatedTemplate>
    );
  }

  // Redirect to the login page if the user is not authenticated
  // Otherwise we display loading element while we wait for processing
  return (
    <>
      <AuthenticatedTemplate>
        <LinearProgress />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to="/login" />;
      </UnauthenticatedTemplate>
    </>

  );
};

export default ReturnPage;