import { GridRowId } from '@mui/x-data-grid';
import { FC } from 'react';

export type Pronouns = 'HeHim' | 'SheHer' | 'TheyThem' | 'Other';

export const pronounOptions: { value: Pronouns; label: string }[] = [
  { value: 'HeHim', label: 'He/Him' },
  { value: 'SheHer', label: 'She/Her' },
  { value: 'TheyThem', label: 'They/Them' },
  { value: 'Other', label: 'Other' },
];

export type AboriginalOrTorresStraitIslander = 'Aboriginal' | 'TorresStraitIslander' | 'Both' | 'Neither' | 'PreferNotToSay';

export const aboriginalOrTorresStraitIslanderOptions: { value: AboriginalOrTorresStraitIslander; label: string }[] = [
  { value: 'Aboriginal', label: 'Aboriginal' },
  { value: 'TorresStraitIslander', label: 'Torres Strait Islander' },
  { value: 'Both', label: 'Both' },
  { value: 'Neither', label: 'Neither' },
  { value: 'PreferNotToSay', label: 'Prefer not to say' },
];

export interface RenderWithRouterOptions {
  route?: string;
  history?: string[];
}

export interface PaginationBase<T> {
  items: T[];
  totalCount: number;
  pageNumber: number;
  pageSize: number;
}

export interface IPages {
  to: string;
  // eslint-disable-next-line
  pageComponent: FC<any> | undefined;
  // eslint-disable-next-line
  props?: { [key: string]: any };
  requiredPermissions: string[];
}

export interface ActionsProps {
  selectedRowIds: GridRowId[];
  dataTableName: string;
}

export interface Address {
  id?: string;
  streetAddress: string;
  suburb: string;
  state: string;
  postCode: string;
  country: string;
  latitude?: number;
  longitude?: number;
  isPrimary: boolean;
}

// This is used for local storage while we handle the file upload on the client side
export interface Attachment {
  file: File;
  state: 'uploading' | 'complete' | 'error' | 'submitted';
  message: string;
  fileSize: number;
  fileName: string;
  fileType: string;
}

// This is used for attachments coming from the database
export interface DBAttachment {
  id: string;
  fileName: string;
  blobUrl: string;
  uploadDate: string;  // "2024-07-02T02:52:51.560Z" This format
  contentType: string;
  fileSize: number;
  fileType: string;
}

export type TUnitOfMeasure = 'Annual' | 'Daily' | 'Each' | 'Hourly' | 'Monthly' | 'Per Item' | 'Weekly' | 'Yearly';
export const unitOfMeasureOptions = ['Annual', 'Daily', 'Each', 'Hourly', 'Monthly', 'Per Item', 'Weekly', 'Yearly'];

export type TPriceGuideRegion = 'QLDNSWVIC' | 'NTSAWATAS' | 'Remote' | 'VeryRemote';
export const priceGuideRegionOptions: { value: TPriceGuideRegion; label: string }[] = [
  { value: 'QLDNSWVIC', label: 'QLD/NSW/VIC' },
  { value: 'NTSAWATAS', label: 'NT/SA/WA/TAS' },
  { value: 'Remote', label: 'Remote' },
  { value: 'VeryRemote', label: 'Very Remote' },
];


export type TTimezone = 'UTC+10 Brisbane' | 'UTC+11 Sydney' | 'UTC+9:30 Adelaide' | 'UTC+9:30 Darwin' | 'UTC+8 Perth';
export type TTimezoneValue = '+10' | '+11' | '+9:30' | '+9:30' | '+8';

export const timezoneOptions: { value: TTimezoneValue; label: TTimezone }[] = [
  { value: '+10', label: 'UTC+10 Brisbane' },
  { value: '+11', label: 'UTC+11 Sydney' },
  { value: '+9:30', label: 'UTC+9:30 Adelaide' },
  { value: '+9:30', label: 'UTC+9:30 Darwin' },
  { value: '+8', label: 'UTC+8 Perth' },
];

export const languages = [
  { value: 'english', label: 'English' },
  { value: 'arabic', label: 'Arabic' },
  { value: 'chinese', label: 'Chinese' },
  { value: 'french', label: 'French' },
  { value: 'german', label: 'German' },
  { value: 'greek', label: 'Greek' },
  { value: 'hindi', label: 'Hindi' },
  { value: 'italian', label: 'Italian' },
  { value: 'japanese', label: 'Japanese' },
  { value: 'korean', label: 'Korean' },
  { value: 'russian', label: 'Russian' },
  { value: 'spanish', label: 'Spanish' },
  { value: 'vietnamese', label: 'Vietnamese' },
  { value: 'other', label: 'Other' },
];