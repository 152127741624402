import React, { FC, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import ScreenWithImage from 'components/common/ScreenWithImage';
import LoginModal from 'components/features/LoginModal';
import { LinearProgress } from '@mui/material';
import useUserOrganisations from 'hooks/useUserOrganisations';

const LoginPage: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [urlRedirect, setUrlRedirect] = useState<string | null>(null);
  const { accounts } = useMsal();
  const location = useLocation();
  const [organisations, , , hasBeenFetched] = useUserOrganisations();

  useEffect(() => {
    const handleAccess = async () => {
      if (accounts.length > 0 && hasBeenFetched) {
        try {
          // TODO: When we move to a multi-organisation setup, we will need to update this logic
          if (organisations.length === 0 || organisations[0]?.organisation.isPending) {
            setUrlRedirect('/organisation-registration');
          } else {
            setUrlRedirect('/');
          }
          setIsLoading(false);
        } catch (error) {
          console.error('There was an error checking if the user is associated with an organisation', error);
          setUrlRedirect(null);
        }
      } else {
        setIsLoading(false);
      }
    };

    handleAccess();
  }, [accounts, hasBeenFetched]);

  // Loading element to prevent the app from rendering before authentication is complete
  if (isLoading) {
    return <LinearProgress />;
  }

  // Redirect the user to the appropriate page if they are not associated with an organisation
  // No redirect if they are already on the correct page
  if (urlRedirect && location.pathname !== urlRedirect) {
    return (
      <AuthenticatedTemplate>
        <Navigate to={urlRedirect} />
      </AuthenticatedTemplate>
    );
  }

  // Render the login modal if the user is not authenticated
  return (
    <UnauthenticatedTemplate>
      <ScreenWithImage component={LoginModal} imageSrc="/assets/images/AdobeStock_506486029-1024x683.jpeg" />
    </UnauthenticatedTemplate>
  );
};

export default LoginPage;