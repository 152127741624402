import React, { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { goalTypes, ParticipantGoal, statusArray } from 'types/dbSchema/participantGoals';
import variables from 'styles/variables';
import TextareaInput from 'components/common/InputFields/TextareaInput';
import DatePickerInput from 'components/common/InputFields/DatePickerInput';
import RadioInput from 'components/common/InputFields/RadioInput';
import StatusDropdown from 'components/common/StatusDropdown';

export interface GoalDetailsProps {
  control: Control<ParticipantGoal>;
  errors: FieldErrors<ParticipantGoal>;
  formData: ParticipantGoal
}

const GoalDetails: FC<GoalDetailsProps> = ({
  control,
  errors,
  formData
}) => {

  return (
    <DetailsStack>
      <Stack flexDirection='row' gap='24px' width='100%' justifyContent='space-between'>
        <Typography variant='h6' fontWeight='600' color='text.primary'>
          Goal Details
        </Typography>
        <Stack flexDirection='row' gap='8px' justifyContent='center' alignItems='center'>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            Status
          </Typography>
          <Controller
            name="status"
            control={control}
            render={({ field }) =>
              <StatusDropdown
                statuses={statusArray}
                currentStatus={formData.status || 'InProgress'}
                setStatus={field.onChange}
              />}
          />
        </Stack>
      </Stack>
      <Controller
        name="goalType"
        control={control}
        rules={{
          required: 'Goal Type is required',
        }}
        render={({ field }) =>
          <RadioInput
            {...field}
            id='goalType'
            label='Goal Type'
            error={errors.goalType ? true : false}
            errorText={errors.goalType?.message}
            values={goalTypes.map((type) => ({ label: type.label, value: type.value }))}
            isMandatory
          />}
      />
      <Controller
        name="goal"
        control={control}
        rules={{
          required: 'Goal is required',
        }}
        render={({ field }) =>
          <TextareaInput
            {...field}
            id='goal'
            label='Goal'
            error={errors.goal ? true : false}
            errorText={errors.goal?.message}
            isMandatory
          />}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) =>
          <TextareaInput
            {...field}
            id='description'
            label='Description'
            error={errors.description ? true : false}
            errorText={errors.description?.message}
          />}
      />
      <Box width='232px'>
        <Controller
          name="reviewDate"
          control={control}
          rules={{
            required: 'Review Date is required',
          }}
          render={({ field }) =>
            <DatePickerInput
              {...field}
              id='reviewDate'
              label='Review Date'
              error={errors.reviewDate ? true : false}
              errorText={errors.reviewDate?.message}
              isMandatory
            />}
        />
      </Box>
    </DetailsStack>
  );
};

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export interface RowProps {
  numberOfChildren?: number;
}

const Row = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'numberOfChildren'
}) <RowProps>`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  & > * {
    flex: 0 0 calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
    max-width: calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
  }
`;

export default GoalDetails;