import React, { FC } from 'react';
import { Control, Controller, FieldErrors, UseFormResetField, UseFormSetValue } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import TextInput from 'components/common/InputFields/TextInput';
import AddressLookupField from 'components/common/InputFields/AddressLookupField';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import { Address } from 'types';
import { OrganisationDetails } from 'types/dbSchema/organisation';
import { emailValidationRegex, phoneValidationRegex } from 'utils/helpers';

export interface ContactDetailsProps {
  control: Control<OrganisationDetails>;
  errors: FieldErrors<OrganisationDetails>;
  setValue: any;
  differentPostalAddress: boolean;
  setDifferentPostalAddress: React.Dispatch<React.SetStateAction<boolean>>;
  resetField: UseFormResetField<OrganisationDetails>;
}

const ContactDetails: FC<ContactDetailsProps> = ({
  control,
  errors,
  setValue,
  differentPostalAddress,
  setDifferentPostalAddress,
  resetField,
}) => {

  const obj = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setDifferentPostalAddress(e.target.checked);
      if (!e.target.checked) {
        handleSetPostalAddress({
          streetAddress: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
          isPrimary: false,
        });
      }
    }
  };

  const handleSetAddress = (values: Address) => {
    setValue('businessAddress', values, { shouldValidate: true });
  };

  const handleSetPostalAddress = (values: Address) => {
    setValue('postalAddress', values, { shouldValidate: true });
  };

  return (
    <DetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Contact Details
      </Typography>
      <Row>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: 'Phone Number is required',
            pattern: {
              value: phoneValidationRegex,
              message: 'Please enter a valid Australian phone number',
            },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='phone'
              label='Phone Number'
              error={errors.phone ? true : false}
              errorText={errors.phone?.message}
              isMandatory
            />}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            pattern: {
              value: emailValidationRegex,
              message: 'Invalid Email Address',
            },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='email'
              label='Email'
              error={errors.email ? true : false}
              errorText={errors.email?.message}
            />}
        />
      </Row>
      <Controller
        name="businessAddress"
        control={control}
        rules={{ required: 'Business Address is required' }}
        render={({ field }) =>
          <AddressLookupField
            {...field}
            id='businessAddress'
            setValue={handleSetAddress}
            label='Business Address'
            isMandatory
            error={errors.businessAddress ? true : false}
            errorText='Business Address is required'
            resetField={resetField}
          />
        }
      />
      <CheckboxInput
        error={false}
        checked={differentPostalAddress}
        id='sameAsPostalAddress'
        label='Different Postal Address'
        {...obj}
      />
      {differentPostalAddress && (
        <Controller
          name="postalAddress"
          control={control}
          render={({ field }) =>
            <AddressLookupField
              {...field}
              id='postalAddress'
              setValue={handleSetPostalAddress}
              label='Postal Address'
              error={false}
              resetField={resetField}
            />
          }
        />
      )}
    </DetailsStack>
  );
};

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export interface RowProps {
  numberOfChildren?: number;
}

const Row = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'numberOfChildren'
}) <RowProps>`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start; /* Changed to flex-start to accommodate varying numbers of children */
  align-items: flex-start;

  & > * {
    flex: 0 0 calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
    max-width: calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
  }
`;

export default ContactDetails;