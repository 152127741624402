import React, { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { closeModalAtom } from 'atoms/modalAtom';
import { useAtom } from 'jotai';
import TitleWithClose from 'components/common/TitleWithClose';
import { Controller, useForm } from 'react-hook-form';
import TextareaInput from 'components/common/InputFields/TextareaInput';
import useErrorMessage from 'hooks/useErrorMessage';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useCurrentUser from 'hooks/useCurrentUser';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { displayLocationOptions, frequencyOptions, ParticipantAlert } from 'types/dbSchema/alerts';
import SelectInput from 'components/common/InputFields/SelectInput';
import CheckboxGroupInput from 'components/common/InputFields/CheckboxGroupInput';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { createParticipantAlert, updateAlertById } from 'api/organisations/participants/alerts';

export interface ParticipantAlertFormProps {
  type: 'create' | 'edit';
  participantAlert: ParticipantAlert | null;
  participantId: string;
}

const ParticipantAlertForm: FC<ParticipantAlertFormProps> = ({ type, participantAlert, participantId }) => {
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();
  const [currentUser] = useCurrentUser();
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: participantAlert || {
      isActive: true
    }
  });

  const handleCreate = async (data: any) => {
    if (!participantId) {
      showError('Participant ID is required');
      return;
    }

    try {
      if (!currentUser) return;
      await createParticipantAlert(
        organisations[0].organisation.globalId,
        participantId,
        { ...data, isDismissed: false }
      );
      setInvalidateData((prev) => ({ ...prev, 'participant-alerts': true, 'participant-alerts-not-dismissed': true }));
    } catch (error) {
      console.error('Error creating participant alert:', error);
      showError('Failed to create participant alert');
    }
  };

  const handleEdit = async (data: ParticipantAlert) => {
    if (!participantId) {
      showError('Participant ID is required');
      return;
    }

    try {
      if (!currentUser) return;
      await updateAlertById(
        organisations[0].organisation.globalId,
        participantId,
        data.id,
        { ...data, isDismissed: false, participantId: participantId }
      );
      setInvalidateData((prev) => ({ ...prev, 'participant-alerts': true, 'participant-alerts-not-dismissed': true }));
    } catch (error) {
      console.error('Error creating participant alert:', error);
      showError('Failed to create participant alert');
    }
  };

  const onSubmit = async (data: ParticipantAlert) => {
    try {
      if (type === 'create') {
        await handleCreate(data);
        closeModal();
      } else if (type === 'edit') {
        await handleEdit(data);
        closeModal();
      }
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid='participant-alert-form' style={{ height: '100%', width: '500px' }}>
      <Stack height='100%' width='100%' padding='32px' gap='16px' justifyContent='space-between'>
        <Box>
          <TitleWithClose title={type === 'create' ? 'Add New Alert' : 'Edit Alert'} handleClose={closeModal} />
        </Box>
        <Stack gap='16px' paddingTop='16px'>
          <Controller
            control={control}
            name='content'
            rules={{
              required: 'Alert Content is required',
              maxLength: { value: 100, message: 'Alert Content is too long' }
            }}
            render={({ field }) => (
              <TextareaInput
                {...field}
                id='content'
                label='Alert Content'
                error={errors.content ? true : false}
                errorText={errors.content?.message}
                isMandatory
              />
            )}
          />
          <Controller
            control={control}
            name='frequency'
            rules={{
              required: 'Frequency is required',
            }}
            render={({ field }) => (
              <SelectInput
                {...field}
                id='frequency'
                label='Display Frequency'
                error={errors.frequency ? true : false}
                errorText={errors.frequency?.message}
                isMandatory
                options={frequencyOptions.map((option) => ({ value: option, label: option }))}
              />
            )}
          />
          <Controller
            control={control}
            name='displayLocations'
            rules={{
              required: 'Alert Content is required',
              maxLength: { value: 100, message: 'Alert Content is too long' }
            }}
            render={({ field }) => (
              <CheckboxGroupInput
                {...field}
                value={field.value || []}
                id='displayLocations'
                label='Display Locations'
                error={errors.displayLocations ? true : false}
                errorText={errors.displayLocations?.message}
                isMandatory
                options={displayLocationOptions.map((option) => ({ value: option, label: option }))}
              />
            )}
          />
          <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
            <Typography variant='subtitle2' fontWeight='500'>
              Status
            </Typography>
            <Controller
              name="isActive"
              control={control}
              rules={{
                validate: value => value !== undefined && value !== null || 'Status is required'
              }}
              render={({ field }) =>
                <IsActiveDropdown
                  isActive={(field.value === undefined || field.value === null) ? true : field.value}
                  setIsActive={field.onChange}
                />
              }
            />
          </Stack>
        </Stack>
        <ButtonStack>
          <Button variant='outlined' color='primary' onClick={closeModal}>Cancel</Button>
          {type === 'create' ? (
            <Button variant='contained' color='primary' type='submit' disabled={!isDirty}>
              Create
            </Button>
          ) : (
            <Button variant='contained' color='secondary' type='submit' disabled={!isDirty}>
              Update
            </Button>
          )}
        </ButtonStack>
      </Stack >
    </form>
  );
};

const ButtonStack = styled(Stack)`
  gap: 16px;
  justify-content: flex-end;
  flex-direction: row;
`;

export default ParticipantAlertForm;