import axiosInstance from "api/config";
import { ServiceRegion, ServiceRegionList } from "types/dbSchema/serviceRegions";

// Returns a list of service regions for the given organisation ID
export const listServiceRegions = async (organisationId: string, page?: number, pageSize?: number): Promise<ServiceRegionList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/serviceregions`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing service regions for the organisation', error);
    throw error;
  }
};

// Returns a service region by ID
export const getServiceRegionByID = async (organisationId: string, serviceRegionId: string): Promise<ServiceRegion> => {
  try {
    if (!serviceRegionId || !organisationId) throw new Error('Organisation ID and ServiceRegion ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/serviceregions/${serviceRegionId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the service region', error);
    throw error;
  }
};

// Create a new service region
export const createServiceRegion = async (organisationId: string, data: ServiceRegion) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/serviceregions`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the service region', error);
    throw error;
  }
};

// Update a service region
export const updateServiceRegion = async (organisationId: string, data: ServiceRegion) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/serviceregions/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the service region', error);
    throw error;
  }
};

// Delete a service region
export const deleteServiceRegionById = async (organisationId: string, serviceRegionId: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/serviceregions/${serviceRegionId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the service region', error);
    throw error;
  }
};