import React, { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { employeeTypes, User } from 'types/dbSchema/userProfiles';
import SelectInput from 'components/common/InputFields/SelectInput';
import TextInput from 'components/common/InputFields/TextInput';
import DatePickerInput from 'components/common/InputFields/DatePickerInput';
import { dummyServiceRegions } from 'utils/helpers/getDBData';
import UserPositionLookupField from 'components/common/LookupInputField/UserPositionLookupField';
import UserProfileLookupField from 'components/common/LookupInputField/UserProfileLookupField';
import ServiceRegionLookupField from 'components/common/LookupInputField/ServiceRegionLookupField';

export interface EmploymentDetailsProps {
  control: Control<User>;
  errors: FieldErrors<User>;
}

const EmploymentDetails: FC<EmploymentDetailsProps> = ({ control, errors }) => {
  return (
    <EmploymentDetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Employment Details
      </Typography>
      <Row>
        <Controller
          name="position"
          control={control}
          rules={{ required: 'Position is required' }}
          render={({ field }) =>
            <UserPositionLookupField
              {...field}
              id='position'
              label='Position'
              validationError={errors.position ? true : false}
              errorText={errors.position?.message}
              isMandatory
              placeholder='Select'
            />
          }
        />
        <Controller
          name="employeeType"
          control={control}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='employeeType'
              label="Employee Type"
              error={errors.employeeType ? true : false}
              errorText={errors.employeeType?.message}
              placeholder='Select'
              options={employeeTypes.map((type) => ({ value: type, label: type }))}
            />}
        />
      </Row>
      <Row>
        <Controller
          name="manager"
          control={control}
          render={({ field }) =>
            <UserProfileLookupField
              {...field}
              id='manager'
              label='Manager'
              validationError={errors.manager ? true : false}
              errorText={errors.manager?.message}
              placeholder='Select'
              value={field.value}
            />
          }
        />
        <Controller
          name="serviceRegion"
          control={control}
          rules={{
            required: 'Service Region is required',
          }}
          render={({ field }) =>
            <ServiceRegionLookupField
              {...field}
              id='serviceRegion'
              label='Service Region'
              placeholder='Select'
              validationError={errors.serviceRegion ? true : false}
              errorText={errors.serviceRegion?.message}
              isMandatory
            />
          }
        />
      </Row>
      <Row>
        <Controller
          name="employeeNo"
          control={control}
          render={({ field }) =>
            <TextInput
              {...field}
              id='employeeNo'
              label='Employee Number'
              error={errors.employeeNo ? true : false}
              errorText={errors.employeeNo?.message}
            />}
        />
        <Controller
          name="employmentStartDate"
          control={control}
          render={({ field }) =>
            <DatePickerInput
              {...field}
              id='employmentStartDate'
              label='Employment Start Date'
              error={errors.employmentStartDate ? true : false}
              errorText={errors.employmentStartDate?.message}
            />}
        />
      </Row>
    </EmploymentDetailsStack>
  );
};

const EmploymentDetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

const Row = styled(Stack)`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default EmploymentDetails;