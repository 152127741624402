import { DBAttachment } from "types";
import { ServiceTeamNoteType, Tag, TagCategory } from "./serviceTeamSettings";
import { UserAndDate } from "./userProfiles";
import { LeaveType } from "./availabilitySettings";

export interface SkillQualification {
  id: string;
  tagCategory: TagCategory;
  tagName: Tag | string; // Lookup field
  expiryDate: string | null; //  "2024-07-02T02:52:51.560Z" This format
  neverExpires: boolean;
  supportingDocuments: DBAttachment[];
  createdBy: UserAndDate | string; // Lookup field
  createdDate: string; // "2024-07-02T02:52:51.560Z" This format
}

export interface EmployeeNote {
  id: string;
  noteType: ServiceTeamNoteType | string; // Lookup field
  subject: string;
  note: string;
  attachments: DBAttachment[];
  createdBy: UserAndDate | string; // Lookup field
  createdDate: string; // "2024-07-02T02:52:51.560Z" This format
}

export interface ServiceTeamLeave {
  id: string;
  leaveType: LeaveType | string; // Lookup field
  startTime: string; // "2024-07-02T02:52:51.560Z" This format
  endTime: string; // "2024-07-02T02:52:51.560Z" This format
  isAllDay: boolean;
  notes: string;
  declinedReason?: string;
  attachments: DBAttachment[];
  reviewedBy: UserAndDate | string; // Lookup field
  status: string;
}

export const SkillQualificationStatuses = [
  {
    label: 'Current',
    value: 'Current',
    fontColor: '#356328',
    backgroundColor: '#69C64F4D',
  },
  {
    label: 'Expired',
    value: 'Expired',
    fontColor: '#C62828',
    backgroundColor: '#E5393533',
  },
];

export const ServiceTeamLeaveStatuses = [
  {
    label: 'Approved',
    value: 'Approved',
    fontColor: '#356328',
    backgroundColor: '#69C64F4D',
  },
  {
    label: 'Declined',
    value: 'Declined',
    fontColor: '#C62828',
    backgroundColor: '#E5393533',
  },
];