import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import EditIcon from "./EditIcon";
import { Typography } from "@mui/material";
import variables from "styles/variables";
import EditStatusDropdown from "./EditStatusDropdown";

export const Columns: GridColDef[] = [
  {
    field: 'edit',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <EditIcon availabilityTemplate={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Leave Type',
    width: 300,
    renderCell: (params) => (
      <Typography variant='subtitle2'>
        {params.row.name}
      </Typography>
    ),
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => (
      <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='500'>
        {params.row.description}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <EditStatusDropdown availabilityTemplate={params.row} />
    ),
  },
];