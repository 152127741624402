import React from 'react';
import { useAtom } from 'jotai';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { ServiceProviderNoteType } from 'types/dbSchema/serviceProviderSettings';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { updateServiceProviderNoteType } from 'api/organisations/settings/service-providers/note-types';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';

const EditStatusDropdown = ({ serviceProviderNoteType }: { serviceProviderNoteType: ServiceProviderNoteType }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('service-provider-note-type');
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateServiceProviderNoteType(
        organisations[0].organisation.globalId,
        { ...serviceProviderNoteType, isActive: status }
      );
      setInvalidateData((prev) => ({ ...prev, 'service-provider-note-type': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={serviceProviderNoteType.isActive}
      setIsActive={handleEdit}
      disabled={!serviceProviderNoteType.isEditable}
    />
  );
};

export default EditStatusDropdown;