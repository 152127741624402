import React from 'react';
import { updateShiftCancellationReason } from "api/organisations/settings/service-team/shift-cancellation-reasons";
import { invalidateDataAtom } from "atoms/invalidateDataAtom";
import IsActiveDropdown from "components/common/IsActiveDropdown";
import useErrorMessage from "hooks/useErrorMessage";
import useInvalidateData from "hooks/useInvalidateData";
import useUserOrganisations from "hooks/useUserOrganisations";
import { useAtom } from "jotai";
import { ShiftCancellationReason } from "types/dbSchema/serviceTeamSettings";

// Handles the status dropdown in the DataTable
// For MVP 1, we are not implementing the edit functionality, so the dropdown is disabled
// I have created the component for future use - just remove this comment and the remove disabled prop
const EditStatusDropdown = ({ shiftCancellationReason }: { shiftCancellationReason: ShiftCancellationReason }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('shift-cancellation-reason');
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateShiftCancellationReason(organisations[0].organisation.globalId, { ...shiftCancellationReason, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'shift-cancellation-reason': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={shiftCancellationReason.isActive}
      setIsActive={handleEdit}
      // disabled={!shiftCancellationReason.isEditable} // For MVP 1, the dropdown is disabled
      disabled
    />
  );
};

export default EditStatusDropdown;