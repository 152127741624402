import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import RichTextInput from 'components/common/InputFields/RichTextInput';
import { ViewParticipantsGoalProps } from 'components/features/Views/ViewParticipantGoal';
import { useNavigate, useParams } from 'react-router-dom';
import variables from 'styles/variables';
import CommentCard from '../CommentCard';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import { Comment, GoalStatus, ParticipantGoal, statusArray } from 'types/dbSchema/participantGoals';
import DatePickerInput from 'components/common/InputFields/DatePickerInput';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useAtom } from 'jotai';
import { addCommentToGoal, updateParticipantGoalById } from 'api/organisations/participants/goals';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import StatusDropdown from 'components/common/StatusDropdown';
import useCurrentUser from 'hooks/useCurrentUser';
import { NameAndID } from 'types/dbSchema/userProfiles';
import { camelCaseToSeparatedWords } from 'utils/helpers';

const ReviewGoal: FC<ViewParticipantsGoalProps> = ({ participantGoal }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentText, setCurrentText] = useState('');
  const [newReviewDate, setNewReviewDate] = useState<string | null>(participantGoal.reviewDate);
  const [currentStatus, setCurrentStatus] = useState(participantGoal.status);
  const [currentUser] = useCurrentUser();
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const [goalReviewPreview, setGoalReviewPreview] = useState<Comment>({
    id: undefined,
    comment: '',
    createdBy: currentUser?.id as string,
    createdAt: new Date().toISOString(),
    commentType: 'goalReview',
    participantGoalSnapshot: {
      ...participantGoal,
    }
  });

  useEffect(() => {
    setGoalReviewPreview({
      ...goalReviewPreview,
      comment: currentText,
    });
  }, [currentText]);

  const handleReviewSubmit = async (text: string) => {
    try {
      const newComment = {
        comment: text,
        commentType: 'goalReview',
        participantGoalSnapshot: {
          description: participantGoal.description,
          goal: participantGoal.goal,
          goalType: camelCaseToSeparatedWords(participantGoal.goalType),
          reviewDate: participantGoal.reviewDate,
          score: participantGoal.score,
        }
      } as Comment;

      const formData = {
        ...participantGoal,
        status: currentStatus,
        reviewDate: newReviewDate,
        createdBy: (participantGoal.createdBy as NameAndID).id,
        lastModifiedBy: currentUser?.id as string,
      } as ParticipantGoal;

      await addCommentToGoal(organisations[0].organisation.globalId, id as string, participantGoal.id, newComment);
      await updateParticipantGoalById(organisations[0].organisation.globalId, id as string, participantGoal.id, formData);
      setInvalidateData((prev) => ({ ...prev, 'participant-goals': true }));
      navigate(-1);
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleReviewCancel = () => navigate(-1);

  return (
    <StyledForm>
      <Banner>
        <MaxWidthContainer>
          <Stack flexDirection='row' gap={2} width='100%' justifyContent='space-between' maxWidth='960px'>
            <Stack flexDirection='row' gap={2} alignItems='center'>
              <GradingOutlinedIcon sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
              <Typography variant='h5'>
                Review Participant Goal
              </Typography>
            </Stack>
            <Button variant='outlined' sx={{ color: 'white', border: '1px solid white' }} onClick={handleReviewCancel}>Cancel</Button>
          </Stack>
        </MaxWidthContainer>
      </Banner>
      <Stack padding='32px' width='100%' height='fit-content' justifyContent='center' alignItems='center' boxSizing='border-box' gap='32px' overflow='auto'>
        <DetailsStack>
          <Stack flexDirection='row' gap='24px' width='100%' justifyContent='space-between'>
            <Typography variant='h6' fontWeight='600' color='text.primary'>
              Review Goal
            </Typography>
          </Stack>
          <Stack gap='8px'>
            <Typography variant='subtitle2' fontWeight='600' color='text.primary'>
              Status
            </Typography>
            <StatusDropdown
              statuses={statusArray}
              currentStatus={currentStatus}
              setStatus={(status: GoalStatus) => setCurrentStatus(status)}
            />
          </Stack>
          {currentStatus !== 'Completed' && (
            <Box width='300px'>
              <DatePickerInput
                error={false}
                errorText=''
                id='reviewDate'
                label='Next Review Date'
                onChange={(date) => setNewReviewDate(date)}
                value={newReviewDate}
                isMandatory
              />
            </Box>
          )}
          <Stack gap='8px'>
            <Typography variant='subtitle2' fontWeight='600' color='text.primary'>
              Review Comment
            </Typography>
            <RichTextBox>
              <RichTextInput
                isSubmitDisabled={!newReviewDate}
                buttonText='Add Review and Update'
                characterLimit={500}
                onSubmit={handleReviewSubmit}
                minHeight='150px'
                exposeInput={(text: string) => setCurrentText(text)}
              />
            </RichTextBox>
          </Stack>
          <Stack gap='8px'>
            <Typography variant='subtitle2' fontWeight='600' color='text.primary'>
              Preview
            </Typography>
            <CommentCard comment={goalReviewPreview} />
          </Stack>
        </DetailsStack>
      </Stack>
    </StyledForm>
  );
};

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
  height: fit-content;
`;

const RichTextBox = styled(Box)`
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 16px;
  height: fit-content;
  width: 100%;
  background: white;
`;

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  background-color: ${variables.colors.primary.darker};
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  color: white;
  position: fixed;
  padding: 16px 32px;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 70px;
`;

const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  max-width: 960px;
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  background-color: #F3F4F6;
  margin-top: 70px;
  min-height: calc(100vh - 70px);
`;
export default ReviewGoal;