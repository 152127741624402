import React from 'react';
import { useParams } from 'react-router-dom';
import { dummyProductAndServices } from 'utils/helpers/getDBData';
import { LinearProgress } from '@mui/material';
import ViewProductAndService from 'components/features/Views/ViewProductAndService';
import { ProductAndService } from 'types/dbSchema/productAndServices';

const ViewProductAndServicePage = () => {
  // const [organisations] = useUserOrganisations();
  const { id } = useParams();

  // TODO: Replace this with the actual endpoint to fetch product and service data
  // const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({
  //   getByIDFunc: () => getParticipantGoalById(organisations[0].organisation.globalId, id as string, goalId as string),
  // });

  const hasError = false;
  const isLoading = false;
  const currentDataPoint = dummyProductAndServices.find((product: ProductAndService) => product.id === id) || null;

  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ViewProductAndService productAndService={currentDataPoint} />;

};

export default ViewProductAndServicePage;