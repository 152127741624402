import React from 'react';
import SettingsTabs from 'components/common/SettingsTabs';
import LeaveTypesDataTable from 'components/features/DataTables/LeaveTypesDataTable';
import AvailabilityTemplatesDataTable from 'components/features/DataTables/AvailabilityTemplatesDataTable';

const AvailabilitySettings = () => {
  return (
    <SettingsTabs
      title='Availability Settings'
      tabs={[
        {
          label: 'Availability Templates',
          content: <AvailabilityTemplatesDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Leave Types',
          content: <LeaveTypesDataTable />,
          requiredPermissions: [],
        }
      ]}
    />
  );
};

export default AvailabilitySettings;