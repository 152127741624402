import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Tabs from 'components/common/Tabs';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { useNavigate } from 'react-router-dom';
import variables from 'styles/variables';
import { ProductAndService } from 'types/dbSchema/productAndServices';
import Label from 'components/common/Label';
import Tooltip from 'components/common/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TPriceGuideRegion } from 'types';

export interface ViewProductAndServiceProps {
  productAndService: ProductAndService;
}

const ViewProductAndService: FC<ViewProductAndServiceProps> = ({ productAndService }) => {
  const navigate = useNavigate();

  const handleStatusChange = (status: boolean) => {
    // TODO: Add logic to update the status of the product and service
    console.log(status);
  };

  const handleBack = () => {
    navigate('/resources/products-services');
  };

  return (
    <Stack sx={{ backgroundColor: 'white', height: '100%' }}>
      <Stack padding='24px 24px 16px 24px' gap='8px'>
        <Stack flexDirection='row' gap='4px' alignItems='center' padding='8px 0' onClick={handleBack} sx={{ cursor: 'pointer' }}>
          <ArrowBackOutlined sx={{ height: '20px', width: '20px', color: 'rgba(0,0,0,0.6)' }} />
          <Typography variant='body2' color="text.primary" sx={{ color: 'rgba(0,0,0,0.6)' }}>
            Products and Services
          </Typography>
        </Stack>
        <Stack flexDirection='row' gap='16px' alignItems='center'>
          <Typography variant='h6' minWidth='fit-content'>
            {productAndService.productCode}
          </Typography>
          <Typography variant='body1' minWidth='fit-content' color={variables.colors.text.secondary}>
            {productAndService.name}
          </Typography>
          <IsActiveDropdown isActive={true} setIsActive={() => handleStatusChange(true)} />
        </Stack>
        <Stack gap='16px' flexDirection='row' alignItems='center'>
          <Label text={
            <Stack gap='4px' flexDirection='row' alignItems='center'>
              <Typography variant='body2' color={variables.colors.text.secondary} fontSize='12px'>
                PACE:
              </Typography>
              <Typography variant='body1' fontSize='12px' fontWeight='600'>
                {productAndService.supportCategoryNamePACE}
              </Typography>
            </Stack>
          }
          />
          <Label text={
            <Stack gap='4px' flexDirection='row' alignItems='center'>
              <Typography variant='body2' color={variables.colors.text.secondary} fontSize='12px'>
                PRODA:
              </Typography>
              <Typography variant='body1' fontSize='12px' fontWeight='600'>
                {productAndService.supportCategoryNamePRODA}
              </Typography>
            </Stack>
          }
          />
          <StyledTooltip dataPoint={productAndService} />
        </Stack>
      </Stack>
      <Tabs tabs={[
        {
          label: 'QLD/NSW/VIC',
          content: <ProductAndServiceDataTable dataPoint={productAndService} locationFilter='QLDNSWVIC' />,
          requiredPermissions: [],
        },
        {
          label: 'NT/SA/WA/TAS',
          content: <ProductAndServiceDataTable dataPoint={productAndService} locationFilter='NTSAWATAS' />,
          requiredPermissions: [],
        },
        {
          label: 'Remote',
          content: <ProductAndServiceDataTable dataPoint={productAndService} locationFilter='Remote' />,
          requiredPermissions: [],
        },
        {
          label: 'Very Remote',
          content: <ProductAndServiceDataTable dataPoint={productAndService} locationFilter='VeryRemote' />,
          requiredPermissions: [],
        },
      ]}
      />
    </Stack>
  );
};

export interface StyledTooltipProps {
  dataPoint: ProductAndService;
}

const StyledTooltip: FC<StyledTooltipProps> = ({ dataPoint }) => {
  const detailsArray = [
    {
      label: 'Quote',
      value: dataPoint.quote,
    },
    {
      label: 'Non-F2F Support Provision',
      value: dataPoint.nonFaceToFaceSupportProvision,
    },
    {
      label: 'Provider Travel',
      value: dataPoint.providerTravel,
    },
    {
      label: 'NDIA Requested Reports',
      value: dataPoint.ndiaRequestedReports,
    },
    {
      label: 'Irregular SIL Supports',
      value: dataPoint.irregularSILSupports,
    }
  ];

  return (
    <Tooltip
      toolTipText={
        <Stack gap='8px'>
          {detailsArray.map((detail, index) => (
            <Stack gap='8px' flexDirection='row' alignItems='center' key={index}>
              <Typography variant='body2' fontSize='12px' fontWeight='500' color={variables.colors.text.secondary}>
                {detail.label}:
              </Typography>
              <Typography variant='body2' fontSize='12px' fontWeight='600' color={variables.colors.text.primary}>
                {detail.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      }
      toolTipTitle=''
      toolTipTriggerComponent={
        <Box height='16px' width='16px' position='relative' minWidth='16px'>
          <InfoOutlinedIcon sx={{ height: '100%', width: '100%' }} />
        </Box>
      }
    />
  );
};

export interface ProductAndServiceDataTableProps {
  dataPoint: ProductAndService;
  locationFilter: TPriceGuideRegion;
}

const ProductAndServiceDataTable: FC<ProductAndServiceDataTableProps> = ({ dataPoint, locationFilter }) => {
  const Columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'effectiveFrom', headerName: 'Effective From', flex: 1 },
    { field: 'unitOfMeasure', headerName: 'Unit of Measure', flex: 1 },
    { field: 'rate', headerName: 'Rate', flex: 1, renderCell: (params: any) => `$${params.value.toFixed(2)}` },
  ];

  return <></>;
  // return (
  //   <DataTable
  //     data={dataPoint.rates.filter(rate => rate.priceGuideRegion === locationFilter)}
  //     columns={Columns}
  //     isPending={false}
  //     title='Rates'
  //     dataTableName='product-and-service'
  //     checkboxSelection={false}
  //   />
  // );
};

export default ViewProductAndService;