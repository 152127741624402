import React, { FC, useCallback } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import styled from 'styled-components';
import { Box } from '@mui/material';
import ViewProgressNotes from 'components/features/Views/ViewProgressNotes';
import { ProgressNote } from 'types/dbSchema/progressNotes';
import { useParams } from 'react-router-dom';

export interface ViewIconProps {
  progressNote: ProgressNote;
}

const ViewIcon: FC<ViewIconProps> = ({ progressNote }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const { id } = useParams<{ id: string }>();

  const handleImportModalOpen = useCallback(() => {
    if (modalState.status === 'closed') {
      setModalState({
        ...modalState,
        status: 'open',
        position: 'right',
        component: ViewProgressNotes,
        props: {
          progressNote: progressNote,
          participantID: id
        }
      });
    } else {
      closeModal();
    }
  }, [modalState, progressNote, setModalState, closeModal, id]);

  return (
    <StyledBox onClick={handleImportModalOpen}>
      <RemoveRedEyeOutlinedIcon />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;