import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styled from 'styled-components';
import DataTable from 'components/common/DataTable';
import SearchInput from 'components/common/InputFields/SearchInput';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import { Controller, useForm } from 'react-hook-form';
import variables from 'styles/variables';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PaginationModel } from 'components/common/DataTable/types';
import useInvalidateData from 'hooks/useInvalidateData';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import DownloadAttachmentsIcon from 'components/common/DownloadAttachmentsIcon';
import { placeholderSkillsData } from 'utils/helpers/getDBData';
import { formatISODate } from 'utils/helpers';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { SkillQualification, SkillQualificationStatuses } from 'types/dbSchema/serviceTeam';
import SelectInput from 'components/common/InputFields/SelectInput';
import { Tag, tagCategories } from 'types/dbSchema/serviceTeamSettings';
import ServiceTeamTagLookupField from 'components/common/LookupInputField/ServiceTeamTagLookupField';
import DatePickerInput from 'components/common/InputFields/DatePickerInput';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import DragAndDropFileUpload from 'components/common/InputFields/DragAndDropFileUpload';
import { UploadedFile } from 'components/common/InputFields/DragAndDropFileUpload/types';
import Tooltip from 'components/common/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StatusDropdown from 'components/common/StatusDropdown';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';

const ServiceTeamSkillsQualifications = () => {
  const [searchValue, setSearchValue] = useState('');
  const [, setModalState] = useAtom(modalStateAtom);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  useInvalidateData('skills-qualifications');

  const { isError, data, isPending, isFetching, isLoading } = useQuery({
    queryKey: ['skills-qualifications', paginationModel],
    queryFn: () => placeholderSkillsData,
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.skillsQualifications,
  });

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'right',
      component: SkillsQualificationsForm,
      props: {
        dataTableName: 'skills-qualifications',
        type: 'create',
      }
    }));
  }, [setModalState]);


  const columns: GridColDef[] = [
    {
      field: 'edit',
      headerName: '',
      width: 40,
      renderCell: (params) => (
        <Edit skillQualification={params.row} />
      ),
      sortable: false,
    },
    {
      field: 'delete',
      headerName: '',
      width: 40,
      renderCell: (params) => (
        <Delete skillQualification={params.row} />
      ),
      sortable: false,
    },
    {
      field: 'download',
      headerName: '',
      width: 75,
      sortable: false,
      renderCell: (params) => (
        <DownloadAttachmentsIcon attachments={params.row.attachments} />
      ),
    },
    {
      field: 'tagName',
      headerName: 'Tag Name',
      width: 200,
      renderCell: (params) => (
        <Typography variant='subtitle2'>
          {(params.row.tagName as Tag).name}
        </Typography>
      ),
    },
    {
      field: 'tagCategory',
      headerName: 'Tag Category',
      width: 150,
      renderCell: (params) => (
        <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='500'>
          {params.row.tagCategory}
        </Typography>
      ),
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry Date',
      width: 150,
      renderCell: (params) => (
        <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='500'>
          {params.row.neverExpires ? 'Never Expires' : params.row.expiryDate ? formatISODate(params.row.expiryDate) : 'N/A'}
        </Typography>
      ),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 150,
      renderCell: (params) => (
        <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='500'>
          {params.row.createdBy}
        </Typography>
      ),
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='500'>
          {params.row.createdDate && formatISODate(params.row.createdDate)}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        <EditStatus skillQualification={params.row} />
      ),
    },
  ];

  return (
    <StyledBox>
      <SearchBox>
        <Stack>
          <Typography fontSize='18px' fontWeight='600'>
            Skills & Qualifications
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Skills are important to ensure that Skymo recommends the right staff to the participant during shift allocation.
          </Typography>
        </Stack>
        <Stack flexDirection='row' gap='16px'>
          <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholderText='Search' />
          <Button variant='contained' sx={{ padding: '8px 16px' }} onClick={handleModalOpen}>
            <AddOutlinedIcon sx={{ marginRight: '8px' }} />
            Add
          </Button>
        </Stack>
      </SearchBox>
      <Box height='calc(100vh - 140px)'>
        <DataTable
          data={{ items: data as SkillQualification[], totalCount: 1, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
          columns={columns}
          checkboxSelection={false}
          dataTableName='skills-qualifications'
          isPending={isFetching || isPending || isLoading}
          isError={isError}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          title='Skill Qualifications'
          paginationMode='server'
          onNoData={handleModalOpen}
        />
      </Box>
    </StyledBox>
  );
};

// Add/Edit form
const SkillsQualificationsForm = ({ type, skillQualification }: { type: 'create' | 'edit'; skillQualification: SkillQualification, }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [includeValues, setIncludeValues] = useState<string[]>([]);
  const showError = useErrorMessage();
  const [currentFiles, setCurrentFiles] = useState<UploadedFile[]>([]);
  const validFileTypes = ['text/csv', 'application/pdf', 'image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
  const maxTotalSize = 104857600;
  const maxFileSize = 104857600;
  const { control, handleSubmit, watch, setValue, formState: { errors, isDirty } } = useForm<SkillQualification>({
    mode: 'onChange',
    defaultValues: skillQualification && {
      ...skillQualification,
      tagName: (skillQualification.tagName as Tag).id,
    }
  });

  const watchedTagCategory = watch('tagCategory');
  const watchedNeverExpires = watch('neverExpires', false);

  useEffect(() => {
    setIncludeValues([watchedTagCategory]);
  }, [watchedTagCategory]);

  const handleIsNever = (value: boolean) => {
    setValue('neverExpires', value);
    if (value) {
      setValue('expiryDate', null, { shouldDirty: true });
    }
  };

  const handleExpireDate = (value: string | null) => {
    setValue('expiryDate', value);
    if (value) {
      setValue('neverExpires', false, { shouldDirty: true });
    }
  };

  // Function to create a new Skill Qualification
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleCreate = async (data: SkillQualification) => {
    console.log('🚀 ~ handleCreate ~ data:', data);
    try {
      setInvalidateData((prev) => ({ ...prev, 'skills-qualifications': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to update a Skill Qualification.
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleEdit = async (data: SkillQualification) => {
    console.log('🚀 ~ handleEdit ~ data:', data);
    try {
      setInvalidateData((prev) => ({ ...prev, 'skills-qualifications': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to handle form submission
  const handleSubmitForm = async (data: any) => {
    if (type === 'create') {
      await handleCreate(data);
    }
    else {
      await handleEdit(data);
    }
  };

  return (
    <form style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack height='fit-content' padding='32px' gap='24px' sx={{ height: '100%', overflowY: 'auto' }}>
        <Typography variant='h6' fontWeight='600'>
          {type === 'create' ? 'Add Skill Qualification' : 'Edit Skill Qualification'}
        </Typography>
        <Controller
          name="tagCategory"
          control={control}
          rules={{ required: 'Tag Category is required' }}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='tagCategory'
              label='Tag Category'
              error={errors.tagCategory ? true : false}
              errorText={errors?.tagCategory?.message}
              placeholder='Enter Tag Category'
              isMandatory
              options={tagCategories}
            />
          }
        />
        <Controller
          name="tagName"
          control={control}
          rules={{ required: 'Tag Name is required' }}
          render={({ field }) =>
            <ServiceTeamTagLookupField
              {...field}
              id='tagName'
              label='Tag Name'
              validationError={errors.tagName ? true : false}
              errorText={errors.tagName?.message}
              isMandatory
              placeholder='Select'
              filterValues={includeValues}
              filterIncludeExclude='include'
            />
          }
        />
        <Stack flexDirection='row' alignItems='flex-end' gap={2}>
          <Controller
            name="expiryDate"
            control={control}
            rules={{
              validate: (value) => {
                if (watchedNeverExpires) return true; // If neverExpires is true, expiryDate is not required
                if (!value) return 'Expiry Date is required'; // Otherwise, expiryDate must have a value
                return true;
              }
            }}
            render={({ field }) =>
              <DatePickerInput
                {...field}
                id='expiryDate'
                label='Expiry Date'
                error={errors.expiryDate ? true : false}
                errorText={errors.expiryDate?.message}
                onChange={(value) => handleExpireDate(value)}
                isMandatory
              />}
          />
          <Controller
            name="neverExpires"
            control={control}
            render={({ field }) =>
              <CheckboxInput
                {...field}
                id='neverExpires'
                label="Never"
                error={errors.neverExpires ? true : false}
                checked={field.value ? true : false}
                onChange={(e) => handleIsNever(e.target.checked)}
              />}
          />
        </Stack>
        <Stack gap='8px'>
          <Stack flexDirection='row' gap='4px' alignItems='center'>
            <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary}>
              Documents
            </Typography>
            <Tooltip
              toolTipTitle='Supported documents'
              toolTipText={`You can upload multiple files, summing up to maximum ${(maxTotalSize / 1048576).toFixed(0)}MB. Files supported: ${validFileTypes.join(', ')}.`}
              toolTipTriggerComponent={
                <Box height='16px' width='16px' position='relative' minWidth='16px'>
                  <InfoOutlinedIcon sx={{ height: '100%', width: '100%' }} />
                </Box>
              }
            />
          </Stack>
          <DragAndDropFileUpload
            setCurrentFiles={setCurrentFiles}
            currentFiles={currentFiles}
            validFileTypes={validFileTypes}
            maxTotalSize={maxTotalSize}
            maxFileSize={maxFileSize}
            multiple
          />
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        <Button variant='outlined' color='primary' onClick={closeModal}>
          Cancel
        </Button>
        <Button type='submit' variant='contained' color='primary' disabled={!isDirty}>
          {type === 'create' ? 'Create' : 'Update'}
        </Button>
      </Stack>
    </form>
  );
};

const Delete = ({ skillQualification }: { skillQualification: SkillQualification }) => {
  const handleDelete = async () => {
    console.log('deleted:', skillQualification);
  };

  return (
    <DeleteOutlinedIcon sx={{ color: variables.colors.icon.standard }} onClick={handleDelete} />
  );
};

// Renders Edit Icon for Grid column definitions - opens add/edit form in modal
const Edit = ({ skillQualification }: { skillQualification: SkillQualification }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  if (!skillQualification) return null;

  // Function to open/close the modal.
  // The filteredColorList is passed to the form to filter out the colors that are already in use
  // We also filter the color of the current row to be able to select it
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'right',
      component: SkillsQualificationsForm,
      props: {
        dataTableName: 'skills-qualifications',
        type: 'edit',
        skillQualification: skillQualification,
      }
    }));
  }, [setModalState, skillQualification]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
    </Stack>
  );
};

// Handles the status dropdown in the DataTable
const EditStatus = ({ skillQualification }: { skillQualification: SkillQualification }) => {
  const [currentStatus, setCurrentStatus] = useState('');

  useEffect(() => {
    if (skillQualification.neverExpires) {
      setCurrentStatus('Current');
    } else if (skillQualification.expiryDate && new Date(skillQualification.expiryDate) < new Date()) {
      setCurrentStatus('Expired');
    } else {
      setCurrentStatus('Current');
    }
  }, []);

  if (!currentStatus) return null;

  return (
    <StatusDropdown
      statuses={SkillQualificationStatuses}
      currentStatus={currentStatus}
      disabled
    />
  );
};

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: white;
`;

const SearchBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 24px 24px 24px;
  gap: 16px;
`;

export default ServiceTeamSkillsQualifications;