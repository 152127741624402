import React, { FC, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import variables from 'styles/variables';
import { useNavigate } from 'react-router-dom';
import LinkContactDetails from './LinkContactDetails';
import CreateContact from './CreateContact';

const LinkContactToParticipantForm: FC = () => {
  const [linkOrCreate, setLinkOrCreate] = useState<'link' | 'create'>('link');
  const navigate = useNavigate();

  const handleCancel = () => navigate(-1);

  return (
    <StyledBox>
      <Banner>
        <MaxWidthContainer>
          <Stack flexDirection='row' gap={1} alignItems='center' minWidth='fit-content'>
            <AddOutlined sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
            <Typography variant='h5'>
              Link Contact
            </Typography>
          </Stack>
          <CloseOutlined sx={{ cursor: 'pointer' }} onClick={handleCancel} />
        </MaxWidthContainer>
      </Banner>
      <Stack padding='32px' width='100%' height='fit-content' justifyContent='center' alignItems='center' boxSizing='border-box' gap='32px' overflow='auto'>
        <DetailsStack>
          <Typography variant='h6' fontWeight='600'>
            Link a contact
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {`Easily link contacts to participants by searching existing entries. 
            If the contact isn't listed, utilise the "add new contact" shortcut to include them in the Contacts Console, simultaneously linking them to the participant profile.`}
          </Typography>
          <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-start'>
            <Button variant={linkOrCreate === 'link' ? 'contained' : 'outlined'} onClick={() => setLinkOrCreate('link')}>Link a Contact</Button>
            <Button variant={linkOrCreate === 'create' ? 'contained' : 'outlined'} onClick={() => setLinkOrCreate('create')}>Add New Contact</Button>
          </Stack>
        </DetailsStack>
        {linkOrCreate === 'link' ? <LinkContactDetails /> : <CreateContact />}
      </Stack>
    </StyledBox>
  );
};

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  background-color: ${variables.colors.primary.darker};
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  color: white;
  position: fixed;
  padding: 16px 32px;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 70px;
`;

const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  max-width: 960px;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  background-color: #F3F4F6;
  margin-top: 70px;
  min-height: calc(100vh - 70px);
`;

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export default LinkContactToParticipantForm;