import React from "react";
import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import EditStatusDropdown from "./EditStatusDropdown";
import EditIcon from "./EditIcon";

// Grid column definitions for DataTable
export const Columns: GridColDef[] = [
  // For MVP 1, we are not implementing the edit functionality
  {
    field: 'edit',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <EditIcon timeSheetDeclinedReason={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Time Sheet Declined Reason',
    flex: 1,
    renderCell: (params) => (
      <Typography variant='subtitle2'>
        {params.row.name}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <EditStatusDropdown timeSheetDeclinedReason={params.row} />
    ),
  },
];