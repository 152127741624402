import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { closeModalAtom } from 'atoms/modalAtom';
import PersonAddDisabledOutlinedIcon from '@mui/icons-material/PersonAddDisabledOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { ExternalContact } from 'types/dbSchema/externalContacts';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { getExternalContactByID } from 'api/organisations/externalcontacts';
import { linkParticipantToExternalContact } from 'api/organisations/participants/externalcontacts';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { AlreadyExistsModalProps } from '../types';

export const AlreadyExistsModal: FC<AlreadyExistsModalProps> = ({
  participantId,
  selectedContactId,
  relationshipData
}) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [foundExternalContact, setFoundExternalContact] = useState<ExternalContact | null>(null);
  const navigate = useNavigate();
  const showError = useErrorMessage();
  const [isLoading, setIsLoading] = useState(true);

  // Fetch the selectedContact using the selectedContactId
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!selectedContactId) return;
        setFoundExternalContact(await getExternalContactByID(organisations[0].organisation.globalId, selectedContactId as string));
      } catch (error) {
        showError((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [selectedContactId]);

  const handleLink = async () => {
    try {
      await linkParticipantToExternalContact(
        organisations[0].organisation.globalId,
        selectedContactId as string,
        participantId,
        relationshipData
      );
      setInvalidateData((prev) => ({ ...prev, 'participant-contacts': true }));
      closeModal();
      navigate(`/participant-centre/participants/view/${participantId}#contacts`);
    } catch (error) {
      showError((error as Error).message);
    }
  };

  if (isLoading) return <LoadingOverlay />;

  return (
    <form style={{ width: '500px' }} onSubmit={handleLink}>
      <Stack alignItems="center" width='100%'>
        <Stack flexDirection="row" alignItems="center" gap='24px' sx={{ padding: '32px' }}>
          <Box sx={{ borderRadius: '100%', background: '#FFECB3', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box' }}>
            <PersonAddDisabledOutlinedIcon color='warning' />
          </Box>
          <Stack gap='16px'>
            <Typography variant='h6'>
              Contact already in the system
            </Typography>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              The contact was found in the system. Would you like to link this contact with the participant?
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ padding: '0 32px 32px 32px', width: '100%' }}>
          <Stack flexDirection='row' border={`1px dashed ${variables.colors.primary.lighter}`} borderRadius='8px' padding='16px' gap='16px' width='100%' sx={{ boxSizing: 'border-box' }}>
            <Box sx={{
              borderRadius: '100%', background: 'rgba(75, 85, 99, 0.12)', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box'
            }}>
              <AccountBoxOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
            </Box>
            <Stack>
              <Typography fontSize='18px' fontWeight='600'>
                {foundExternalContact?.fullName.firstName} {foundExternalContact?.fullName.lastName}
              </Typography>
              <Stack flexDirection='row' gap='8px'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Email
                </Typography>
                <Typography variant='body2'>
                  {foundExternalContact?.email}
                </Typography>
              </Stack>
              <Stack flexDirection='row' gap='8px'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Mobile
                </Typography>
                <Typography variant='body2'>
                  {foundExternalContact?.mobile}
                </Typography>
              </Stack>
              <Stack flexDirection='row' gap='8px'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Phone
                </Typography>
                <Typography variant='body2'>
                  {foundExternalContact?.phone}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box >
        <ButtonStack>
          <Button variant='outlined' onClick={closeModal}>Cancel</Button>
          <Button variant='contained' color='primary' type='submit'>Link this contact</Button>
        </ButtonStack>
      </Stack >
    </form>
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;

export default AlreadyExistsModal;