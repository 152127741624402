import React, { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import { useForm } from 'react-hook-form';
import { SubscriptionDetailsData, SubscriptionDetailsProps } from '../types';
import styled from 'styled-components';
import CurrentStepIndicator from '../CurrentStepIndicator';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { onboarding } from 'api';
import { useMsal } from '@azure/msal-react';
import AuthenticationButton from 'components/common/AuthenticationButton';
import useUserOrganisations from 'hooks/useUserOrganisations';

// TODO: Update the items array with the correct data when available
const items = [
  {
    id: 'price_1OyTTJKBLLkUkmkdspd138IT',
    name: 'Basic',
    price: 0,
    description: 'Basic plan for small organisations',
    img: 'https://via.placeholder.com/150',
    features: ['Feature 1', 'Feature 2', 'Feature 3']
  },
  {
    id: 'price_1OyTTJKBLLkUkmkdspd138IT',
    name: 'Standard',
    price: 50,
    description: 'Standard plan for medium organisations',
    img: 'https://via.placeholder.com/150',
    features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4']
  },
  {
    id: 'price_1OyTTJKBLLkUkmkdspd138IT',
    name: 'Premium',
    price: 100,
    description: 'Premium plan for large organisations',
    img: 'https://via.placeholder.com/150',
    features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5']
  }
];

const SubscriptionDetails: FC<SubscriptionDetailsProps> = ({ currentStep, setCurrentStep, orgFormData, setOrgFormData }) => {
  const [, , fetchOrganisations] = useUserOrganisations();
  const { watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      plan: orgFormData.plan
    },
    mode: 'onChange'
  });
  const selectedPlan = watch('plan');

  const handleOnboarding = async () => {
    const response = await onboarding(
      orgFormData.plan,
      orgFormData.plan,
      orgFormData.userDetails.firstName,
      orgFormData.userDetails.lastName,
      accounts[0].username,
      orgFormData.userDetails.mobile,
      orgFormData.organisationDetails.organisationName,
      orgFormData.organisationDetails.NDISOrganisationNumber,
      orgFormData.organisationDetails.ABN.replaceAll(' ', '') // Remove spaces from ABN
    );
    // If the onboarding is successful, update the organisations atom with the new organisation
    if (response.success) await fetchOrganisations();
  };

  const onSubmit = async (data: SubscriptionDetailsData) => {
    setOrgFormData({
      ...orgFormData,
      plan: data.plan
    });
    await handleOnboarding();
    setCurrentStep(currentStep + 1);
  };
  const { accounts } = useMsal();

  return (
    <SubscriptionGridContainer>
      <Stack gap='8px'>
        <CurrentStepIndicator currentStep={currentStep} />
        <Typography variant="h6" >
          Subscription Details
        </Typography>
        <Typography variant="body2" color={variables.colors.darkNeutral.lighter}>
          Review your chosen plan.
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', height: '100%' }}>
        <Stack height='100%' gap='32px' width='100%'>
          <ItemStack flexDirection='row' justifyContent='space-between' height='100%' gap='24px'>
            {items.map((item, index) => (
              <Item
                id={item.id}
                key={index}
                onClick={() => setValue('plan', item.id)}
                sx={{
                  border: selectedPlan === item.id ?
                    `2px solid ${variables.colors.primary.main}` :
                    `2px solid ${variables.colors.lightNeutral.darker}`
                }}>
                <Box>
                  <img src={item.img} alt={item.name} />
                </Box>
                <Stack>
                  <Typography variant='h6'>{item.name}</Typography>
                  <Typography variant='body2'>{item.description}</Typography>
                  <Typography variant='h6'>${item.price}</Typography>
                  <Stack gap='8px'>
                    {item.features.map((feature, index) => (
                      <Stack key={index} gap='8px' flexDirection='row'>
                        <CheckOutlinedIcon sx={{ backgroundColor: variables.colors.success.main, borderRadius: '50%', fontSize: '10px', height: '10px', width: '10px', padding: '3px' }} />
                        <Typography variant='body2'>{feature}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </Item>
            ))}
          </ItemStack>
          <ButtonStack>
            <AuthenticationButton type='login logout' prompt='login' buttonText='Exit' />
            <Button type='submit' variant='contained' aria-label='Continue'>
              Continue
            </Button>
          </ButtonStack>
        </Stack>
      </form>
    </SubscriptionGridContainer>
  );
};

const SubscriptionGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 auto;
  width: calc(100% - 32px);
  max-width: 908px;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  /* max-width: 400px; */
  gap: 24px;
  padding-bottom: 24px;
  justify-content: flex-end;
  @media (max-width: 462px) {
    flex-direction: column;
    max-width: unset;
  }
`;

const ItemStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  gap: 32px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Item = styled(Stack)`
  flex: 1 1 0;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.24s ease;
  padding: 16px;
  gap: 16px;
  &:hover {
    border: 2px solid ${variables.colors.primary.lighter};
    scale: 1.05;
  }
  @media (max-width: 768px) {
    flex-direction: row;
  }
  @media (max-width: 448px) {
    flex-direction: column;
  }
`;

export default SubscriptionDetails;