import React from 'react';
import { useAtom } from 'jotai';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { ServiceTeamNoteType } from 'types/dbSchema/serviceTeamSettings';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import { updateServiceTeamNoteType } from 'api/organisations/settings/service-team/note-types';

// Handles the status dropdown in the DataTable
const EditStatusDropdown = ({ serviceTeamNoteType }: { serviceTeamNoteType: ServiceTeamNoteType }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('service-team-settings-note-types');
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateServiceTeamNoteType(organisations[0].organisation.globalId, { ...serviceTeamNoteType, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'service-team-settings-note-types': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={serviceTeamNoteType.isActive}
      setIsActive={handleEdit}
      disabled={!serviceTeamNoteType.isEditable}
    />
  );
};

export default EditStatusDropdown;