import { Stack } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
import { LineProps } from './types';

const Line: FC<LineProps> = ({
  children,
  noBottomBorder,
  gap,
  flexDirection,
  justifyContent,
  alignItems
}) => {
  return (
    <StyledLine
      flexDirection={flexDirection}
      gap={gap}
      noBottomBorder={noBottomBorder}
      justifyContent={justifyContent}
      alignItems={alignItems}
    >
      {children}
    </StyledLine>
  );
};


const StyledLine = styled(Stack).withConfig({
  shouldForwardProp: (prop) =>
    !['noBottomBorder', 'gap', 'flexDirection', 'justifyContent', 'alignItems'].includes(prop),
}) <LineProps>`
  gap: ${({ gap }) => gap ? `${gap}px` : '8px'};
  width: 100%;
  padding: 16px 24px;
  border-bottom: ${({ noBottomBorder }) => noBottomBorder ? 'none' : '1px solid #E5E7EB'};
  flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : 'row'};
  justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'flex-start'};
  align-items: ${({ alignItems }) => alignItems ? alignItems : 'center'};
`;


export default Line;