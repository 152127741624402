import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Box, Button, LinearProgress, MenuItem, Select, Stack, Typography } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { placeholderLeaveData } from 'utils/helpers/getDBData';
import { format, startOfWeek, addWeeks, addDays, Day } from 'date-fns';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { listPayPeriods } from 'api/organisations/settings/service-team/pay-periods';
import variables from 'styles/variables';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import SearchIcon from '@mui/icons-material/Search';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import ViewDropdown from './ViewDropdown';
import SettingsDropdown from './SettingsDropdown';
import ActionsDropdown from './ActionsDropdown';
import styled from 'styled-components';
import { handleNextClick, handlePrevClick, handleTodayClick } from 'utils/helpers/fullCalendarTemplates';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

const ServiceTeamRoster = () => {
  // TODO: Events will be all of the shifts and leave for the service team member.
  const [events, setEvents] = useState<any[]>([{
    start: '2024-08-21T10:00:00',
    end: '2024-08-21T16:00:00',
    display: 'inverse-background',
    backgroundColor: '#d0d5e0',
  }]);
  const [organisations] = useUserOrganisations();
  const calendarRef = useRef<any>(null);
  const [jumpDate, setJumpDate] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const [currentView, setCurrentView] = useState('payPeriod');
  const [currentStartTime, setCurrentStartTime] = useState('06:00:00');
  const [currentDay, setCurrentDay] = useState(0);
  const [payPeriodObject, setPayPeriodObject] = useState<any>({});
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const { isPending, isError, data, isFetching, isLoading } = useQuery({
    queryKey: ['service-team-roster'],
    queryFn: () => placeholderLeaveData,
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.serviceTeamRoster,
  });

  const { data: payPeriodData } = useQuery({
    queryKey: ['pay-periods'],
    queryFn: () => listPayPeriods(organisations[0].organisation.globalId, 1, 2),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.payPeriods,
  });

  const handleViewChange = (view: string) => {
    if (!calendarRef.current) return;
    const calendarApi = calendarRef.current.getApi();
    setCurrentView(view);
    setCurrentDay(0);
    calendarApi.changeView(view);
    setJumpDate('');
  };

  const handleJumpTo = (event: any) => {
    const calendarApi = calendarRef.current.getApi();
    const date = new Date(event.target.value);
    calendarApi.gotoDate(date);
    setJumpDate(event.target.value);
  };

  const handleStartTimeChange = (newTime: string) => {
    setCurrentStartTime(newTime);
  };

  // Generate options for the dropdown
  const generateDateOptions = () => {
    const localOptions: any[] = [];
    const now = new Date();
    if (!calendarRef.current) return localOptions;

    if (currentView === 'timeGridDay') {
      for (let i = 0; i <= 13; i++) {
        const date = addDays(now, i);
        localOptions.push({
          label: format(date, 'EEE, dd MM yyyy'),
          value: date,
        });
        setJumpDate(localOptions[0].value);
      }
    } else if (currentView === 'timeGridWeek') {
      for (let i = 0; i <= 13; i++) {
        const weekStart = startOfWeek(addWeeks(now, i), { weekStartsOn: 0 });
        localOptions.push({
          label: `${format(weekStart, 'dd MMM')} - ${format(addDays(weekStart, 6), 'dd MMM yyyy')}`,
          value: weekStart,
        });
        setJumpDate(localOptions[0].value);
      }
    } else if (currentView === 'dayGridMonth' || currentView === 'listMonth') {
      for (let i = 0; i <= 13; i++) {
        const monthStart = new Date(now.getFullYear(), now.getMonth() + i, 1);
        localOptions.push({
          label: format(monthStart, 'MMM yyyy'),
          value: monthStart,
        });
        setJumpDate(localOptions[0].value);
      }
    } else if (currentView === 'payPeriod' && payPeriodData) {
      const weekPeriod = payPeriodData.items[0].frequency === 'Weekly' ? 6 : 13;
      const addWeek = payPeriodData.items[0].frequency === 'Weekly' ? 1 : 2;
      const startDay = new Date(payPeriodData.items[0].rosterStartDate).getDay() as Day;
      for (let i = 0; i <= 12; i++) {
        const weekStart = startOfWeek(addWeeks(now, (addWeek * i)), { weekStartsOn: startDay });
        localOptions.push({
          label: `${format(weekStart, 'dd MMM')} - ${format(addDays(weekStart, weekPeriod), 'dd MMM yyyy')}`,
          value: weekStart,
        });
        setJumpDate(localOptions[0].value);
      }
    }
    return localOptions;
  };

  useEffect(() => {
    if (calendarRef.current) setOptions(generateDateOptions());
  }, [calendarRef.current, currentView, payPeriodData]);

  useEffect(() => {
    const storedStartTime = localStorage.getItem('startTime');
    if (storedStartTime) setCurrentStartTime(storedStartTime);
  }, []);

  useEffect(() => {
    if (payPeriodData && currentView === 'payPeriod') {
      const weekPeriod = payPeriodData.items[0].frequency === 'Weekly' ? 1 : 2;
      const startDay = new Date(payPeriodData.items[0].rosterStartDate).getDay();
      setCurrentDay(startDay);
      setPayPeriodObject({
        payPeriod: {
          type: 'timeGridWeek',
          duration: { weeks: weekPeriod },
          buttonText: 'Pay Period',
        }
      });
    }
  }, [payPeriodData, currentView]);

  useEffect(() => {
    localStorage.setItem('startTime', currentStartTime);
  }, [currentStartTime]);

  if (isPending || isFetching || isLoading) return <LinearProgress />;

  return (
    <Stack sx={{ backgroundColor: 'white', height: '100%' }} gap='16px'>
      <Stack>
        <Stack flexDirection='row' justifyContent='space-between' padding='24px'>
          <Typography variant='h6'>Employee Roster</Typography>
          <Stack flexDirection='row' gap='8px'>
            <ActionsDropdown />
            <Button variant='contained'>Release all shifts</Button>
          </Stack>
        </Stack>
        <Stack flexDirection='row' justifyContent='space-between' padding='0 24px' alignItems='center' gap='12px'>
          <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='12px'>
            <ViewDropdown handleViewChange={handleViewChange} />
            <Button onClick={() => handleTodayClick(calendarRef)} variant='outlined' sx={{ color: variables.colors.text.main, borderColor: variables.colors.border.main }}>Today</Button>
            <Select value={jumpDate} onChange={handleJumpTo} variant='standard' disableUnderline>
              {options && options.map((option, index) => (
                <MenuItem key={index} value={option.value.toString()}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <Button onClick={() => handlePrevClick(calendarRef)} sx={{ padding: 0, minWidth: 'unset', color: 'black' }}><KeyboardArrowLeftIcon /></Button>
            <Button onClick={() => handleNextClick(calendarRef)} sx={{ padding: 0, minWidth: 'unset', color: 'black' }}><KeyboardArrowRightIcon /></Button>
          </Stack>
          <Stack flexDirection='row' justifyContent='flex-end' alignItems='center' gap='12px'>
            <Button onClick={() => console.log('test')} startIcon={<SearchIcon />} sx={{ color: variables.colors.icon.standard, padding: '8px', minWidth: 'unset' }}>Filter</Button>
            <Button onClick={() => console.log('test')} startIcon={<LibraryAddCheckOutlinedIcon />} sx={{ color: variables.colors.icon.standard, padding: '8px', minWidth: 'unset' }}>Multi-select</Button>
            <SettingsDropdown
              isSettingsOpen={isSettingsOpen}
              setIsSettingsOpen={setIsSettingsOpen}
              handleStartTimeChange={handleStartTimeChange}
              currentStartTime={currentStartTime}
            />
            <Button onClick={() => console.log('test')} sx={{ color: variables.colors.icon.standard, padding: '8px', minWidth: 'unset' }}><FileDownloadOutlinedIcon /></Button>
            <Button onClick={() => console.log('test')} sx={{ color: variables.colors.icon.standard, padding: '8px', minWidth: 'unset' }}><OpenInFullOutlinedIcon /></Button>
          </Stack>
        </Stack>
      </Stack>
      <StyledDiv>
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin, listPlugin]}
          initialView="timeGridWeek"
          views={{
            ...payPeriodObject
          }}
          selectable={false}
          events={events}
          nowIndicator
          headerToolbar={false}
          schedulerLicenseKey='0431355106-fcs-1732322022'
          slotDuration='1:00'
          slotLabelInterval={{ hours: 1 }}
          slotLabelFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }}
          // eventContent={renderEventContent}
          dayHeaderContent={DayHeader}
          droppable={true}
          editable={true}
          allDayText=''
          firstDay={currentDay}
          height='calc(100vh - 214px)'
          slotMinTime={currentStartTime}
        />
      </StyledDiv>
    </Stack>
  );
};

const DayHeader = (args: any) => {
  const isToday = new Date().toDateString() === args.date.toDateString();
  return (
    <Stack flexDirection='row' alignItems='center' gap='12px'>
      <Typography variant='caption'>{args.date.toLocaleDateString('en-US', { weekday: 'short' })}</Typography>
      <Box
        sx={{
          width: '28px',
          height: '28px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          backgroundColor: isToday ? variables.colors.primary.darker : 'transparent',
        }}
      >
        <Typography fontSize='18px' fontWeight='400' color={isToday ? 'white' : 'inherit'}>
          {args.date.toLocaleDateString('en-US', { day: 'numeric' })}
        </Typography>
      </Box>
    </Stack>
  );
};

const StyledDiv = styled.div`
  height: 100%;
  background-color: white;

  .fc .fc-timegrid-slot {
    height: 64px;
  }

  .fc .fc-daygrid-day {
    height: 64px;
  }

  .fc-day-today {
    background-color: transparent !important;
  }
`;

export default ServiceTeamRoster;