import React from 'react';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import variables from 'styles/variables';
import ColorDisplayBox from 'components/common/ColorDisplayBox';
import EditIcon from './EditIcon';
import EditStatusDropdown from './EditStatusDropdown';

export const Columns = (filteredColorList: string[]): GridColDef[] => ([
  {
    field: 'edit',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <EditIcon serviceType={params.row} filteredColorList={filteredColorList} />
    ),
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Service Type',
    width: 200,
    renderCell: (params) => (
      <Typography variant='subtitle2'>
        {params.row.name}
      </Typography>
    ),
  },
  {
    field: 'claimType',
    headerName: 'Claim Type',
    width: 150,
    renderCell: (params) => (
      <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='500'>
        {params.row.claimType}
      </Typography>
    ),
  },
  {
    field: 'color',
    headerName: 'Color',
    flex: 1,
    renderCell: (params) => (
      <ColorDisplayBox color={params.row.color} />
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <EditStatusDropdown serviceType={params.row} />
    ),
  },
]);