import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import styled from 'styled-components';
import DataTable from 'components/common/DataTable';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { ServiceType } from 'types/dbSchema/schedulingSettings';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { listServiceTypes } from 'api/organisations/settings/scheduling/service-types';
import useInvalidateData from 'hooks/useInvalidateData';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';
import ServiceTypeForm from 'components/features/Forms/ServiceTypeForm';
import { Columns } from './Columns';

const ServiceTypesDataTable = () => {
  const [, setModalState] = useAtom(modalStateAtom);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  useInvalidateData('service-type');

  const { isError, data, isPending, isFetching, isLoading } = useQuery({
    queryKey: ['service-type', paginationModel],
    queryFn: () => listServiceTypes(
      organisations[0].organisation.globalId,
      paginationModel.page + 1,
      paginationModel.pageSize
    ),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.serviceTypes,
  });

  // Filter out the colors that are already in use
  const filteredColorList = useMemo(() => data ? (data?.items as ServiceType[]).map(row => row.color) : [], [data]);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ServiceTypeForm,
      props: {
        dataTableName: 'service-type',
        filterOut: filteredColorList,
        type: 'create',
      }
    }));
  }, [setModalState, filteredColorList]);

  return (
    <StyledBox>
      <SearchBox>
        <Button variant='contained' sx={{ padding: '8px 16px' }} onClick={handleModalOpen}>
          <AddOutlinedIcon sx={{ marginRight: '8px' }} />
          Add
        </Button>
      </SearchBox>
      <Box height='calc(100vh - 295px)'>
        <DataTable
          data={data as Data}
          columns={Columns(filteredColorList)}
          checkboxSelection={false}
          dataTableName='service-type'
          isPending={isFetching || isPending || isLoading}
          isError={isError}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          title='Service Types'
          paginationMode='server'
          onNoData={handleModalOpen}
        />
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: white;
`;

const SearchBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
`;

export default ServiceTypesDataTable;