import { Box, Stack, Typography } from '@mui/material';
import UserProfileCell from 'components/features/UserProfileCell';
import React, { FC } from 'react';
import styled from 'styled-components';
import variables from 'styles/variables';
import { Comment } from 'types/dbSchema/participantGoals';
import { camelCaseToSeparatedWords, formatISODate, formatISODateTime } from 'utils/helpers';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useAtom } from 'jotai';
import useErrorMessage from 'hooks/useErrorMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteCommentFromGoal } from 'api/organisations/participants/goals';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { triggerRefreshCurrentDataPointAtom } from 'atoms';

export interface CommentProps {
  comment: Comment;
  canDelete?: boolean;
}

const CommentCard: FC<CommentProps> = ({ comment, canDelete }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const [, setTriggerRefresh] = useAtom(triggerRefreshCurrentDataPointAtom);
  const showError = useErrorMessage();
  const navigate = useNavigate();
  const { id, goalId } = useParams();

  const handleDeleteComment = async () => {
    try {
      await deleteCommentFromGoal(
        organisations[0].organisation.globalId,
        id as string,
        goalId as string,
        comment.id as string
      );
      setInvalidateData((prev) => ({ ...prev, 'participant-goals': true }));
      setTriggerRefresh((prev) => prev + 1);
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <CommentBox>
      <Stack flexDirection='row' gap='16px' width='100%' justifyContent='space-between' alignItems='center'>
        <Stack flexDirection='row' gap='16px' width='fit-content' justifyContent='flex-start' alignItems='center'>
          {/* TODO: Display the person's name who wrote the comment*/}
          {/* <UserProfileCell displayOnly userProfile={dummyUserProfileDataNEW[0]} nameOnly /> */}
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {formatISODateTime(comment.createdAt)}
          </Typography>
          {comment.commentType === 'generalComment' ? <GeneralComment /> : <GoalReview />}
        </Stack>
        {canDelete && (
          <DeleteOutlinedIcon
            onClick={handleDeleteComment}
            sx={{
              cursor: 'pointer',
              color: variables.colors.error.main
            }}
          />
        )}
      </Stack>
      <Typography variant='body2' dangerouslySetInnerHTML={{ __html: comment.comment }} />
      {comment.commentType === 'goalReview' &&
        <Stack sx={{ borderTop: '1px solid #E5E7EB' }} width='100%' paddingTop='16px' gap='16px'>
          <Typography variant='body2' fontSize='12px' fontWeight='600' color={variables.colors.text.secondary}>
            GOAL REVIEWED
          </Typography>
          <Stack gap='8px' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary} minWidth='140px'>
              Goal Type
            </Typography>
            <Typography variant='body2'>
              {comment.participantGoalSnapshot?.goalType && camelCaseToSeparatedWords(comment.participantGoalSnapshot?.goalType)}
            </Typography>
          </Stack>
          <Stack gap='8px' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary} minWidth='140px'>
              Goal
            </Typography>
            <Typography variant='subtitle2'>
              {comment.participantGoalSnapshot?.goal}
            </Typography>
          </Stack>
          <Stack gap='8px' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary} minWidth='140px'>
              Description
            </Typography>
            <Typography variant='body2'>
              {comment.participantGoalSnapshot?.description}
            </Typography>
          </Stack>
          <Stack gap='8px' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary} minWidth='140px'>
              Review Date
            </Typography>
            <Typography variant='body2'>
              {comment.participantGoalSnapshot?.reviewDate && formatISODate(comment.participantGoalSnapshot?.reviewDate)}
            </Typography>
          </Stack>
          <Stack gap='8px' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary} minWidth='140px'>
              Score
            </Typography>
            <Typography variant='body2'>
              {comment.participantGoalSnapshot?.score}
            </Typography>
          </Stack>
        </Stack>
      }
    </CommentBox >
  );
};


const GeneralComment = () => {
  return (
    <Stack sx={{ backgroundColor: '#5C068C0A', padding: '4px 8px', borderRadius: '4px', width: 'fit-content', position: 'relative' }} direction='row' alignItems='center'>
      <Typography variant='subtitle2' fontWeight='600' color='#AC4FC6'>
        General Comment
      </Typography>
    </Stack>
  );
};

const GoalReview = () => {
  return (
    <Stack sx={{ backgroundColor: '#03A9F414', padding: '4px 8px', borderRadius: '4px', width: 'fit-content', position: 'relative' }} direction='row' alignItems='center'>
      <Typography variant='subtitle2' fontWeight='600' color='#01579B'>
        Goal Review
      </Typography>
    </Stack>
  );
};

const CommentBox = styled(Stack)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;border-radius: 8px;
  background: #F9FAFB;
  /* border: 1px solid #E0E0E0; */
`;

export default CommentCard;