import React, { useState, useRef, useEffect, forwardRef, useCallback } from 'react';
import { LinearProgress, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import SearchInput from '../SearchInput';
import { AddressLookupFieldProps } from './types';
import { getAddressSuggestions, validateAddress } from 'api/address';
import { formatAddress } from 'utils/helpers';
import useDebounceValue from 'hooks/useDebounceValue';

const AddressLookupField = forwardRef<HTMLDivElement, AddressLookupFieldProps>(({
  id,
  label,
  helperText,
  placeholder,
  value,
  setValue,
  isMandatory,
  errorText,
  error,
  resetField,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localErrorText, setLocalErrorText] = useState('');
  const [searchValue, setSearchValue] = useState(value?.streetAddress ? formatAddress(value) : '');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [dropUp, setDropUp] = useState(false);
  const internalRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Function to check if the click is outside the component
    const handleClickOutside = (event: MouseEvent) => {
      if (internalRef.current && !internalRef.current.contains(event.target as Node) &&
        dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle input changes and fetch suggestions
  const handleInput = (value: string) => {
    setSearchValue(value);

    if (!isOpen) setIsOpen(true);

    if (value === '') setLocalErrorText(''); // Clear error when input is cleared
  };

  const fetchSuggestions = useCallback(async (search: string) => {
    setLocalErrorText('');
    try {
      const response = await getAddressSuggestions(search);
      setSuggestions(Array.isArray(response) ? response : []);
    } catch (err) {
      setSuggestions([]);
    }
  }, []);

  // Debounced version of fetchSuggestions
  const debouncedSearchValue = useDebounceValue(searchValue, 500);

  useEffect(() => {
    if (!debouncedSearchValue) {
      // if it's empty, close or reset
      setIsOpen(false);
      return;
    }

    // If we do have a debounced string, fetch suggestions
    fetchSuggestions(debouncedSearchValue);
  }, [debouncedSearchValue, fetchSuggestions]);

  const handleOptionClick = async (selectedSuggestion: string) => {
    setIsLoading(true);
    const validatedAddress = await validateAddress(selectedSuggestion);
    if (validatedAddress?.status === 400) {
      setLocalErrorText(validatedAddress.detail);
    } else {
      setSearchValue(selectedSuggestion);
      setValue(validatedAddress);
    }
    setIsOpen(false); // Close dropdown after selecting an option
    setIsLoading(false);
  };

  const handleClear = () => {
    setSearchValue('');
    setSuggestions([]);
    setLocalErrorText('');
    setIsOpen(false);
    resetField(id, { defaultValue: null });
  };

  // Function to update dropUp state based on available space
  const updateDropUp = () => {
    if (internalRef.current) {
      const inputRect = internalRef.current.getBoundingClientRect();
      const dropdownHeight = 250; // as per maxHeight of the Stack inside Dropdown
      const viewportHeight = window.innerHeight;

      if (inputRect) {
        // Calculate space below and above the input
        const spaceBelow = viewportHeight - inputRect.bottom;
        const spaceAbove = inputRect.top;

        if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
          // Not enough space below, but enough space above
          setDropUp(true);
        } else {
          setDropUp(false);
        }
      }
    }
  };

  // Update dropUp state when dropdown is opened
  useEffect(() => {
    if (isOpen) {
      updateDropUp();
      window.addEventListener('resize', updateDropUp);
    } else {
      window.removeEventListener('resize', updateDropUp);
    }

    // Clean up the event listener when component unmounts or dropdown closes
    return () => {
      window.removeEventListener('resize', updateDropUp);
    };
  }, [isOpen]);

  return (
    <Stack ref={ref}>
      {label && (
        <label htmlFor={id}>
          <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
            {label} {isMandatory && <span style={{ color: 'red' }}>*</span>}
          </Typography>
        </label>
      )}
      <div ref={internalRef} style={{ margin: '0', position: 'relative', width: '100%' }}>
        <Stack sx={{ position: 'relative' }}>
          <SearchInput
            placeholderText={placeholder || ''}
            searchValue={searchValue}
            setSearchValue={handleInput}
            onClear={handleClear}
            error={(localErrorText || error) ? true : false}
          />
          {isOpen && (
            <Dropdown ref={dropdownRef} dropUp={dropUp}>
              <Stack maxHeight='250px' overflow='auto'>
                {suggestions.length > 0 && suggestions.map((suggestion) => (
                  <Stack key={suggestion} onClick={() => handleOptionClick(suggestion)} sx={{ cursor: 'pointer', minHeight: '36px', justifyContent: 'center', padding: '0 10px' }}>
                    <Typography variant='body2' tabIndex={0}>
                      {suggestion}
                    </Typography>
                  </Stack>
                ))}
                {searchValue.length === 0 ? (
                  <Stack sx={{ minHeight: '36px', justifyContent: 'center', padding: '0 10px' }}>
                    <Typography variant='body2' tabIndex={0}>
                      Please enter an address.
                    </Typography>
                  </Stack>
                ) : suggestions.length === 0 && (
                  <Stack sx={{ minHeight: '36px', justifyContent: 'center', padding: '0 10px' }}>
                    <Typography variant='body2' tabIndex={0}>
                      No suggestions found.
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Dropdown>
          )}
          {isLoading && (
            <LinearProgress
              sx={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                borderRadius: '4px 4px 0 0',
              }}
            />
          )}
        </Stack>
        {localErrorText ? <Typography variant='subtitle2' color='red' fontWeight='400'>{localErrorText}</Typography>
          : (error && errorText) && <Typography variant='subtitle2' color='red' fontWeight='400'>{errorText}</Typography>
        }
        {helperText && <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='400'>{helperText}</Typography>}
      </div >
    </Stack>
  );
});

export interface DropdownProps {
  dropUp?: boolean;
}

const Dropdown = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'dropUp',
}) <DropdownProps>`
  z-index: 999;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  gap: 8px;
  position: absolute;
  top: ${({ dropUp }) => dropUp ? 'unset' : '42px'};
  bottom: ${({ dropUp }) => dropUp ? '100%' : 'unset'};
  left: 0;
  background-color: white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 8px -1px #E0E0E0;
  border-radius: 4px;
`;

// We need to set the displayName for the forwardRef to work correctly
AddressLookupField.displayName = 'AddressLookupField';

export default AddressLookupField;