import React, { FC, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Columns } from './Columns';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { PaginationModel } from 'components/common/DataTable/types';
import { unreleasedShiftsData } from 'utils/helpers/getDBData';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useNavigate } from 'react-router-dom';
import variables from 'styles/variables';
import { permissions } from 'types/dbSchema/permissions';
import { useHasPermission } from 'hooks/useHasPermission';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';

const UnreleasedShiftsWidget: FC = () => {
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  // const [currentUser] = useCurrentUser();
  useInvalidateData('unreleased-shifts');
  const navigate = useNavigate();
  const requiredPermissions = [permissions.VIEW_SHIFT_RELEASES];

  const { isPending, isError, data, isFetching, isLoading } = useQuery({
    queryKey: ['unreleased-shifts', paginationModel],
    // queryFn: () => listPositions(organisations[0].organisation.globalId, paginationModel.page + 1, paginationModel.pageSize),
    queryFn: () => unreleasedShiftsData,
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.unallocatedServices,
  });

  const hasPermission = useHasPermission(requiredPermissions);

  if (!hasPermission) return null;

  return (
    <Stack height='400px' width='100%' sx={{ backgroundColor: 'white' }} borderRadius='12px'>
      <Stack flexDirection='row' justifyContent='space-between' alignItems='center' padding='0 12px'>
        <Typography variant='h6' padding='16px'>
          Unreleased Shifts
        </Typography>
        <Button
          variant='text'
          endIcon={<ArrowForwardOutlinedIcon />}
          sx={{ color: variables.colors.text.primary }}
          onClick={() => navigate('/scheduling/unallocated-services')}
        >
          View All
        </Button>
      </Stack>
      <DataTable
        data={{ items: data || [], totalCount: 4, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
        // data={data as Data}
        columns={Columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching || isLoading}
        isError={isError}
        title='Unallocated Services'
        dataTableName='unallocated-services'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={() => navigate('/scheduling/unallocated-services')}
      />
    </Stack>
  );
};

export default UnreleasedShiftsWidget;