import React, { FC } from 'react';
import { useCallback } from "react";
import { useAtom } from "jotai";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { modalStateAtom, triggerRefreshCurrentDataPointAtom } from "atoms";
import { Box, Button, Stack, Typography } from "@mui/material";
import variables from 'styles/variables';
import styled from 'styled-components';
import { closeModalAtom } from 'atoms/modalAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useNavigate, useParams } from 'react-router-dom';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { ProgressNote } from 'types/dbSchema/progressNotes';
import { updateParticipantProgressNoteById } from 'api/organisations/participants/progressNotes';
import { convertProgressNote } from 'utils/helpers/postDataConvert';

export const UnlinkIcon = ({ progressNote }: { progressNote: ProgressNote }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  const { id, goalId } = useParams();

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: UnlinkModal,
      props: {
        dataTableName: 'participant-progress-notes',
        progressNote,
        goalId,
        participantId: id,
      }
    }));
  }, [setModalState]);


  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <LinkOffIcon sx={{ color: variables.colors.icon.standard }} />
    </Stack>
  );
};

export interface UnlinkModalProps {
  progressNote: ProgressNote;
  participantId: string;
  backAfterUnlink?: boolean;
}

export const UnlinkModal: FC<UnlinkModalProps> = ({ progressNote, participantId, backAfterUnlink = false }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const navigate = useNavigate();
  const [, setTriggerRefresh] = useAtom(triggerRefreshCurrentDataPointAtom);

  const handleUnlinkFromGoal = async () => {
    try {
      if (!progressNote.id) return;

      await updateParticipantProgressNoteById(
        organisations[0]?.organisation.globalId,
        participantId,
        progressNote.id,
        convertProgressNote(progressNote, null),
      );
      setInvalidateData((prev) => ({ ...prev, 'linked-participant-progress-notes': true }));
      setTriggerRefresh((prev) => prev + 1);
      closeModal();
      backAfterUnlink && navigate(-1);
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // const handleUnlink = async () => {
  //   if (!progressNote || !progressNote.id || !participantId) {
  //     showError('Participant or contact not found');
  //     return;
  //   }
  //   try {
  //     await updateParticipantProgressNoteById(
  //       organisations[0].organisation.globalId,
  //       participantId,
  //       progressNote.id,
  //       { ...progressNote, participantGoal: null }
  //     );
  //     setInvalidateData((prev) => ({ ...prev, 'linked-participant-progress-notes': true }));
  //     closeModal();
  //     backAfterUnlink && navigate(-1);
  //   } catch (error) {
  //     showError((error as Error).message);
  //   }
  // };

  return (
    <Stack alignItems="center" width='500px'>
      <Stack flexDirection="row" alignItems="center" gap='24px' sx={{ padding: '32px' }}>
        <Box sx={{ borderRadius: '100%', background: '#FECDD2', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box' }}>
          <LinkOffIcon color='error' />
        </Box>
        <Stack gap='16px'>
          <Typography variant='h6'>
            Unlink Progress Note
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Are you sure you want to unlink the selected progress note from this goal?
          </Typography>
        </Stack>
      </Stack>
      <ButtonStack>
        <Button variant='outlined' onClick={closeModal}>Cancel</Button>
        <Button variant='contained' color='error' onClick={handleUnlinkFromGoal}>Unlink</Button>
      </ButtonStack>
    </Stack>
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;