import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { formatISODate } from 'utils/helpers';
import ListServiceTypes from 'components/features/ListServiceTypes';
import variables from 'styles/variables';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

export const Columns: GridColDef[] = [
  {
    field: 'participantName',
    headerName: 'Participant',
    width: 150,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {params.row.participant.fullName.firstName} {params.row.participant.fullName.lastName}
      </Typography>
    ),
  },
  {
    field: 'serviceType',
    headerName: 'Service Types',
    width: 200,
    renderCell: (params) => (
      <ListServiceTypes
        serviceTypesDelivered={params.row.serviceTypes}
      />
    ),
  },
  {
    field: 'scheduledStart',
    headerName: 'Date',
    minWidth: 100,
    flex: 1,
    renderCell: (params) => (
      <Typography color={variables.colors.text.secondary} variant='body2'>
        {params.row?.scheduledStart && formatISODate(params.row?.scheduledStart)}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <CircleOutlinedIcon
        sx={{
          borderRadius: '4px',
          height: '16px',
          width: '16px',
          padding: '2px',
          backgroundColor: '#D32F2F',
          color: 'white'
        }}
      />
    )
  },
];