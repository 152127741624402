import React, { useCallback } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { ShiftLocation } from 'types/dbSchema/schedulingSettings';
import ShiftLocationForm from 'components/features/Forms/ShiftLocationForm';
import { Stack } from '@mui/material';

const EditIcon = ({ shiftLocation }: { shiftLocation: ShiftLocation }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  // If the shift location is not editable, return null
  if (!shiftLocation || !shiftLocation.isEditable) return null;

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ShiftLocationForm,
      props: {
        dataTableName: 'shift-locations',
        type: 'edit',
        shiftLocation: shiftLocation,
      }
    }));
  }, [setModalState, shiftLocation]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default EditIcon;