import React, { FC } from 'react';
import { updateParticipantById } from 'api/organisations/participants';
import ParticipantStatusDropdown from 'components/common/LookupInputField/ParticipantStatusDropdown';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { ParticipantStatus } from 'types/dbSchema/participantSettings';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { convertParticipant } from 'utils/helpers/postDataConvert';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { ParticipantStatusFlowProps } from './types';
import InactiveModalContent from './InactiveModalContent';
import DeceasedModalContent from './DeceasedModalContent';

const ParticipantStatusFlow: FC<ParticipantStatusFlowProps> = ({ participant }) => {
  const [organisations] = useUserOrganisations();
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);

  // Handles the modal for the deceased status
  const handleDeceased = (status: ParticipantStatus) => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: DeceasedModalContent,
      props: {
        participant: participant,
        status: status
      },
    });
  };

  // Handles the modal for the inactive status
  const handleInactive = (status: ParticipantStatus) => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: InactiveModalContent,
      props: {
        participant: participant,
        status: status
      },
    });
  };

  // Handles the status change for the participant
  // If it is deceased or inactive, it will open the modal
  // If it is any other status, it will update the participant status
  const handleStatusChange = async (status: ParticipantStatus) => {
    if (!status) return;
    if (status.name === 'Deceased') {
      handleDeceased(status);
    } else if (status.name === 'Inactive') {
      handleInactive(status);
    } else {
      // Update the participant status
      await updateParticipantById(
        organisations[0].organisation.globalId,
        participant.id as string,
        convertParticipant({ ...participant, status })
      );

      setInvalidateData((prev) => ({ ...prev, 'participants': true }));
    }
  };

  return (
    <ParticipantStatusDropdown
      participant={participant}
      setStatus={handleStatusChange}
      isStateBased={false}
    // disabled={!useHasPermission([permissions.MODIFY_PARTICIPANT_DETAILS])}
    />
  );
};

export default ParticipantStatusFlow;