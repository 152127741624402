import React from 'react';
import SettingsTabs from 'components/common/SettingsTabs';
import CancellationCodesDataTable from 'components/features/DataTables/CancellationCodesDataTable';
import ServiceTypesDataTable from 'components/features/DataTables/ServiceTypesDataTable';
import ShiftLocationsDataTable from 'components/features/DataTables/ShiftLocationsDataTable';
// Not in the scope of MVP1
// import DeclinedReasonsDataTable from 'components/features/DataTables/DeclinedReasonsDataTable';

const SchedulingSettings = () => {
  return (
    <SettingsTabs
      title='Scheduling Settings'
      tabs={[
        {
          label: 'Service Types',
          content: <ServiceTypesDataTable />,
          requiredPermissions: [],
        },
        {
          label: 'Cancellation Codes',
          content: <CancellationCodesDataTable />,
          requiredPermissions: [],
        },
        // This component is partially completed (except for the APIs), it is not in the scope of MVP1
        // {
        //   label: 'Declined Reasons',
        //   content: <DeclinedReasonsDataTable />,
        //   requiredPermissions: [],
        // },
        {
          label: 'Shift Locations',
          content: <ShiftLocationsDataTable />,
          requiredPermissions: [],
        },
      ]}
    />
  );
};

export default SchedulingSettings;