import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import styled from 'styled-components';
import variables from 'styles/variables';
import ProfileImageUpload from 'components/common/InputFields/ProfileImageFileUpload';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Participant } from 'types/dbSchema/participants';
import Tabs from 'components/common/Tabs';
import ParticipantDetails from './ParticipantDetails';
import Contacts from './Contacts';
import ServiceContracts from './ServiceContracts';
import Goals from './Goals';
import GeneralNotes from './GeneralNotes';
import Alerts from './Alerts';
import ProgressNotes from './ProgressNotes';
import { ExitReason } from 'types/dbSchema/participantSettings';
import Tooltip from 'components/common/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { formatISODate } from 'utils/helpers';
import { deleteParticipantById } from 'api/organisations/participants';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useAtom } from 'jotai';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import { permissions } from 'types/dbSchema/permissions';
import ParticipantAlertBox from '../../ParticipantAlertBox';

// Commented out Incidents and Tasks, they will be released after MVP1
// import Incidents from './Incidents';
// import Tasks from './Tasks';
export interface ViewParticipantsProps {
  participant: Participant;
}

const ViewParticipants: FC<ViewParticipantsProps> = ({ participant }) => {
  return (
    <Stack width='100%' justifyContent='flex-start' alignItems='center' height='100%' sx={{
      backgroundColor: 'white',
      minHeight: `calc(100vh - ${variables.heights.topBar})`,
      overflowY: 'auto',
      position: 'relative',
    }}>
      <PageHeader participant={participant} />
      <Stack flexDirection='row' gap='24px' width='100%' height='100%' padding='0' >
        <Tabs tabs={[
          {
            label: 'Participant Details',
            content: <ParticipantDetails participant={participant} />,
            requiredPermissions: [permissions.VIEW_PARTICIPANTS, permissions.VIEW_PARTICIPANT_DETAILS],
          },
          {
            label: 'Contacts',
            content: <Contacts />,
            requiredPermissions: [permissions.VIEW_PARTICIPANTS, permissions.VIEW_PARTICIPANT_DETAILS],
          },
          {
            label: 'Service Contracts',
            content: <ServiceContracts />,
            requiredPermissions: [permissions.VIEW_PARTICIPANTS, permissions.VIEW_PARTICIPANT_CONTACTS],
          },
          {
            label: 'Goals',
            content: <Goals />,
            requiredPermissions: [permissions.VIEW_PARTICIPANTS, permissions.VIEW_PARTICIPANT_GOALS],
          },
          {
            label: 'Progress Notes',
            content: <ProgressNotes />,
            requiredPermissions: [permissions.VIEW_PARTICIPANTS, permissions.VIEW_PARTICIPANT_PROGRESS_NOTES],
          },
          {
            label: 'General Notes',
            content: <GeneralNotes />,
            requiredPermissions: [permissions.VIEW_PARTICIPANTS, permissions.VIEW_PARTICIPANT_GENERAL_NOTES],
          },
          // Commented out Incidents and Tasks, they will be released after MVP1
          // {
          //   label: 'Incidents',
          //   content: <Incidents participant={participant} />,
          //   requiredPermissions: [permissions.VIEW_PARTICIPANTS,],
          // },
          // {
          //   label: 'Tasks',
          //   content: <Tasks participant={participant} />,
          //   requiredPermissions: [permissions.VIEW_PARTICIPANTS,'],
          // },
          {
            label: 'Alerts',
            content: <Alerts participant={participant} />,
            requiredPermissions: [permissions.VIEW_PARTICIPANTS, permissions.VIEW_PARTICIPANT_ALERTS],
          },
        ]} />
      </Stack>
      <Box sx={{
        width: '100%',
        padding: '0 24px',
        boxSizing: 'border-box',
      }} >
      </Box>
      <ParticipantAlertBox participant={participant} />
    </Stack>
  );
};

const PageHeader: FC<{ participant: Participant }> = ({ participant }) => {
  const navigate = useNavigate();

  return (
    <PageHeaderStack>
      <Button variant='text'
        sx={{
          color: variables.colors.text.secondary,
          textTransform: 'none',
          fontWeight: 400,
          padding: 0
        }}
        onClick={() => navigate('/participant-centre/participants')}
      >
        <ArrowBackOutlinedIcon sx={{
          padding: '4px',
          boxSizing: 'border-box',
          height: '24px',
          width: '24px',
        }}
        />
        Participants
      </Button>
      <Stack flexDirection='row' gap={1} justifyContent='space-between' alignItems='center' width='100%' position='relative'>
        <Stack flexDirection='row' gap='16px' alignItems='center'>
          <ProfileImageUpload variant='icon' value={participant.profilePicture} />
          <Typography variant='h5' minWidth='fit-content'>
            {participant.fullName.firstName} {participant.fullName.lastName}
          </Typography>
          {/* <ParticipantStatusFlow participant={participant} /> */}
          {(participant?.exitDate && participant.exitReason) ? (
            <Tooltip
              toolTipTitle={`Exit date ${formatISODate(participant.exitDate)}`}
              toolTipText={`${(participant.exitReason as ExitReason).name}`}
              toolTipTriggerComponent={<InfoOutlinedIcon sx={{ height: '100%', width: '100%' }} />}
            />
          ) : participant?.deceasedDate && (
            <Tooltip
              toolTipTitle={`Deceased date ${formatISODate(participant.deceasedDate)}`}
              toolTipText=''
              toolTipTriggerComponent={<InfoOutlinedIcon sx={{ height: '100%', width: '100%' }} />}
            />
          )}
        </Stack>
        <OverviewLabels participant={participant} />
      </Stack>
    </PageHeaderStack>
  );
};

export interface OverviewLabelsProps {
  participant: Participant;
}

const OverviewLabels: FC<OverviewLabelsProps> = ({ participant }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  const handleDeleteClick = () => {
    setModalState({
      component: ParticipantModalDeleteContent,
      position: 'center',
      disableClickAway: false,
      props: {
        participantID: participant?.id || undefined,
      },
      status: 'open',
    });
  };

  return (
    <Stack flexDirection='row' gap='8px'>
      <OverviewLabel>
        <Typography variant='subtitle2' fontSize='12px' fontWeight='500' color={variables.colors.text.secondary}>
          NDIS #
        </Typography>
        <Typography variant='subtitle2' fontSize='12px' fontWeight='600' color={variables.colors.text.main}>
          {participant.ndisNumber}
        </Typography>
      </OverviewLabel>
      <OverviewLabel>
        <Typography variant='subtitle2' fontSize='12px' fontWeight='600' color={variables.colors.text.main}>
          {participant.ndisSystem}
        </Typography>
      </OverviewLabel>
      <StyledDeleteOutlinedIcon onClick={handleDeleteClick} />
    </Stack>
  );
};

export interface ParticipantModalDeleteContentProps {
  participantID: string | undefined;
}

const ParticipantModalDeleteContent: FC<ParticipantModalDeleteContentProps> = ({ participantID }) => {
  const navigate = useNavigate();
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();
  const [, closeModal] = useAtom(closeModalAtom);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);

  const handleDelete = async () => {
    try {
      await deleteParticipantById(organisations[0].organisation.globalId, participantID || '');
      setInvalidateData((prev) => ({ ...prev, 'participants': true }));
      closeModal();
      navigate('/participant-centre/participants');
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <Stack width='500px' boxSizing='border-box'>
      <Stack flexDirection='row' gap='24px' width='100%' padding='32px' boxSizing='border-box'>
        <Stack>
          <Stack sx={{ backgroundColor: variables.colors.error.subtle, borderRadius: '100%', width: '40px', height: '40px', justifyContent: 'center', alignItems: 'center' }} >
            <DeleteOutlinedIcon sx={{ color: variables.colors.error.main, width: '24px' }} />
          </Stack>
        </Stack>
        <Stack gap='12px'>
          <Typography variant='h6'>
            Are you sure you want to delete this participant?
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            The action cannot be reverted.
          </Typography>
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        <Button variant='text' onClick={closeModal} sx={{ border: `1px solid ${variables.colors.lightNeutral.darker}` }}>Cancel</Button>
        <Button variant='contained' color='error' onClick={handleDelete}>Delete Participant</Button>
      </Stack>
    </Stack>
  );
};

const PageHeaderStack = styled(Stack)`
  display: flex;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 8px;
`;

const OverviewLabel = styled(Stack)`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: #F3F4F6;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledDeleteOutlinedIcon = styled(DeleteOutlinedIcon)`
  color: ${variables.colors.text.secondary};
  cursor: pointer;
  font-size: 20px;
  transition: color 240ms;
  margin: auto;
  &:hover {
    color: ${variables.colors.text.main};
  }
`;

export default ViewParticipants;