import React, { FC } from 'react';
import { CircularProgress, LinearProgress, Stack } from '@mui/material';
import { LoadingOverlayProps } from './types';

const LoadingOverlay: FC<LoadingOverlayProps> = ({ sx, variant = 'linear' }) => {
  return (
    <Stack
      position='absolute'
      top={0}
      left={0}
      zIndex={99999}
      height='100%'
      width='100%'
      justifyContent='flex-start'
      alignItems='center'
      overflow='hidden'
      sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', ...sx }}
    >
      {variant === 'linear' ? <LinearProgress sx={{ width: '100%' }} /> :
        <CircularProgress sx={{ width: '100%' }} />
      }
    </Stack>
  );
};

export default LoadingOverlay;