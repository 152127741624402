import React, { useCallback, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styled from 'styled-components';
import DataTable from 'components/common/DataTable';
import SearchInput from 'components/common/InputFields/SearchInput';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import { Controller, useForm } from 'react-hook-form';
import TextInput from 'components/common/InputFields/TextInput';
import variables from 'styles/variables';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { Tag, tagCategories } from 'types/dbSchema/serviceTeamSettings';
import SelectInput from 'components/common/InputFields/SelectInput';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { createTag, listTags, updateTag } from 'api/organisations/settings/service-team/tags';
import useInvalidateData from 'hooks/useInvalidateData';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';
import TagsForm from '../../../../Forms/TagsForm';

// Renders Edit Icon for Grid column definitions - opens add/edit form in modal
const EditIcon = ({ tag }: { tag: Tag }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  if (!tag || !tag.isEditable) return null;

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: TagsForm,
      props: {
        dataTableName: 'service-team-settings-tags',
        type: 'edit',
        tag: tag,
      }
    }));
  }, [setModalState, tag]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

export default EditIcon;