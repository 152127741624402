import React, { FC } from 'react';
import { FileStatesProps, SubmittedStateProps, UploadingStateProps } from '../types';
import styled from 'styled-components';
import { LinearProgress, Stack, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import variables from 'styles/variables';
import { downloadAttachment, getFileIcon } from 'utils/helpers';
import { DBAttachment } from 'types';
import { format, parseISO } from 'date-fns';

// The UploadingState component displays a loading bar and the file name and size of the file being uploaded.
export const UploadingState: FC<UploadingStateProps> = ({ uploadedFile, loadingProgress, removeFile }) => {
  return (
    <UploadingStateStack>
      <Stack flexDirection='row' justifyContent='space-between' width='100%'>
        <Stack flexDirection='row' gap='8px' alignItems='center'>
          {getFileIcon(uploadedFile.fileType)}
          <Stack>
            <Typography variant='body2'>
              {uploadedFile.fileName}
            </Typography>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              {(uploadedFile.fileSize / 1048576).toFixed(2)} MB
            </Typography>
          </Stack>
        </Stack>
        <CloseOutlinedIcon onClick={removeFile} sx={{ cursor: 'pointer' }} />
      </Stack>
      <Stack flexDirection='row' width='100%' alignItems='center' gap='16px'>
        <StyledLinearProgress variant='determinate' value={loadingProgress} sx={{ width: '100%' }} color='primary' />
        <Typography variant='subtitle2' color='rgba(31, 41, 55, 0.60)' width='65px' textAlign='right'>
          {`${loadingProgress.toFixed(2)}%`}
        </Typography>
      </Stack>
    </UploadingStateStack>
  );
};

// The complete state displays a green loading bar and the file name and size of the uploaded file.
export const CompleteState: FC<FileStatesProps> = ({ uploadedFile, removeFile }) => {
  return (
    <CompleteStateStack>
      <Stack flexDirection='row' justifyContent='space-between' width='100%'>
        <Stack flexDirection='row' gap='8px' alignItems='center'>
          {getFileIcon(uploadedFile.fileType)}
          <Stack>
            <Typography variant='body2'>
              {uploadedFile.fileName}
            </Typography>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              {(uploadedFile.fileSize / 1048576).toFixed(2)} MB
            </Typography>
          </Stack>
        </Stack>
        <CloseOutlinedIcon onClick={removeFile} sx={{ cursor: 'pointer' }} />
      </Stack>
      <Stack flexDirection='row' width='100%' alignItems='center' gap='16px'>
        <StyledLinearProgress variant='determinate' value={100} sx={{ width: '100%' }} color='success' />
        <Typography variant='subtitle2' color='rgba(31, 41, 55, 0.60)'>
          COMPLETE
        </Typography>
      </Stack>
    </CompleteStateStack>
  );
};

// Error state displays red and error message and the file name and size of the file that failed to upload.
export const ErrorState: FC<FileStatesProps> = ({ uploadedFile, removeFile }) => {
  return (
    <ErrorStateStack>
      <Stack flexDirection='row' justifyContent='space-between' width='100%'>
        <Stack flexDirection='row' gap='8px' alignItems='center'>
          {getFileIcon(uploadedFile.fileType)}
          <Stack>
            <Typography variant='body2'>
              {uploadedFile.fileName}
            </Typography>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              {(uploadedFile.fileSize / 1048576).toFixed(2)} MB
            </Typography>
          </Stack>
        </Stack>
        <CloseOutlinedIcon onClick={removeFile} sx={{ cursor: 'pointer' }} />
      </Stack>
      <Stack flexDirection='row' width='100%' alignItems='center' gap='16px'>
        <ErrorOutlineOutlinedIcon sx={{ color: variables.colors.error.main }} />
        <Typography variant='body2' color={variables.colors.error.main}>
          Error: {uploadedFile.message}
        </Typography>
      </Stack>
    </ErrorStateStack>
  );
};

// Submitted state will be shown for files that were previously uploaded (not in the current editing session).
export const SubmittedState: FC<SubmittedStateProps> = ({ uploadedFile, removeFile, showDeleteIcon }) => {
  return (
    <SubmittedStateStack>
      <Stack flexDirection='row' justifyContent='space-between' width='100%' gap='8px'>
        <Stack flexDirection='row' gap='16px' alignItems='flex-start'>
          {getFileIcon(uploadedFile.fileType)}
          <Stack>
            <Typography variant='body2'>
              {uploadedFile.fileName}
            </Typography>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              {/* Display the upload date if it exists, otherwise display the file size as a fallback*/}
              {uploadedFile.uploadDate ?
                `Added on ${format(parseISO(uploadedFile.uploadDate), 'dd/MM/yyyy, hh:mm a')}`
                : `${(uploadedFile.fileSize / 1048576).toFixed(2)} MB`}
            </Typography>
          </Stack>
        </Stack>
        <Stack flexDirection='row' gap='8px' alignItems='center'>
          <FileDownloadOutlinedIcon sx={{ cursor: 'pointer' }} onClick={() => downloadAttachment(uploadedFile)} />
          {showDeleteIcon && <DeleteOutlinedIcon onClick={removeFile} sx={{ cursor: 'pointer' }} />}
        </Stack>
      </Stack>
    </SubmittedStateStack>
  );
};

// Icon only state
export const IconOnlyState: FC<SubmittedStateProps> = ({ uploadedFile, removeFile, showDeleteIcon }) => {
  if (uploadedFile.fileType === 'png' || uploadedFile.fileType === 'jpeg') {
    return (
      <img
        src={uploadedFile.blobUrl}
        alt={uploadedFile.fileName}
        onClick={() => downloadAttachment(uploadedFile)}
        style={{
          width: '48px',
          height: '48px',
          objectFit: 'cover',
          cursor: 'pointer',
          borderRadius: '8px',
          border: '1px solid #F6F7F9',
        }}
      />
    );
  }
  return (
    <IconOnlyStateStack sx={{ cursor: 'pointer' }} onClick={() => downloadAttachment(uploadedFile)}>
      <Stack flexDirection='row' gap='16px' alignItems='center' width='100%' justifyContent='center'>
        {getFileIcon(uploadedFile.fileType)}
        <Typography variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {uploadedFile.fileName}
        </Typography>
      </Stack>
    </IconOnlyStateStack>
  );
};

const StateStack = styled(Stack)`
  width: 100%;
  gap: 16px;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
`;

const UploadingStateStack = styled(StateStack)`
  border: 1px solid #E2E8F0;
  &:hover {
      background-color: #F9FAFB;
    }
`;

const CompleteStateStack = styled(StateStack)`
  border: 1px solid #D0EEC7;
  &:hover {
      background-color:#f9fbf9;
    }
`;

const ErrorStateStack = styled(StateStack)`
  border: 1px solid #eec7c7;
  &:hover {
      background-color: #fbf9f9;
    }
`;

const SubmittedStateStack = styled(StateStack)`
  border: 1px solid #E2E8F0;
  &:hover {
      background-color: #F9FAFB;
    }
`;

const IconOnlyStateStack = styled(StateStack)`
  background-color: #F6F7F9;
  width: 150px;
  height: 48px;
  padding: 8px 16px;
  &:hover {
      background-color: #edf2f7;
    }
`;

const StyledLinearProgress = styled(LinearProgress)`
  width: 100%;
  min-height: 6px !important;
  height: 6px !important;
  border-radius: 4px;
`;