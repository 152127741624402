import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { createFilterString } from 'utils/helpers';
import { listUsers } from 'api/organisations/users';
import { useNavigate } from 'react-router-dom';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';
import useDebounceValue from 'hooks/useDebounceValue';

const UserProfileDataTable: FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  const navigate = useNavigate();
  useInvalidateData('user-profile');

  const debouncedSearchText = useDebounceValue(searchText, 500);

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['user-profile', paginationModel, createFilterString(selectedFilters), debouncedSearchText],
    queryFn: () => listUsers(
      organisations[0].organisation.globalId,
      selectedFilters,
      paginationModel.page + 1,
      paginationModel.pageSize,
      debouncedSearchText
    ),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.userProfiles,
  });


  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedRowIds={selectedRowIds}
      />
      <DataTable
        data={data as Data}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        title='User Profiles'
        dataTableName='user-profile'
        checkboxSelection={true}
        paginationMode='server'
        onNoData={() => navigate('/settings/user-management/user-profiles/create')}
      />
    </Stack>
  );
};

export default UserProfileDataTable;